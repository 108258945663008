import React from "react";
import "./description.css";
import Card from "react-bootstrap/Card";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const Description = ({ Description, name }) => {
  return (
    <>
      <Card className="container my-3">
        <Card.Header className="single-desc-main">
          <p className="single-desc-top-header">Description</p>
        </Card.Header>
        <Card.Body>
          <Card.Title className="mb-desc-title">
            What is <span style={{ color: "#3EB489" }}>{name}</span>
          </Card.Title>
          <Card.Text className="mbl-desc">
            {parse(Description)}
            {/* Microsoft Office 365 is a cloud-based subscription service that includes various tools for productivity, collaboration, and communication.Business Basic: Entry-level plan with web-only versions of Office apps, email, and file storage.Business Standard: Enhanced plan with desktop and mobile versions of Office
                                                apps, email, and file storage. It also includes features like desktop Outlook, Teams, and Planner. */}
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default Description;
