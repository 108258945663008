const LandingPageForAdvtContent ={
    LPADVTPARA1: "Simply provide us with the essential details about your project, goals, and budget. Our user-friendly platform makes it effortless to outline your requirements. Sit back and let us take it from there.",
    LPADVTPARA2: "Once your project is created, our platform automatically matches you with top-tier IT vendors who are best suited for your needs. You don't have to spend time searching or reaching out to vendors. We bring the options to you.",
    LPADVTPARA3: "Leave the time-consuming task of quotation analysis to us. We extract and present the key information in a clear and concise manner, enabling you to make an informed decision with ease.",
    LPADVTPARA4: "Based on the analysed quotations, we provide you with valuable insights, such as pricing, services offered, and vendor profiles. With our guidance and comprehensive information at your disposal, you can confidently select the perfect vendor for your project.",
    
}

module.exports ={
    LandingPageForAdvtContent
}