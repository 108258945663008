import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./SingleProductNavBar.css";
import write from "../../../Assets/Images/write.png";
import navstar from "../../../Assets/Images/navstar.png";
import Description from "../Description";
import Features from "../Features";
import Specifications from "../Specification";
import Youtube from "../Youtube";
import FAQ from "../Faq";
import ProductEnquiryPopup from "../../../Models/ProductEnquiryPopup/ProductEnquiryPopup";
import BottomNav from "../../MobileComponents/BottomNav";
import Packages from "../Packages/Packages";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProductReviews from "../ProductReviews/ProductReviews";

const SingleProductNavBar = () => {
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);
  const productDetails = SingleProduct.productDetail;
  console.log(productDetails, "productDetails");
  const [popup, setPopup] = useState(false);
  const desc = useRef(null);
  const navigate = useNavigate();
  const YoutubeId = SingleProduct.productDetail.video_link
    ? SingleProduct.productDetail.video_link.split("https://youtu.be/")[1]
    : "";

  const handelDesc = () => {
    window.scroll({
      top: 830,
      behavior: "smooth",
    });
  };

  const onClickCallNow = () => {
    setPopup(!popup);
    //console.log("popup clicked");
  };

  const onClickDesc = () => {
    window.scroll({
      top: 780,
      behavior: "smooth",
    });
  };

  const onClickProd = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  //  const onClickVideo = () => {
  //   window.scrollToBottom({
  //     bottom: 0,
  //     behavior: "smooth",
  //   });
  //  }

  const onClickWriteReview = () => {
    const loginType = localStorage.getItem("logintype");
    if (loginType == 1) {
      navigate("/write-a-review");
      localStorage.setItem("productName", productDetails.product_name);
      localStorage.setItem("productId", productDetails.product_id);
    } else if (loginType == 2) {
      toast.error("Only registered customers can give feedback");
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      {SingleProduct && (
        <>
          <section
            className="container d-none d-md-block"
            style={{ border: "1px solid #C4C4C4", borderRadius: "10px" }}
          >
            <div className="sp-nav-btn-and-star">
              <div className="sp-nav-header-img">
                <div className="sp-brand-img-div">
                  <img
                    className="sp-brand-img"
                    src={productDetails.brand_logo_url}
                    alt=""
                  />
                </div>
                <div className="sp-header-btns">
                  <h4>{productDetails.product_name}</h4>
                  <div className="sp-nav-btns">
                    <button
                      onClick={onClickCallNow}
                      className="sp-get-free-btn"
                    >
                      {SingleProduct.productDetail.parent_id === 1
                        ? "Get Free Demo"
                        : "Request Quotation"}
                    </button>
                    {/* {/* <button className="sp-buy-now-btn">Buy Now</button> */}
                    <button
                      className="sp-write-btn"
                      onClick={onClickWriteReview}
                    >
                      {" "}
                      <img src={write} alt="" /> &nbsp; Write a Review
                    </button>
                  </div>
                </div>
              </div>
              <div className="sp-nav-star-div">
                <div>
                  <img src={navstar} alt="" />
                </div>
                <div>
                  <p className="sp-nav-rate">
                    {productDetails.avg_rating}
                    <span className="sp-span-rate">/5</span>
                  </p>
                  <p className="sp-span-rate">
                    Based on {SingleProduct.productReviews.length} Review
                  </p>
                </div>
              </div>
            </div>

            <div className="sp-nav-bar">
              <a className="tag-name-link">
                <p className="tag-names" onClick={onClickProd}>
                  PRODUCT
                </p>
              </a>
              {SingleProduct.productDetail.is_price === "Package" && (
                <a href="#package" className="tag-name-link">
                  {" "}
                  <p className="tag-names">PRICING</p>
                </a>
              )}
              <a className="tag-name-link">
                <p onClick={handelDesc} className="tag-names">
                  DESCRIPTION
                </p>
              </a>
              <a href="#fea" className="tag-name-link">
                <p className="tag-names">FEATURES</p>
              </a>
              <a href="#spe" className="tag-name-link">
                <p className="tag-names">SPECIFICATION</p>
              </a>
              {SingleProduct.productDetail.video_link && (
                <a href="#vid" className="tag-name-link">
                  <p className="tag-names">VIDEO</p>
                </a>
              )}
              {/* <p>COMPARE</p>
              <p>ALTERNATIVE</p> */}

              {SingleProduct.productFAQs.length > 0 && (
                <a href="#faq" className="tag-name-link">
                  <p className="tag-names">FAQS</p>
                </a>
              )}

              {SingleProduct.productReviews.length > 0 && (
                <a href="#review" className="tag-name-link">
                  <p className="tag-names">REVIEW</p>
                </a>
              )}
            </div>
          </section>
          <div id="desc" ref={desc}>
            <Description
              Description={productDetails.product_information}
              name={productDetails.product_name}
            />
          </div>
          {SingleProduct.productDetail.is_price === "Package" && (
            <div id="package">
              <Packages />
            </div>
          )}
          <div id="fea">
            <Features Features={productDetails.features} />
          </div>
          <div id="spe">
            <Specifications Specifications={productDetails.specifications} />
          </div>
          {SingleProduct.productDetail.video_link && (
            <div id="vid" className="d-none d-md-block">
              <Youtube VideoID={YoutubeId} />
            </div>
          )}

          {SingleProduct.productDetail.video_link && (
            <div className="d-block d-sm-none">
              <Youtube VideoID={YoutubeId} />
            </div>
          )}
          {SingleProduct.productFAQs.length > 0 && (
            <div id="faq">
              <FAQ />
            </div>
          )}
          {SingleProduct.productReviews.length > 0 && (
            <div id="review">
              <ProductReviews />
            </div>
          )}
          <div className="d-block d-sm-none">
            <BottomNav des={onClickDesc} pro={onClickProd} />
          </div>
          {popup && <ProductEnquiryPopup closeModal={setPopup} />}
        </>
      )}
    </>
  );
};

export default SingleProductNavBar;
