import React from "react";
import "./ReviewSuccessPopup.css";
import success from "../../Assets/Images/wr-success-new.png";
import cross from "../../Assets/Images/blue-cross.png";
import { useNavigate } from "react-router-dom";

const ReviewSuccessPopup = () => {
  const navigate = useNavigate();
  const onClickClose = () => {
    // console.log(document.referrer, "old url");
    // const url = document.referrer;
    // window.location = url;
    navigate("/")
  };
  return (
    <section className="wr-popup-main">
      <button className="wr-close-btn" onClick={onClickClose}>
        <img className="wr-close" src={cross} alt="" />
      </button>
      <div className="wr-img-div">
        <img className="wr-success-img" src={success} alt="success" />
      </div>
      <p className="wr-pop-text">
        Thank you for reaching out and providing us with <br /> valuable
        feedback.
      </p>
    </section>
  );
};

export default ReviewSuccessPopup;
