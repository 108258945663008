import React from 'react';
import TermsAndConditionsHb from '../../Components/HBanner/TermsAndConditionsHb';
import TermsAndConditionsBottomContent from '../../Components/TandConditions';
import TermsAndConditionsTopContent from '../../Components/TandConditions/TopContent';
import HomePageLayout from '../../Layouts/HomePageLayout';
import './termsAndConditions.css'

const TermsAndConditions = () => {
        return (
                <>
                        <HomePageLayout>
                                <TermsAndConditionsHb
                                        title={"Terms"}
                                        spanName={"Conditions"}
                                />

                                <div className='pt-5'>
                                        <TermsAndConditionsTopContent />
                                </div>
                                <div>
                                        <TermsAndConditionsBottomContent />
                                </div>
                        </HomePageLayout>
                </>
        )
}

export default TermsAndConditions