import React from 'react';
import './TandConTopCont.css'

const TermsAndConditionsTopContent = () => {
        return (
                <>
                        <section className='container'>
                                <div>
                                        <h2 className='top-tac-content'>Purchase Terms and Conditions</h2>
                                        <div>
                                                <hr className='hr-tac' />
                                        </div>
                                        <div className='top-tac-content-para'>
                                                <p >
                                                        Thank you for your interest in purchasing an opportunity from us. Before proceeding with your purchase, please read the following terms and conditions carefully:
                                                </p>
                                        </div>

                                        <div className='container top-tac-content-para'>
                                                <p>1. <strong>Definition:</strong> In these terms and conditions, "we", "us" or "our" refers to the seller of the opportunity, and "you" or "your" refers to the purchaser.  </p>
                                                <p>2. <strong>Payment: </strong>The purchase price for the opportunity must be paid in full at the time of purchase, unless otherwise agreed upon in writing by both parties. </p>
                                                <p>3. <strong>Delivery: </strong>Upon receipt of payment, we will deliver the opportunity to you within a reasonable time frame. </p>
                                                <p>4. <strong> Refunds: </strong> We do not offer refunds for opportunities purchased. All sales are final. </p>
                                                <p>5. <strong> Non-transferable: </strong>The opportunity is non-transferable and can only be used by the purchaser. </p>
                                                <p>6. <strong>Disclaimer: </strong>We make no guarantees or representations regarding the outcome or success of the opportunity. The purchaser assumes all risks and responsibilities associated with the opportunity.  </p>
                                                <p>7.  <strong>Confidentiality: </strong>The purchaser agrees to keep all information related to the opportunity confidential and not to disclose such information to any third party.</p>
                                                <p>8. <strong>Governing Law: </strong>These terms and conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which we are located. </p>
                                                <p>9. <strong>Entire Agreement: </strong>These terms and conditions constitute the entire agreement between us and you with respect to the opportunity and supersede all prior or contemporaneous communications and proposals, whether oral or written. </p>
                                                <p>10. <strong>Modification: </strong>We reserve the right to modify these terms and conditions at any time without prior notice. Any such modifications will be effective immediately upon posting on our website or other notice to you. </p>
                                        </div>
                                        <div className='top-tac-content-para'>
                                                <p>By purchasing the opportunity, you agree to be bound by these terms and conditions. If you do not agree to these terms and conditions, please do not purchase the opportunity.</p>
                                        </div>
                                </div>
                        </section>
                </>
        )
}

export default TermsAndConditionsTopContent