import React, { useState, useEffect } from 'react';
import './plus-minus.css';
import Plus from '../../../Assets/Svg/Union.svg';
import Minus from '../../../Assets/Svg/Union(1).svg';
import { useDispatch, useSelector } from 'react-redux';
import {
        updatedCurrentSelectedProduct1,
        updatedCurrentSelectedProduct2,
        updatedCurrentSelectedProduct3
} from '../../../Store/Reducer/AllProductsReducer';
import ProductPopup from '../ProductPopup';
import ImageCompo3 from '../ImageCompo3';

const PlusMinus = () => {

        const [open, setOpen] = useState(false);
        const { AllProducts, currentSelectedProduct3, currentSelectedProduct2,
                currentSelectedProduct1
        } = useSelector((state) => state.allProducts);
        console.log("currentSelectedProduct3", currentSelectedProduct3)


        const dispatch = useDispatch();
        const handleClear = () => {
                dispatch(updatedCurrentSelectedProduct1(null));
                dispatch(updatedCurrentSelectedProduct2(null));
        }

        const handleCloseProduct = () => {
                dispatch(updatedCurrentSelectedProduct3(null));
                console.log("clicked close product")
        }


        const handleClose = () => {
                setOpen(false);
        }

        function filterItemsById(ids) {
                return AllProducts.filter((item) => !ids.includes(item.id))
        }

        const itemId = [currentSelectedProduct2?.id, currentSelectedProduct1?.id];
        // console.log("itemId", itemId)
        const filteredItems = filterItemsById(itemId);

        return (
                <>
                        <div className='d-none d-md-block'>
                                {currentSelectedProduct3 ?
                                        (<ImageCompo3 handleCloseProduct={handleCloseProduct} />) : (<>
                                                {/* <ImageCompo3 /> */}
                                                <ProductPopup open={open} handleClose={handleClose} allProducts={filteredItems} />
                                                <section >
                                                        <div className="d-flex flex-column justify-content-evenly" style={{ height: "250px" }}>
                                                                <div className=''>
                                                                        <img src={Plus} alt="" style={{ cursor: "pointer" }} onClick={() => setOpen(true)} />
                                                                </div>
                                                                <div>
                                                                        <img src={Minus} alt="" style={{ cursor: "pointer" }} onClick={handleClear} />
                                                                </div>
                                                        </div>
                                                </section>
                                        </>
                                        )}
                        </div>

                        {/* <div className='d-block d-sm-none'>
                        {((currentSelectedProduct2 == null || currentSelectedProduct3 && currentSelectedProduct3) || (currentSelectedProduct3)) ?
                                        (<ImageCompo3 handleProductClear={handleProductClear} />) : (<>
                                                <ImageCompo3 />
                                                <ProductPopup open={open} handleClose={handleClose} allProducts={filteredItems} />
                                                <section >
                                                        <div className="d-flex flex-column justify-content-evenly" style={{ height: "250px" }}>
                                                                <div className=''>
                                                                        <img src={Plus} alt="" style={{ cursor: "pointer" }} onClick={() => setOpen(true)} />
                                                                </div>
                                                                <div>
                                                                        <img src={Minus} alt="" style={{ cursor: "pointer" }} onClick={handleClear} />
                                                                </div>
                                                        </div>
                                                </section>
                                        </>
                                        )}
                        </div> */}

                </>
        )
}

export default PlusMinus