import React from "react";
import "./SellYourSolutionHeroBanner.css";
import { SellYourSolutionContent } from "../../../Content/SellYourSolution.content";

const SellYourSolutionHeroBanner = () => {
  return (
    <>
      <div className="container-fluid hero-banner-div d-flex">
        <div className="hero-banner-div-text">
          <h2 className="sell-banner-heading" style={{ color: "#403D39" }}>
            {SellYourSolutionContent.BANNERHEADING1}
          </h2>
          <h1 className="sell-banner-heading">
            {SellYourSolutionContent.BANNERHEADING2}
          </h1>
          <h3 className="sell-banner-sub-heading">
            {SellYourSolutionContent.BANNERSUBHEADING}
          </h3>
          <a href="https://login.conqt.com/login">
          <button className="sell-banner-button">
            {SellYourSolutionContent.BANNERBUTTON}
          </button></a>
          <h5 className="sell-banner-tagline1">
            {SellYourSolutionContent.BANNERTAGLINE1}
          </h5>
          <h5 className="sell-banner-tagline2">
            {SellYourSolutionContent.BANNERTAGLINE2}
          </h5>
        </div>
      </div>
    </>
  );
};

export default SellYourSolutionHeroBanner;
