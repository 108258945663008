import React, { useEffect } from "react";
import HomePageLayout from "../../Layouts/HomePageLayout";
import HelpFaqs from "../../Components/FAQs/HelpFaqs/HelpFaqs";
import FaqOverview from "../../Components/FAQs/FaqOverview/FaqOverview";
import FaqAboutProducts from "../../Components/FAQs/FaqAboutProducts/FaqAboutProducts";
import FaqAboutIndustries from "../../Components/FAQs/FaqAboutIndustries/FaqAboutIndustries";
import FaqCustomerQueries from "../../Components/FAQs/FaqCustomerQueries/FaqCustomerQueries";
import { Helmet } from "react-helmet";

const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <HomePageLayout>
        <Helmet>
          <title>{"Frequently Asked Questions - ConQT"}</title>
          <meta
            name="description"
            content={
              "Find answers to commonly asked questions about ConQT, a leading platform for improving productivity and collaboration in the workplace."
            }
          />
        </Helmet>
        <HelpFaqs />
        <FaqCustomerQueries />
        <FaqAboutProducts />
        {/* <FaqOverview/> */}
        <FaqAboutIndustries />
      </HomePageLayout>
    </>
  );
};

export default Faq;
