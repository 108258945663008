import React from "react";
import "./HowConqtHelpGreenCard.css";

// const data = [
//     // {value: }
// ]
const HowConqtHelpGreenCard = ({icon, text}) => {
  return (
    <div className="lp-cmap-card-div">
      <div>
        <img alt="" className="lp-cmap-card-icon" src={icon} />
      </div>
      <p className="lp-cmap-card-text">{text}</p>
    </div>
  );
};

export default HowConqtHelpGreenCard;
