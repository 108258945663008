import React, { useState, useEffect } from "react";
import "./MobileScreenPopup.css";
import "../../Styles/Styles.css";
import HeaderLogo from "../../Assets/Images/conqt-logo.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  AiFillCloseSquare,
  AiFillCaretDown,
  AiOutlineHome,
  AiOutlineApartment,
} from "react-icons/ai";
import { MdContactPhone } from "react-icons/md";
import { BiGitCompare } from "react-icons/bi"
import { GrOrganization } from "react-icons/gr";
import { BiCategoryAlt } from "react-icons/bi";
import { FaIndustry } from "react-icons/fa";
import { BaseUrl } from "../../ApiBaseUrl/Api";
import axios from "axios";
import {
  INDUSTRY_DETAILS,
  DEPARTMENT_DETAILS,
  CATEGORY_DETAILS,
} from "../../ApiEndPoints/";
import FadeLoader from "react-spinners/FadeLoader";
import { updatedCurrentSelectedProduct1, updatedCurrentSelectedProduct2, updatedCurrentSelectedProduct3 } from "../../Store/Reducer/AllProductsReducer";
import { useDispatch } from "react-redux";

const MobileScreenPopup = ({ isShow }) => {
  const [showIndustry, setShowIndustry] = useState(false);
  const [showDepartment, setShowDepartment] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [showSubCategory, setSubShowCategory] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleIndustryClick = () => {
    setShowIndustry(!showIndustry);
  };

  const handleDepartmentClick = () => {
    setShowDepartment(!showDepartment);
  };

  const handleCategoryClick = () => {
    setShowCategory(!showCategory);
  };

  const handleSubCategoryClick = () => {
    setSubShowCategory(!showSubCategory);
  };

  const [data, setData] = useState([]);
  const [department, setDepartment] = useState([]);
  const [category, setCategory] = useState([]);
  const [visible, setVisible] = useState(0);  
  const { id, name } = useParams();

  useEffect(() => {
  setLoader(true)
    axios
      .get(`${BaseUrl}${INDUSTRY_DETAILS}`)
      .then((response) => {
        setLoader(false)
        setData(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${BaseUrl}${DEPARTMENT_DETAILS}`)
      .then((response) => {
        setDepartment(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${BaseUrl}${CATEGORY_DETAILS}`)
      .then((response) => {
        setCategory(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClickAbout = () => {
    navigate("/about-us");
  };

  const onClickCompare = () => {
    navigate("/compare-products")
    dispatch(updatedCurrentSelectedProduct1(null))
    dispatch(updatedCurrentSelectedProduct2(null))
    dispatch(updatedCurrentSelectedProduct3(null))
    
  }
  const onClickAllProducts = () => {
    navigate("/view-all-product");
  };
  const onClickHome = () => {
    navigate("/");
  };
  const onClickContact = () => {
    navigate("/contact-us");
  };

  return (
    <>
      {/* <div className="mobile-screen-popup">
        <div className="mobile-popup-close" onClick={() => isShow(false)}>
          X
        </div>
        <img className="mobile-popup-logo" src={HeaderLogo} alt=""></img>
        <p className="mobile-popup-text" onClick={onClickAllProducts}>
          All Products
        </p>
        <p className="mobile-popup-text" onClick={onClickAbout}>
          About Us
        </p>
        <p className="mobile-popup-text" onClick={onClickContact}>
          Contact Us
        </p>
        <div className="mobile-popup-btn-div">
          <a href="https://login.conqt.com/login" className="mobile-popup-btn-login">Login</a>
          <a href="https://login.conqt.com/register" className="mobile-popup-btn">Sign Up</a>
        </div>
      </div> */}
      <div className="mobile-screen-popup">
        <div className="mobile-main-body">
          <div className="mainlogoclose">
            <img src={HeaderLogo} alt="mobile logo" />
            <AiFillCloseSquare onClick={() => isShow(false)} />
          </div>

          <div className="sidebar-item">
            <div className="sidebar-title" onClick={onClickHome}>
              <span>
                <AiOutlineHome />
                Home
              </span>
            </div>
            <div className="sidebar-title" onClick={handleCategoryClick}>
              <span>
                <BiCategoryAlt />
                Category
              </span>
              <AiFillCaretDown />
            </div>
            {showCategory && (
              <div className="sidebar-submenu-items">
                {category.slice(0,2).map((items, index) => {
                  return (
                    <>
                    <span onClick={() => setVisible(!visible && items.category_id)}>
                      <div className="subMenuChild">
                        {items.category_name}
                        <AiFillCaretDown />
                      </div>
                    </span>
                    {visible == items.category_id && (
                      <div className="sidebar-SubChildNewMenu-items">
                        {items.sub_categories.map((type, indexs) => {
                          return (
                            type.product_count != 0 && 
                            <span
                              key={indexs}
                              onClick={() => {
                                navigate(
                                  `/category/${
                                    type.category_id
                                  }/${type.category_name
                                    .split(" ")
                                    .join("-")}`
                                );
                                isShow(false)
                              }}
                              
                            >
                              {type.category_name}
                            </span>
                          );
                        })}
                      </div>
                    )}
                        </>
                  );
                })}
              </div>
            )}
            <div className="sidebar-title" onClick={handleIndustryClick}>
              <span>
                <FaIndustry />
                Industry
              </span>
              <AiFillCaretDown />
            </div>
            {showIndustry && (
              <div className="sidebar-submenu-items">
                {data.slice(0, 30).map((items, index) => {
                  return (
                    items.product_count != 0 && 
                    <span
                      onClick={() => {
                        navigate(
                          `/industry/${items.id}/${items.industry_name
                            .split(" ")
                            .join("-")}`
                        );
                        isShow(false)
                      }}
                    >
                      {items.industry_name}
                    </span>
                  );
                })}
              </div>
            )}
            <div className="sidebar-title" onClick={handleDepartmentClick}>
              <span>
                <AiOutlineApartment />
                Department
              </span>
              <AiFillCaretDown />
            </div>
            {showDepartment && (
              <div className="sidebar-submenu-items">
                {department.slice(0, 30).map((items, index) => {
                  return (
                    items.product_count != 0 && 
                    <span
                      onClick={() => {
                        navigate(
                          `/department/${
                            items.id
                          }/${items.department_name.split(" ").join("-")}`
                        );
                        isShow(false)
                      }}
                    >
                      {items.department_name}
                    </span>
                  );
                })}
              </div>
            )}
            <div className="sidebar-title" onClick={onClickCompare}>
              <span>
                <BiGitCompare />
                Compare Product
              </span>
            </div>

            {/* <div className="sidebar-title" onClick={onClickAbout}>
              <span>
                <GrOrganization />
                About Us
              </span>
            </div> */}

            <div className="sidebar-title" onClick={onClickContact}>
              <span>
                <MdContactPhone />
                Contact Us
              </span>
            </div>
          </div>
        </div>
        <div className="buttonsMenu">
          <a href="https://login.conqt.com/login">
            <button className="mobile-popup-btn-login">Login</button>
          </a>
          <a href="https://login.conqt.com/register">
            <button className="mobile-popup-btn">Sign Up</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default MobileScreenPopup;
