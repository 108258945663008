import React, { useEffect } from "react";
import FormCompo from "../../Components/ContactUsCompo/FormCompo";
import ContactTop from "../../Components/ContactUsCompo/TopCompo";
import HBanner from "../../Components/HBanner";
import HomePageLayout from "../../Layouts/HomePageLayout";
import "./contactUs.css";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HomePageLayout>
      <Helmet>
        <title>{"Contact Us | ConQt"}</title>
        <meta
          name="description"
          content={
            "At ConQT, we help you find the right resources to get the work delivered"
          }
        />
      </Helmet>
      <HBanner title={"CONTACT"} spanName={"US"} />
      <ContactTop />
      <div>
        <FormCompo />
      </div>
    </HomePageLayout>
  );
};

export default ContactUs;
