import React from "react";
import "./CompareProducts.css";
import HomePageLayout from "../../Layouts/HomePageLayout";
import CompareProductsHeader from "../../Components/CompareProducts/CompareProductsHeader/CompareProductsHeader";
import AddProductsToCompare from "../../Components/CompareProducts/AddProductsToCompareCard/AddProductsToCompareCard";
import TrendingProductsComparison from "../../Components/CompareProducts/TrendingProductsComparison/TrendingProductsComparison";
import CallExpert from "../../Components/CompareProducts/CallExpert/CallExpert";
import SimilarProducts from "../../Components/CompareProducts/SimilarProducts/SimilarProducts";
import SearchAddProductPopUpPage from "../../Components/CompareProducts/SearchAddProductPopUpPage/SearchAddProductPopUpPage";
import PopupPageOfProducts from "../../Components/CompareProducts/PopupPageOfProducts/PopupPageOfProducts";
import { Helmet } from "react-helmet";

const CompareProducts = () => {
  return (
    <>
      <Helmet>
        <title>{"Compare Software and Products at ConQt"}</title>
        <meta
          name="description"
          content={
            "Get In-depth comparison between software for all business types based on features, pricing, specifications, reviews and more."
          }
        />
      </Helmet>
      <HomePageLayout>
        <CompareProductsHeader />
        <AddProductsToCompare />
        {/* <SearchAddProductPopUpPage /> */}
        {/* <TrendingProductsComparison /> */}
        <CallExpert />
        {/* <SimilarProducts/> */}
        {/* <PopupPageOfProducts/> */}
      </HomePageLayout>
    </>
  );
};

export default CompareProducts;
