import React from "react";
import { LandingPageForAdvtContent } from "../../../Content/LandingPageForAdvt.content";
import HowConqtHelpGreenCard from "../LandingPageForAdvtConqtMap/HowConqtHelpGreenCard/HowConqtHelpGreenCard";
import "./ConqtCompaignFlowMap.css";
import mapIcon1 from "../../../Assets/Svg/Optimization.svg";
import mapIcon2 from "../../../Assets/Svg/Send-file.svg";
import mapIcon3 from "../../../Assets/Svg/Analyzing.svg";
import mapIcon4 from "../../../Assets/Svg/Profile.svg";
import line1 from "../../../Assets/Svg/Line1.svg";
import flow1 from "../../../Assets/Svg/flow1.svg";

const ConqtCompaignFlowMap = () => {
  return (
    <div className="lp-cmap-div">
      <h1 className="lp-cmap-heading">
        How ConQT Helps You Select the Perfect Vendor
      </h1>
      <div className="lp-cmap-card-flow">
        <div className="lp-cmap-flow-div">
          <img alt="" src={flow1} className="lp-cmap-flow-img" />
          <span className="lp-cmap-flow-text">Create Your Project</span>
        </div>
        <HowConqtHelpGreenCard
          icon={mapIcon1}
          text={LandingPageForAdvtContent.LPADVTPARA1}
        />
      </div>
      <div className="lp-cmap-card-flow">
        <div className="lp-cmap-flow-div">
          <img alt="" src={flow1} className="lp-cmap-flow-img" />
          <span className="lp-cmap-flow-text1">Receive Vendors Quotations</span>
        </div>
        <HowConqtHelpGreenCard
          icon={mapIcon2}
          text={LandingPageForAdvtContent.LPADVTPARA2}
        />
      </div>
      <div className="lp-cmap-card-flow">
        <div className="lp-cmap-flow-div">
          <img alt="" src={flow1} className="lp-cmap-flow-img" />
          <span className="lp-cmap-flow-text">Analyze Quotations</span>
        </div>
        <HowConqtHelpGreenCard
          icon={mapIcon3}
          text={LandingPageForAdvtContent.LPADVTPARA3}
        />
      </div>
      <div className="lp-cmap-card-flow">
        <div className="lp-cmap-flow-div">
          <img alt="" src={flow1} className="lp-cmap-flow-img" />
          <span className="lp-cmap-flow-text">Select Your Vendor</span>
        </div>
        <HowConqtHelpGreenCard
          icon={mapIcon4}
          text={LandingPageForAdvtContent.LPADVTPARA4}
        />
      </div>
    </div>
  );
};

export default ConqtCompaignFlowMap;
