import React from "react";
import "./HowToBecomePartnerSteps.css";
import Accordion from "react-bootstrap/Accordion";
import { ConqtUniversityContent } from "../../../Content/ConqtUniversity.content";
import homepagemobile from "../../../Assets/Images/homepage-mobile.png";
import homepagedesktop from "../../../Assets/Images/homepage-desktop.png";
import registrationmobile from "../../../Assets/Images/registration-mobile.png";
import registartiondesktop from "../../../Assets/Images/registration-desktop.png";
import partnermobile from "../../../Assets/Images/partner-mobile.png";
import partnerdesktop from "../../../Assets/Images/partner-desktop.png";
import registrationformmobile from "../../../Assets/Images/registrationform-mobile.png";
import registrationformdesktop from "../../../Assets/Images/registrationform-desktop.png";
import loginmobile from "../../../Assets/Images/login-mobile.png";
import logindesktop from "../../../Assets/Images/login-desktop.png";
import partnerdashboardmobile from "../../../Assets/Images/partnerdashboard-mobile.png";
import partnerdashboarddesktop from "../../../Assets/Images/partnerdashboard-desktop.png";
import projectloginmobile from "../../../Assets/Images/gaplogin-mobile.png";
import projectlogindesktop from "../../../Assets/Images/gaplogin-desktop.png";
import announcementmobile from "../../../Assets/Images/gap-announcement-mobile.png";
import announcementdesktop from "../../../Assets/Images/gap-announcement-desktop.png";
import projectmobile from "../../../Assets/Images/gap-project-mobile.png";
import projectdesktop from "../../../Assets/Images/gap-project-desktop.png";
import gapregistrationformmobile from "../../../Assets/Images/gap-projectregistrationform-mobile.png";
import gapregistrationformdesktop from "../../../Assets/Images/gap-projectregistrationform-desktop.png";
import buildproposalmobile from "../../../Assets/Images/sap-build-a-proposal-mobile.png";
import buildproposaldesktop from "../../../Assets/Images/sap-build-a-proposal-desktop.png";
import proposalsubmissionmobile from "../../../Assets/Images/sap-submission-of-proposal-mobile.png";
import proposalsubmissiondesktop from "../../../Assets/Images/sap-submission-of-proposal-desktop.png";
import myproposalmobile from "../../../Assets/Images/sap-myproposal-mobile.png";
import myproposaldesktop from "../../../Assets/Images/sap-myproposal-desktop.png";

function HowToBecomePartnerSteps({
  showBecomePartner,
  showProject,
  showSubmitProposal,
  showAddProducts,
}) {
  return (
    <div className="full-accordion">
      {!showAddProducts && (
        <Accordion className="CU-accordion" defaultActiveKey={["0"]} alwaysOpen>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="stepper">
                {/* <div className="stepper-heading">Step</div> */}
                <div className="stepper-round">1</div>
              </div>
              <h5 className="accordion-heading">
                {showBecomePartner
                  ? ConqtUniversityContent.HTBAPHOMEPAGEHEADER
                  : showProject
                  ? ConqtUniversityContent.GAPLOGINHEADER
                  : showSubmitProposal
                  ? ConqtUniversityContent.PROPOSALBUILDINGHEADER
                  : ""}
              </h5>
            </Accordion.Header>
            <Accordion.Body className="accordion-body">
              <div className="stepper-line"></div>
              <div className="drop-down-container">
                <div className="homepage-description">
                  <p className="accordion-description">
                    {showBecomePartner
                      ? ConqtUniversityContent.HTBAPHOMEPAGEDESCRIPTION
                      : showProject
                      ? ConqtUniversityContent.GAPLOGINDESCRIPTION
                      : showSubmitProposal
                      ? ConqtUniversityContent.PROPOSALBUILDINGDESCRIPTION1
                      : ""}
                  </p>
                  <p className="accordion-description">
                    {showSubmitProposal
                      ? ConqtUniversityContent.PROPOSALBUILDINGDESCRIPTION2
                      : ""}
                  </p>
                  <p className="accordion-description">
                    {showSubmitProposal
                      ? ConqtUniversityContent.PROPOSALBUILDINGDESCRIPTION3
                      : ""}
                  </p>
                </div>
                <div className="image-htbap">
                  <figure>
                    <img
                      className="image-accordion-1"
                      src={
                        showBecomePartner
                          ? homepagemobile
                          : showProject
                          ? projectloginmobile
                          : showSubmitProposal
                          ? buildproposalmobile
                          : ""
                      }
                    />
                    <figcaption>On Mobile Screen</figcaption>
                  </figure>
                  <figure>
                    <img
                      className="image-accordion-2"
                      src={
                        showBecomePartner
                          ? homepagedesktop
                          : showProject
                          ? projectlogindesktop
                          : showSubmitProposal
                          ? buildproposaldesktop
                          : ""
                      }
                    />
                    <figcaption>On Computer Screen</figcaption>
                  </figure>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <div className="stepper">
                {/* <div className="stepper-heading">Step</div> */}
                <div className="stepper-round">2</div>
              </div>
              <h5 className="accordion-heading">
                {showBecomePartner
                  ? ConqtUniversityContent.HTBAPREGISTRATIONHEADER
                  : showProject
                  ? ConqtUniversityContent.GAPANNOUNCEMENTHEADER
                  : showSubmitProposal
                  ? ConqtUniversityContent.PROPOSALSUBMISSIONHEADER
                  : ""}
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <div className="stepper-line"></div>
              <div className="drop-down-container">
                <div className="registration-description">
                  <p className="accordion-description">
                    {showBecomePartner
                      ? ConqtUniversityContent.HTBAPREGISTRATIONDESCRIPTION1
                      : showProject
                      ? ConqtUniversityContent.GAPANNOUNCEMENTDESCRIPTION1
                      : showSubmitProposal
                      ? ConqtUniversityContent.PROPOSALSUBMISSIONDESCRIPTION
                      : ""}
                  </p>
                  <p className="accordion-description">
                    {showBecomePartner
                      ? ConqtUniversityContent.HTBAPREGISTRATIONDESCRIPTION2
                      : showProject
                      ? ConqtUniversityContent.GAPANNOUNCEMENTDESCRIPTION2
                      : ""}
                  </p>
                  <p className="accordion-description">
                    {showBecomePartner
                      ? ConqtUniversityContent.HTBAPREGISTRATIONDESCRIPTION3
                      : ""}
                  </p>
                </div>
                <div className="image-htbap">
                  <figure>
                    <img
                      className="image-accordion-1"
                      src={
                        showBecomePartner
                          ? registrationmobile
                          : showProject
                          ? announcementmobile
                          : showSubmitProposal
                          ? proposalsubmissionmobile
                          : ""
                      }
                    />
                    <figcaption>On Mobile Screen</figcaption>
                  </figure>
                  <figure>
                    <img
                      className="image-accordion-2"
                      src={
                        showBecomePartner
                          ? registartiondesktop
                          : showProject
                          ? announcementdesktop
                          : showSubmitProposal
                          ? proposalsubmissiondesktop
                          : ""
                      }
                    />
                    <figcaption>On Computer Screen</figcaption>
                  </figure>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <div className="stepper">
                {/* <div className="stepper-heading">Step</div> */}
                <div className="stepper-round">3</div>
              </div>
              <h5 className="accordion-heading">
                {showBecomePartner
                  ? ConqtUniversityContent.HTBAPPARTNERHEADER
                  : showProject
                  ? ConqtUniversityContent.GAPPROJECTHEADER
                  : showSubmitProposal
                  ? ConqtUniversityContent.PROPOSALMYPROPOSALHEADER
                  : ""}
              </h5>
            </Accordion.Header>
            <Accordion.Body>
              <div className={!showSubmitProposal ? "stepper-line" : ""}></div>
              <div className="drop-down-container">
                <div className="registration-description">
                  <p className="accordion-description">
                    {showBecomePartner
                      ? ConqtUniversityContent.HTBAPPARTNERDESCRIPTION
                      : showProject
                      ? ConqtUniversityContent.GAPPROJECTDESCRIPTION1
                      : showSubmitProposal
                      ? ConqtUniversityContent.PROPOSALMYPROPOSALDESCRIPTION
                      : ""}
                  </p>
                  <p className="accordion-description">
                    {showProject
                      ? ConqtUniversityContent.GAPPROJECTDESCRIPTION2
                      : ""}
                  </p>
                  <p className="accordion-description">
                    {showProject
                      ? ConqtUniversityContent.GAPPROJECTDESCRIPTION3
                      : ""}
                  </p>
                </div>
                <div className="image-htbap">
                  <figure>
                    <img
                      className="image-accordion-1"
                      src={
                        showBecomePartner
                          ? partnermobile
                          : showProject
                          ? projectmobile
                          : showSubmitProposal
                          ? myproposalmobile
                          : ""
                      }
                    />
                    <figcaption>On Mobile Screen</figcaption>
                  </figure>
                  <figure>
                    <img
                      className="image-accordion-2"
                      src={
                        showBecomePartner
                          ? partnerdesktop
                          : showProject
                          ? projectdesktop
                          : showSubmitProposal
                          ? myproposaldesktop
                          : ""
                      }
                    />
                    <figcaption>On Computer Screen</figcaption>
                  </figure>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          {!showSubmitProposal && (
            <>
              {" "}
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <div className="stepper">
                    {/* <div className="stepper-heading">Step</div> */}
                    <div className="stepper-round">4</div>
                  </div>
                  <h5 className="accordion-heading">
                    {" "}
                    {showBecomePartner
                      ? ConqtUniversityContent.HTBAPFORMHEADER
                      : showProject
                      ? ConqtUniversityContent.GAPREGISTRATIONFORMHEADER
                      : ""}
                  </h5>
                </Accordion.Header>
                <Accordion.Body>
                  <div className={!showProject ? "stepper-line" : ""}></div>
                  <div className="drop-down-container">
                    <div className="registration-description">
                      <p className="accordion-description">
                        {showBecomePartner
                          ? ConqtUniversityContent.HTBAPFORMDESCRIPTION1
                          : showProject
                          ? ConqtUniversityContent.GAPREGISTRATIONFORMDESCRIPTION
                          : ""}
                      </p>
                      <p className="accordion-description">
                        {showBecomePartner
                          ? ConqtUniversityContent.HTBAPFORMDESCRIPTION2
                          : ""}
                      </p>
                      <p className="accordion-description">
                        {showBecomePartner
                          ? ConqtUniversityContent.HTBAPFORMDESCRIPTION3
                          : ""}
                      </p>
                    </div>
                    <div className="image-htbap">
                      <figure>
                        <img
                          className="image-accordion-1"
                          src={
                            showBecomePartner
                              ? registrationformmobile
                              : showProject
                              ? gapregistrationformmobile
                              : ""
                          }
                        />
                        <figcaption>On Mobile Screen</figcaption>
                      </figure>
                      <figure>
                        <img
                          className="image-accordion-2"
                          src={
                            showBecomePartner
                              ? registrationformdesktop
                              : showProject
                              ? gapregistrationformdesktop
                              : ""
                          }
                        />
                        <figcaption>On Computer Screen</figcaption>
                      </figure>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {!showProject && (
                <>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <div className="stepper">
                        {/* <div className="stepper-heading">Step</div> */}
                        <div className="stepper-round">5</div>
                      </div>
                      <h5 className="accordion-heading">
                        {" "}
                        {showBecomePartner
                          ? ConqtUniversityContent.HTBAPLOGINHEADER
                          : ""}
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="stepper-line"></div>
                      <div className="drop-down-container">
                        <div className="registration-description">
                          <p className="accordion-description">
                            {showBecomePartner
                              ? ConqtUniversityContent.HTBAPLOGINDESCRIPTION1
                              : ""}
                          </p>
                          <p className="accordion-description">
                            {showBecomePartner
                              ? ConqtUniversityContent.HTBAPLOGINDESCRIPTION2
                              : ""}
                          </p>
                          <p className="accordion-description">
                            {showBecomePartner
                              ? ConqtUniversityContent.HTBAPLOGINDESCRIPTION3
                              : ""}
                          </p>
                          <p className="accordion-description">
                            {showBecomePartner
                              ? ConqtUniversityContent.HTBAPLOGINDESCRIPTION4
                              : ""}
                          </p>
                        </div>
                        <div className="image-htbap">
                          <div>
                            <figure>
                              <img
                                className="image-accordion-1"
                                src={showBecomePartner ? loginmobile : ""}
                              />
                              <figcaption>On Mobile Screen</figcaption>
                            </figure>
                          </div>
                          <div>
                            <figure>
                              <img
                                className="image-accordion-2"
                                src={showBecomePartner ? logindesktop : ""}
                              />
                              <figcaption>On Computer Screen</figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <div className="stepper">
                        {/* <div className="stepper-heading">Step</div> */}
                        <div className="stepper-round">6</div>
                      </div>
                      <h5 className="accordion-heading">
                        {" "}
                        {showBecomePartner
                          ? ConqtUniversityContent.HTBAPDASHBOARDHEADER
                          : ""}
                      </h5>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="drop-down-container">
                        <div className="registration-description">
                          <p className="accordion-description">
                            {showBecomePartner
                              ? ConqtUniversityContent.HTBAPDASHBOARDDESCRIPTION
                              : ""}
                          </p>
                        </div>
                        <div className="image-htbap">
                          <figure>
                            <img
                              className="image-accordion-1"
                              src={
                                showBecomePartner ? partnerdashboardmobile : ""
                              }
                            />
                            <figcaption>On Mobile Screen</figcaption>
                          </figure>
                          <figure>
                            <img
                              className="image-accordion-2"
                              src={
                                showBecomePartner ? partnerdashboarddesktop : ""
                              }
                            />
                            <figcaption>On Computer Screen</figcaption>
                          </figure>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>{" "}
                </>
              )}{" "}
            </>
          )}
        </Accordion>
      )}
    </div>
  );
}

export default HowToBecomePartnerSteps;
