import React, { useEffect, useState } from "react";
import "./singleproduct.css";
import HomePageLayout from "../../../Layouts/HomePageLayout";
import SingleProductTopImage from "../../../Components/SingleProduct/SIngleProductTopImage";
import Youtube from "../../../Components/SingleProduct/Youtube";
import { useSelector, useDispatch } from "react-redux";
import SingleProductNavBar from "../../../Components/SingleProduct/SingleProductNavBar/SingleProductNavBar";
import Description from "../../../Components/SingleProduct/Description";
import BreadCrumbs from "../../../Components/BreadCrumbs/BreadCrumbs";
import { useNavigate, useParams } from "react-router-dom";
// import BottomNav from "../../../Components/MobileComponents/BottomNav";
import SingleProductTopInMobile from "../../../Components/SingleProduct/SingleProductTopInMobile/SingleProductTopInMobile";
import FadeLoader from "react-spinners/FadeLoader";
import axios from "axios";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import SingleProductPageReducer, {
  updatedSingleProductsData,
} from "../../../Store/Reducer/SingleProductPageReducer";
import { PRODUCT_DETAILS } from "../../../ApiEndPoints";
import { updatedAllProductsData } from "../../../Store/Reducer/AllProductsReducer";
import arrow from "../../../Assets/Images/breadArrow.png";
import { Helmet } from "react-helmet";

const SingleProduct = () => {
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);
  const navigate = useNavigate();
  const [selectedId, isSelectedId] = useState(0);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const productName = useParams();

  // console.log(productName, "product name");
  // const handleLoading = () => {
  //   setIsLoading(false);
  // };

  const onClickAllProducts = () => {
    navigate("/view-all-product");
  };

  useEffect(() => {
    setLoader(true);
    // console.log("use effect calling");
    axios
      .get(`${BaseUrl}/product/Get-All-Products`)
      .then((response) => {
        // console.log("api is calling");
        // console.log(response.data.data, "all products data");
        dispatch(updatedAllProductsData(response.data.data));
        const selectedDetails = response.data.data.filter((e) => {
          // console.log("updated all products " ,e)
          return e.product_name === productName.name.replace(/-/g, " ");
        });
        // console.log("selectedDetails @@@@@@@@@", selectedDetails);
        // isSelectedId(selectedDetails[0].id);
        axios
          .get(`${BaseUrl}${PRODUCT_DETAILS}${selectedDetails[0].id}`)
          .then((response) => {
            // console.log(response.data.data, "data of single products @@@@@");
            dispatch(updatedSingleProductsData(response.data.data));
            setLoader(false);
            // navigate(
            //   "/" + response.data.data.productDetail.product_name.replace(/ /g, "-")
            // );
            window.scrollTo(0, 0);
            // setData(response.data.data);
            // console.log(response.data.data[0].product_name.replace(" ","-"))
          })
          .catch((error) => {
            setLoader(false);
            console.log(error);
          });
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  }, []);

  console.log(SingleProduct, "SingleProduct data in single product page");

  return (
    <>
      {Object.keys(SingleProduct).length != 0 ? (
        <>
          <Helmet>
            <title>
              {SingleProduct?.productDetail?.product_name}
              {" - "}
              {SingleProduct?.productDetail?.category_name}
              {" | "}
              {"ConQt"}
            </title>
            <meta
              name="description"
              content={SingleProduct?.productDetail?.product_description}
            />
          </Helmet>
          <div className="d-none  d-md-block product-page">
            <HomePageLayout>
              <center>{loader && <FadeLoader color="#36d7b7" />}</center>
              <div className="container">
                <BreadCrumbs
                  title1="All Products"
                  title2={
                    <>
                      {SingleProduct &&
                        SingleProduct.productDetail.product_name}{" "}
                      &nbsp;
                      <span className="sp-beta">Beta</span>
                    </>
                  }
                  title3=""
                  sep1=""
                  sep2={arrow}
                  sep3=""
                  sep4=""
                  onClickTitle1={onClickAllProducts}
                  Title2class={"sp-bread-title"}
                  Title1class={"sp-bread-all-products"}
                />
              </div>
              <div className="mainproductimage">
                <SingleProductTopImage />
              </div>
              <div>
                {/* <SingleProductContent /> */}
                <SingleProductNavBar />
              </div>

              <div>{/* <Youtube /> */}</div>
            </HomePageLayout>
          </div>
          <div className="d-block d-sm-none">
            <HomePageLayout>
              <center>{loader && <FadeLoader color="#36d7b7" />}</center>
              <div className="container">
                <BreadCrumbs
                  title1="All Products"
                  title2={
                    <>
                      {SingleProduct &&
                        SingleProduct.productDetail &&
                        SingleProduct.productDetail.product_name}{" "}
                      &nbsp;
                      <span className="sp-beta">Beta</span>
                    </>
                  }
                  title3=""
                  sep1=">"
                  sep2={arrow}
                  sep3=""
                  sep4=""
                  onClickTitle1={onClickAllProducts}
                  Title2class={"sp-bread-title"}
                  Title1class={"sp-bread-all-products"}
                />
              </div>
              <div>
                <SingleProductTopInMobile />
              </div>
              <div>
                <SingleProductNavBar />
              </div>
              {/* <div>
                                                <BottomNav />
                                        </div> */}
            </HomePageLayout>
          </div>
        </>
      ) : null}
    </>
  );
};

export default SingleProduct;
