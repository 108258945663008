import React from "react";
import "./LandingPageForAdvtHomeBanner.css";
import logolanding from "../../../Assets/Images/logolanding.png";
import landingheaderimage from "../../../Assets/Svg/landingheaderimage.svg";

const LandingPageForAdvtHomeBanner = () => {
  const handleSignUp =()=>{
    window.location.href = 'https://login.conqt.com/vendor-registration';
  }
  return (
    <div>
      <div className="lp-logo-button">
        <a href="/"><img src={logolanding} alt="" className="lp-conqt-logo"/></a>
        <button  className="signup-button" onClick={handleSignUp}>Sign Up</button>
      </div>
      <div className="lp-headingadvt">
        <div className="lp-banner-content">
          <div className="lp-banner-home-heading-div">
            <h1 className="lp-banner-home-heading">
              Connect With Certified IT Vendors in minutes.
            </h1>
          </div>
          <div className="lp-banner-home-para-div">
            <p className="lp-banner-home-para">
              Join ConQt today and gain access to an extensive network of
              vendors, cutting-edge solutions, and streamlined transactions.
            </p>
          </div>

          <button className="signup-button" onClick={handleSignUp}>Sign Up</button>
        </div>
        <img className="imagesection" src={landingheaderimage} alt="" />
      </div>
    </div>
  );
};

export default LandingPageForAdvtHomeBanner;
