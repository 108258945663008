import React, { useState, useEffect } from "react";
import "./Popup.css";
import PopupProgressBar from "./ProgessBar/ProgressBar.jsx";
import PageOne from "./Pages/PageOne";
import PageTwo from "./Pages/PageTwo";
import PageThree from "./Pages/PageThree";
import PageFour from "./Pages/PageFour";
import PageFive from "./Pages/PageFive";
import { clearCallExpertData } from "../../../Store/Reducer/CallExpertData";
import { useDispatch, useSelector } from "react-redux";

const HomePagePopup = ({ close }) => {
  const [page, setPage] = useState("pageone");
  const dispatch = useDispatch();
  const nextPageNumber = (pageNumber) => {
    console.log(pageNumber);
    switch (pageNumber) {
      case "1":
        setPage("pageone");
        break;
      case "2":
        setPage("pagetwo");
        break;
      case "3":
        setPage("pagethree");
        break;
      case "4":
        setPage("pagefour");
        break;
      case "5":
        setPage("pagefive");
        break;
      default:
        setPage("1");
    }
  };

  const onSubmit = (submit) => {
    close(submit);
  };

  return (
    <>
      <div className="popup-content-callExpert">
        <div className="popup-header">
          <div className="popup-close-btn-div">
            <button
              onClick={() => {
                close(false);
                dispatch(clearCallExpertData());
                console.log("clicked");
              }}
              className="popup-close-btn"
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 0C6.64286 0 0 6.64286 0 15C0 23.3571 6.64286 30 15 30C23.3571 30 30 23.3571 30 15C30 6.64286 23.3571 0 15 0ZM20.7857 22.5L15 16.7143L9.21429 22.5L7.5 20.7857L13.2857 15L7.5 9.21429L9.21429 7.5L15 13.2857L20.7857 7.5L22.5 9.21429L16.7143 15L22.5 20.7857L20.7857 22.5Z"
                  fill="#3EB489"
                />
              </svg>
            </button>
          </div>
          <div className="popup-header-btncontent">
            <div className="popup-header-back-btn">
              <button  onClick={() => {
                close(false);
                dispatch(clearCallExpertData());
                console.log("clicked");
              }}
              className="popup-back-btn">
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.78378 16L0 8L7.78378 0L9.6 1.86667L3.63243 8L9.6 14.1333L7.78378 16Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="popup-header-title-div">
              <h1 className="popup-title">
                Make a faster decision by sharing more details
              </h1>
            </div>
            <div className="popup-header-close-popup-btn">
              <button  onClick={() => {
                close(false);
                dispatch(clearCallExpertData());
                console.log("clicked");
              }}
              className="popup-back-btn">
                <svg
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="36" height="36" rx="18" fill="#3EB489" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.9991 19.299L24.699 26L26 24.701L19.2983 18L26 11.3009L24.7008 10L17.9991 16.701L11.2992 10L10 11.3009L16.6999 18L10 24.6991L11.2992 26L17.9991 19.299Z"
                    fill="#F0F0F0"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="popup-body">
          <div className="popup-progress-bar">
            <PopupProgressBar page={page} onPageNumberClick={nextPageNumber} />
          </div>
          {
            {
              pageone: <PageOne onButtonClick={nextPageNumber} />,
              pagetwo: <PageTwo onButtonClick={nextPageNumber} />,
              pagethree: <PageThree onButtonClick={nextPageNumber} />,
              pagefour: <PageFour onButtonClick={nextPageNumber} />,
              pagefive: (
                <PageFive
                  onButtonClick={nextPageNumber}
                  onSubmitForm={onSubmit}
                />
              ),
            }[page]
          }
        </div>
      </div>
    </>
  );
};

export default HomePagePopup;
