import React, { useEffect, useRef } from "react";
import "./aboutUs.css";
import HomePageLayout from "../../Layouts/HomePageLayout";
import WhatIsConqt from "../../Components/WhatIsConqt/WhatIsConqt";
import Mission from "../../Components/Mission/Mission";
import HBanner from "./../../Components/HBanner/index";
import History from "../../Components/History";
import OurTeam from "../../Components/AboutUs/OurTeam";
import Location from "../../Components/AboutUs/Location";
import NextMove from "../../Components/AboutUs/NextMove";
import { AboutUsContent } from "../../Content/AboutUs.content";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  // const usa = useRef<HTMLInputElement>(null);
  const usa = React.createRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <HomePageLayout>
      <Helmet>
        <title>{"About ConQt - Connecting Clients with Vendors"}</title>
        <meta
          name="description"
          content={
            "ConQT is a B2B Information Technology Marketplace that connects businesses who sell technology products and services with other businesses who are looking to buy them."
          }
        />
      </Helmet>
      <div ref={usa}>
        <HBanner title={"ABOUT"} spanName={"US"} />
      </div>

      <WhatIsConqt />
      <Mission />
      <History />
      <div className="py-3">
        <OurTeam />
      </div>
      <div>
        <NextMove />
      </div>
      <div>
        <Location />
      </div>
    </HomePageLayout>
  );
};

export default AboutUs;
