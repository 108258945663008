import React, { useState, useEffect } from "react";
import "./sidenavsuboptions.css";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import axios from "axios";
import { DEPARTMENT_DETAILS } from "../../../ApiEndPoints";
import { updatedSingleDepartmentProductsData } from "../../../Store/Reducer/SingleDepartmentReducer";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../../../Assets/Svg/Rectangle29.svg";
import { useParams, useNavigate } from "react-router-dom";

const SideNavSubDepartment = ({ title }) => {
  // const [toggle, setToggle] = useState(false)

  // const hadnelClickToggle = () => {
  //     setToggle(!toggle)
  // }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isChecked, setIsChecked] = useState();
  const { id, name } = useParams();

  useEffect(() => {
    // console.log(id, name, "test");
    axios
      .get(`${BaseUrl}${DEPARTMENT_DETAILS}`)
      .then((response) => {
        // console.log(response.data.data, "department details w");
        setData(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <section className="container">
        <h4 className="py-2">{title}</h4>
        <div>
          <>
            {data.map((items, index) => {
              return (
                items.product_count != 0 && (
                  <button
                    className="side-nav-sub-dept"
                    onClick={() => {
                      setIsChecked(index);
                      navigate(
                        `/department/${items.id}/${items.department_name
                          .split(" ")
                          .join("-")}`
                      );
                    }}
                  >
                    {/* <img src={CheckBox} alt="" /> */}
                    <input type="checkbox" checked={isChecked === index} />
                    <span className="px-1">{items.department_name}</span>
                  </button>
                )
              );
            })}
          </>
        </div>
      </section>
    </>
  );
};

export default SideNavSubDepartment;
