import React from "react";
import "./ShippingPolicy.css";
import HomePageLayout from "../../Layouts/HomePageLayout";
import { ShippingPolicyContent } from "../../Content/ShippingContent";
import { Helmet } from "react-helmet";

const ShippingPolicy = () => {
  return (
    <>
      <HomePageLayout>
        <Helmet>
          <title>{"Shipping & Delivery Policy - ConQT"}</title>
        </Helmet>
        <div className="container mt-5 mb-5 shipping-policy-container">
          <h2 className="shipping-policy-title">
            {ShippingPolicyContent.SHIPPINGPOHEADING1}
          </h2>
          <hr className="shipping-policy-hr-line"></hr>
          <p className="shipping-policy-para mb-5">
            {ShippingPolicyContent.SHIPPINGPOPARA1}
          </p>

          <h2 className="shipping-policy-title">
            {ShippingPolicyContent.SHIPPINGPOHEADING2}
          </h2>
          <hr className="shipping-policy-hr-line"></hr>

          <p className="shipping-policy-para">
            {ShippingPolicyContent.SHIPPINGPOPARA2}
          </p>
        </div>
      </HomePageLayout>
    </>
  );
};

export default ShippingPolicy;
