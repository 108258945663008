import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./SingleProductImageSlider.css";
import { useSelector } from "react-redux";
import { SideBySideMagnifier } from "react-image-magnifiers";

const SingleProductImageSlider = () => {
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);
  const [selectedImage, setSelectedImage] = useState(null);
  const imagesData = SingleProduct.productImages;
  const parentImages = {
    original: SingleProduct.productDetail.product_image_url,
    thumbnail: SingleProduct.productDetail.product_image_url,
  };
  const images = imagesData.map((image) => {
    return { original: image.img_url, thumbnail: image.img_url };
  });

  images.unshift(parentImages);

  // const handleImageSelect = (e) => {
  //    setSelectedImage(e.relatedTarget.currentSrc);
  // };

  // const handleMagnifierClose = () => {
  //   setSelectedImage(null);
  // };

  // const renderMagnifier = () => {
  //   if (!selectedImage) {
  //     return null;
  //   }

  //   return (
  //     <SideBySideMagnifier
  //       imageSrc={selectedImage}
  //       imageAlt={""}
  //       largeImageSrc={selectedImage}
  //       largeImageAlt={""}
  //       onClose={handleMagnifierClose}
  //       fillAvailableSpace={false}
  //     />
  //   );
  // };

  // const handleImageClick = (event) => {
  //   const { index } = event.target.dataset;
  //   console.log(childImages,"handle image click")
  //   setSelectedImage(childImages[index]);
  // };

  // const handleMagnifierClose = () => {
  //   setSelectedImage(null);
  // };

  const renderItem = (item) => {
    return (
      <div className="image-gallery-image">
        <SideBySideMagnifier
          className="image-magnifier"
          imageSrc={item.original}
          imageAlt={"not available"}
          alwaysInPlace={true}
          overlayOpacity={0.5}
          overlayBoxOpacity={1}
          zoomPosition="beside"
          fillAvailableSpace={true}
        />
      </div>
    );
  };

  return (
    <div>
      {/* <div className="sp-parent">
        <div className="sp-gallery-div">
          <ImageGallery
            items={images}
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            useBrowserFullscreen={false}
            onMouseOver={handleImageSelect}
          />
        </div>
        <div className="sp-magnified-div">{renderMagnifier()}</div>
      </div> */}
      {SingleProduct && (
        <ImageGallery
          items={images}
          // if you want to zooming effect you can un comment below render function
          // renderItem={renderItem}
          showPlayButton={false}
          showFullscreenButton={false}
          showNav={false}
          useBrowserFullscreen={false}
        />
      )}
    </div>
  );
};

export default SingleProductImageSlider;
