import React from "react";
import "./sidenavigation.css";
import ArrowIcon from "../../Assets/Svg/rightSignArrow.svg";
import PlusIcon from "../../Assets/Svg/plusVector.svg";
import { useNavigate } from "react-router-dom";
import SideNavSubOptions from "./SideNavSubOptions";
import SideNavSubDepartment from "./SideNavSubOptions/department";
import SideNavSubCategory from "./SideNavSubOptions/category";
import Rate from "rc-rate";

const SideNavigation = () => {
  const navigate = useNavigate();
  const onClickAllProducts = () => {
    navigate("/view-all-product");
  };
  return (
    <>
      <div className="d-flex flex-column flex-shrink-0 py-3 text-dark sidenav-main mb-3">
        {/* <div className="sidebar">
                    <div className='d-flex align-items-center justify-content-between' onClick={onClickAllProducts}>
                        <p className='side-nav-links'>All Products</p>
                        <img src={ArrowIcon} alt="" />
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <p className='side-nav-links'>Software</p>
                        <img src={ArrowIcon} alt="" />
                    </div>
                    <div className='d-flex align-items-center justify-content-between py-2'>
                        <p className='side-nav-links'>Hardware</p>
                        <img src={ArrowIcon} alt="" />
                    </div>
                    <div style={{ paddingTop: "200px" }} className="d-flex">
                        <span><img src={PlusIcon} alt="" /></span>
                        <p className='side-nav-links px-2'>More Categories</p>
                    </div>
                </div> */}
        <div className="container side-header">
          <h4 style={{ paddingTop: "7px" }}>SHOP BY</h4>
        </div>
        {/* <div className='container'>
                    <h4>Search</h4>
                    <input type="text"
                        className='input-mbl-search-cat'
                    />
                </div> */}
        <div className="pt-2">
          <SideNavSubCategory title={"Categories"} />
          <div>
            <hr />
          </div>
        </div>

        {/* <div className='py-2'>
                    <SideNavSubOptions
                        title={"Service"}
                    />
                    <div>
                        <hr />
                    </div>
                </div> */}

        <div className="">
          <SideNavSubOptions title={"Industries"} />
          <div>
            <hr />
          </div>
        </div>

        <>
          {/* <div>
                                        <h5>Price</h5>
                                        <input type="range" />
                                        <div className='d-flex align-items-center justify-content-between'>
                                                <p className='price-cat-single pt-3'>PRICE: $1 - $1000</p>
                                                <button className='cat-single-filter-btn px-3 py-1'>FILTER</button>
                                        </div>
                                        <div className='container' style={{ marginTop: "15px" }}>
                                                <h4>Rating</h4>
                                                <div style={{ marginTop: "-20px" }}>
                                                        <Rate
                                                                defaultValue={5}
                                                                // onChange={onChange}
                                                                style={{ fontSize: 40 }}
                                                                // allowHalf
                                                                // allowClear={true}
                                                                disabled={true}
                                                        />
                                                </div>
                                                <div style={{ marginTop: "-30px" }}>
                                                        <Rate
                                                                defaultValue={4}
                                                                // onChange={onChange}
                                                                style={{ fontSize: 40 }}
                                                                // allowHalf
                                                                // allowClear={true}
                                                                disabled={true}
                                                        />
                                                </div>
                                                <div style={{ marginTop: "-30px" }}>
                                                        <Rate
                                                                defaultValue={3}
                                                                // onChange={onChange}
                                                                style={{ fontSize: 40 }}
                                                                // allowHalf
                                                                // allowClear={true}
                                                                disabled={true}
                                                        />
                                                </div>
                                                <div style={{ marginTop: "-30px" }}>
                                                        <Rate
                                                                defaultValue={2}
                                                                // onChange={onChange}
                                                                style={{ fontSize: 40 }}
                                                                // allowHalf
                                                                // allowClear={true}
                                                                disabled={true}
                                                        />
                                                </div>
                                                <div style={{ marginTop: "-30px" }}>
                                                        <Rate
                                                                defaultValue={1}
                                                                // onChange={onChange}
                                                                style={{ fontSize: 40 }}
                                                                // allowHalf
                                                                // allowClear={true}
                                                                disabled={true}
                                                        />
                                                </div>
                                        </div>
                                        <div>
                                                <hr />
                                        </div>
                                </div> */}
        </>

        <div className="pt-2">
          <SideNavSubDepartment title={"Department"} />
          <div>
            <hr />
          </div>
        </div>

        {/* <div className='py-2'>
                    <SideNavSubOptions
                        title={"Hardware"}
                    />
                    <div>
                        <hr />
                    </div>
                </div>

                <div className=''>
                    <SideNavSubOptions
                        title={"Others"}
                    />
                    {/* <div>
                        <hr />
                    </div> *
                </div> */}
      </div>
    </>
  );
};

export default SideNavigation;
