import React from "react";
import "./LoginBackground.css";
import LeftPatch from "../../../Assets/Images/lg-bg-left.png";
import RightPatch from "../../../Assets/Images/lg-bg-right.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import "swiper/swiper.min.css";
import WEBDESIGN from "../../../Assets/Images/web-design.png";
import macbook from "../../../Assets/Images/MacBook.png";
import "swiper/css/pagination";
import create from "../../../Assets/Images/create-project-lap.png";
import customer from "../../../Assets/Images/cust-dash-lap.png";
import orders from "../../../Assets/Images/my-orders-lap.png";
import project from "../../../Assets/Images/project-lap.png";

const LoginBackground = () => {
  return (
    <>
      <div className="lg-bg-main">
        <div className="lg-bg-patchs">
          <img className="lg-left-patch" src={LeftPatch} alt=""></img>
          <img className="lg-right-patch" src={RightPatch} alt=""></img>
        </div>
      </div>
      <div className="lg-slider">
        <Swiper
          pagination={true}
          slidesPerView={1}
          spaceBetween={10}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            240: {
              slidesPerView: 1,
            },
            1268: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="lg-left-sliders-div">
              <h3 className="lg-header">
                Summarize your activities all in one place!
              </h3>
              <p className="lg-content">
                Your personal dashboard lets you find all information collated
                in a single window. Gives you accountability of the projects
                executed in a simple to understand visual summary.
              </p>
              <div className="lg-images-div">
                <img className="lg-slider-images" src={customer} alt=""></img>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="lg-left-sliders-div">
              <h3 className="lg-header">Enlist your requirements now!</h3>
              <p className="pt-3"></p>
              <p className="lg-content">
                Wondering how to put up your requirements? Our formats are
                designed to smoothen the process of posting your project. The
                fields ensure to capture essential information that aids in
                attracting vendor responses.
              </p>
              <div className="lg-images-div">
                <img className="lg-slider-images" src={create} alt=""></img>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="lg-left-sliders-div">
              <h3 className="lg-header">
                Access your consolidate Product purchase history!
              </h3>
              <p className="lg-content">
                Keep a track of the products of your interest. Segregate the
                ones that are ordered, just enquired or delivered. Make
                re-ordering hassle-free. In addition, also track the status of
                in-process orders.
              </p>
              <div className="lg-images-div">
                <img className="lg-slider-images" src={orders} alt=""></img>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="lg-left-sliders-div">
              <h3 className="lg-header">
                Let the system create a comparison table for your perusal!
              </h3>
              <p className="lg-content">
                Comparing proposals is no longer time-consuming. Find everything
                listed in one place, compare prices and view the details of the
                ones that catch your interest. Make informed decisions with
                lesser efforts.
              </p>
              <div className="lg-images-div">
                <img className="lg-slider-images" src={project} alt=""></img>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <p className="lg-text">© 2022 Tech Curve AI & Innovations</p>
      </div>
    </>
  );
};

export default LoginBackground;
