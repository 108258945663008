import React from "react";
import HomePageLayout from "../../Layouts/HomePageLayout";
import SellYourSolutionHeroBanner from "../../Components/SellYourSolution/SellYourSolutionHeroBanner/SellYourSolutionHeroBanner";
import HowToSellOnConqt from "../../Components/SellYourSolution/HowToSellOnConqt/HowToSellOnConqt";
import HtsocVideo from "../../Components/SellYourSolution/HtsocVideo/HtsocVideo";
import SysVimeoVideo from "../../Components/SellYourSolution/SysVimeoVideo/SysVimeoVideo";
import SysRegisterHere from "../../Components/SellYourSolution/SysRegisterHere/SysRegisterHere";
import SysFeedbackSlider from "../../Components/SellYourSolution/SysFeedbackSlider/SysFeedbackSlider";
import SysStartSelling from "../../Components/SellYourSolution/SysStartSelling/SysStartSelling";
// import CardGridView from "../../Components/SellYourSolution/ProductCards/CardGridView";
import HomePageAllProducts from "../../Components/HomePage/AllProducts/AllProducts";

const SellYourSolution = () => {
  return (
    <>
      <HomePageLayout>
        <SellYourSolutionHeroBanner />
        <HowToSellOnConqt />
        <HtsocVideo />
        {/* <CardGridView /> */}
        < HomePageAllProducts />
        {/* <SysVimeoVideo />
        <SysRegisterHere /> 
        <SysFeedbackSlider /> */}
        <SysStartSelling />
      </HomePageLayout>
    </>
  );
};

export default SellYourSolution;
