import React from "react";
import "./specification.css";
import Card from "react-bootstrap/Card";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";


const Specifications = ({ Specifications }) => {
        return (
                <>
                        <Card className="container my-3">
                                <Card.Header className="single-desc-main">
                                        <p className="single-spec-top-header">Specifications</p>
                                </Card.Header>
                                <Card.Body>
                                        <div className="mbl-spec">
                                                <p className="mbl-spec">{parse(Specifications)}</p>
                                        </div>
                                </Card.Body>
                        </Card>
                </>
        );
};

export default Specifications;
