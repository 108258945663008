import React from 'react';
import './bottomContentTaC.css';
import Policy from '../../Assets/Svg/policy.svg';

const TermsAndConditionsBottomContent = () => {
        return (
                <>
                        <section className='container'>
                                <div>
                                        <h1 className='btm-tac-content'>Policy</h1>
                                        <div className=''>
                                                <div className="row align-items-center justify-content-between">
                                                        <div className='col-12 col-md-5'>
                                                                <img src={Policy} alt="" style={{ width: "100%", height: "100%" }} />
                                                        </div>
                                                        <div className='col-12 col-md-6'>
                                                                <div className='btm-tac-content-para'>
                                                                        <p>Thank you for providing the specific details for your refund policy. Please find below a sample 60-day refund policy based on your requirements</p>
                                                                </div>
                                                                <div className='btm-tac-content-para'>
                                                                        <p>We understand that circumstances may change after purchasing an opportunity, and we want to ensure that our customers are satisfied with their purchases. If for any reason you are not satisfied with the opportunity you purchased from us, we offer a 60-day period during which you can request a replacement opportunity. </p>
                                                                        <p>In the event that you have not received a response from the customer after purchasing the opportunity, our team will provide you with another opportunity. However, we do not offer refunds for purchases. </p>
                                                                        <p>Before we provide you with a replacement opportunity, we will request full information about your efforts to contact the customer, including the emails you have sent and any other relevant information. We will use this information to validate your request for a replacement opportunity and to ensure that the customer is provided with the best possible experience. </p>
                                                                        <p>Please note that we reserve the right to refuse a replacement opportunity if we believe that the information provided is inaccurate or if we suspect that the opportunity has been abused or misused. </p>
                                                                </div>
                                                        </div>
                                                        <div className='btm-tac-content-para mb-3'>
                                                                <p>If you have any questions or concerns about our refund policy, please do not hesitate to contact us. We are committed to providing our customers with the highest level of service and support. </p>
                                                        </div>
                                                </div>

                                        </div>
                                </div>
                        </section>
                </>
        )
}

export default TermsAndConditionsBottomContent 