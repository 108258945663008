import React from "react";
import "./HowToBecomePartner.css";
import { ConqtUniversityContent } from "../../../Content/ConqtUniversity.content";

const HowToBecomePartner = ({
  showBecomePartner,
  showProject,
  showSubmitProposal,
  showAddProducts,
}) => {
  return (
    <>
      <div className="intro-to-partner-registration">
        <h3 className="htbap-header">
          {showBecomePartner
            ? ConqtUniversityContent.HTBAPINTRODUCTIONHEADER
            : showProject
            ? ConqtUniversityContent.GAPINTRODUCTIONHEADER
            : showSubmitProposal
            ? ConqtUniversityContent.PROPOSALINTRODUCTIONHEADER
            : showAddProducts
            ? ConqtUniversityContent.ADDPRODUCTHEADER
            : ""}
        </h3>
        <hr className="heading-line" />
      </div>
      <div className="partner-registration-video">
        <iframe
          className="registartion-video"
          src={
            showBecomePartner
              ? "https://www.youtube.com/embed/EKlLXvU8KPs?controls=0"
              : showProject
              ? "https://www.youtube.com/embed/xTwsIiAEhvk"
              : showSubmitProposal
              ? "https://www.youtube.com/embed/xTwsIiAEhvk"
              : showAddProducts
              ? "https://www.youtube.com/embed/7kkhLD6fOsY"
              : ""
          }
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </>
  );
};
export default HowToBecomePartner;
