import React, { useState, useEffect } from "react";
import logo from "../../../Assets/Images/logo.png";
import "./LoginForm.css";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { CUSTOMER_LOGIN } from "../../../ApiEndPoints";

const LoginForm = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log(data);
    if (rememberMe) {
      localStorage.setItem("email", data.user_name);
      localStorage.setItem("password", data.password);
    }
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      user_name: data.user_name,
      password: data.password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${CUSTOMER_LOGIN}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === 200) {
          toast.success("You have successfully logged in");
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("logintype", 1);
          localStorage.setItem("user", JSON.stringify(result.data));
          localStorage.setItem("userInfo", JSON.stringify(result.data));
          localStorage.setItem("user_name", result.data.user_info.user_name);
          navigate("/write-a-review")
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    const storedPassword = localStorage.getItem("password");
    if (storedEmail && storedPassword) {
      setValue("user_name", storedEmail);
      setValue("password", storedPassword);
      setRememberMe(true);
    }
  }, [setValue]);
  return (
    <div className="lg-form">
      <div className="lg-logo-div">
        <img className="lg-logo" src={logo} alt="" />
      </div>
      <h2 className="lg-title">Login as a Customer</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="lg-label-input-div">
          <label className="lg-label">Email Address</label>
          <input
            className="lg-input"
            type="email"
            {...register("user_name", {
              required: true,
              minLength: 3,
              maxLength: 40,
            })}
          />
          {errors.user_name && (
            <span className="error-msg">
              Please enter the valid email address
            </span>
          )}
        </div>
        <div className="lg-label-input-div">
          <label className="lg-label">Password</label>
          <input
            className="lg-input"
            type="password"
            {...register("password", {
              required: true,
              minLength: 3,
              maxLength: 20,
            })}
          />
          {errors.password && (
            <span className="error-msg">Please enter the valid password</span>
          )}
        </div>
        <div className="lg-label-input-div lg-check-div">
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label className="lg-label">Remember me</label>
        </div>
        <button type="submit" className="lg-btn">
          Login
        </button>
        <a href="https://login.conqt.com/reset-password" className="lg-forget">Forget password?</a>
      </form>
      <div className="lg-text-div">
      <p className="lg-dont-text pt-5">
        Don’t have an account?
        <a href="https://login.conqt.com/customer-registration">Sign Up</a>
      </p>
      </div>
    </div>
  );
};

export default LoginForm;
