import React from "react";
import "./TrendingProductsComparison.css";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/swiper.min.css";
import { CompareProductsContent } from "../../../Content/CompareProducts.content";
import SalesforceProductImage from "../../../Assets/Images/product-logo-cp.png";
import OdooProductImage from "../../../Assets/Images/odoo.png";
import SeeqProductImage from "../../../Assets/Images/Seeq.png";
import GsuiteProductImage from "../../../Assets/Images/gsuite.png";
import slidericon from "../../../Assets/Images/slider-icon.png";
import ProductCardComparePage from "./ProductCardComparePage";

SwiperCore.use([Autoplay, Navigation]);

const VsFunction = () => {
  return (
    <div className="versus">
      <h6 className="versus-content">vs</h6>
    </div>
  );
};
const TrendingProductsComparison = () => {
  const fakeRate = 4.3;
  const rating = 4;
  return (
    <>
      <div className="tpc-container">
        <div className="trending-product-comp-container">
          <div className="tpc-header">
            <h3 className="tpc-heading">{CompareProductsContent.TPCHEADER}</h3>
          </div>

          <Swiper
            navigation={true}
            slidesPerView={1}
            spaceBetween={10}
            // pagination={{
            //   clickable: true,
            // }}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              240: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              576: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="trending-product-mySwiper"
          >
            <SwiperSlide>
              <div className="d-flex trending-product-comp-2card-div ">
                <div className="compared-products-card-2">
                  <div className="product-details-container">
                    <ProductCardComparePage
                      image={SalesforceProductImage}
                      name={CompareProductsContent.PRODUCTNAME1}
                      brandName={CompareProductsContent.BRANDNAME1}
                    />
                    <VsFunction />
                    <ProductCardComparePage
                      image={OdooProductImage}
                      name={CompareProductsContent.PRODUCTNAME2}
                      brandName={CompareProductsContent.BRANDNAME2}
                    />
                  </div>
                  <div className="button-tpc">
                    <button className="compare-button-tpc">
                      {CompareProductsContent.COMPAREBUTTON}
                    </button>
                  </div>
                </div>
                <div className="compared-products-card-2">
                  <div className="product-details-container">
                    <ProductCardComparePage
                      image={GsuiteProductImage}
                      name={CompareProductsContent.PRODUCTNAME3}
                      brandName={CompareProductsContent.BRANDNAME3}
                    />
                    <VsFunction />
                    <ProductCardComparePage
                      image={SeeqProductImage}
                      name={CompareProductsContent.PRODUCTNAME4}
                      brandName={CompareProductsContent.BRANDNAME4}
                    />
                  </div>
                  <div className="button-tpc">
                    <button className="compare-button-tpc">
                      {CompareProductsContent.COMPAREBUTTON}
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="d-flex trending-product-comp-2card-div ">
                <div className="compared-products-card-2">
                  <div className="product-details-container">
                    <ProductCardComparePage
                      image={SalesforceProductImage}
                      name={CompareProductsContent.PRODUCTNAME1}
                      brandName={CompareProductsContent.BRANDNAME1}
                    />
                    <VsFunction />
                    <ProductCardComparePage
                      image={OdooProductImage}
                      name={CompareProductsContent.PRODUCTNAME2}
                      brandName={CompareProductsContent.BRANDNAME2}
                    />
                  </div>
                  <div className="button-tpc">
                    <button className="compare-button-tpc">
                      {CompareProductsContent.COMPAREBUTTON}
                    </button>
                  </div>
                </div>
                <div className="compared-products-card-2">
                  <div className="product-details-container">
                    <ProductCardComparePage
                      image={GsuiteProductImage}
                      name={CompareProductsContent.PRODUCTNAME3}
                      brandName={CompareProductsContent.BRANDNAME3}
                    />
                    <VsFunction />
                    <ProductCardComparePage
                      image={SeeqProductImage}
                      name={CompareProductsContent.PRODUCTNAME4}
                      brandName={CompareProductsContent.BRANDNAME4}
                    />
                  </div>
                  <div className="button-tpc">
                    <button className="compare-button-tpc">
                      {CompareProductsContent.COMPAREBUTTON}
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="d-flex trending-product-comp-2card-div ">
                <div className="compared-products-card-2">
                  <div className="product-details-container">
                    <ProductCardComparePage
                      image={SalesforceProductImage}
                      name={CompareProductsContent.PRODUCTNAME1}
                      brandName={CompareProductsContent.BRANDNAME1}
                    />
                    <VsFunction />
                    <ProductCardComparePage
                      image={OdooProductImage}
                      name={CompareProductsContent.PRODUCTNAME2}
                      brandName={CompareProductsContent.BRANDNAME2}
                    />
                  </div>
                  <div className="button-tpc">
                    <button className="compare-button-tpc">
                      {CompareProductsContent.COMPAREBUTTON}
                    </button>
                  </div>
                </div>
                <div className="compared-products-card-2">
                  <div className="product-details-container">
                    <ProductCardComparePage
                      image={GsuiteProductImage}
                      name={CompareProductsContent.PRODUCTNAME3}
                      brandName={CompareProductsContent.BRANDNAME3}
                    />
                    <VsFunction />
                    <ProductCardComparePage
                      image={SeeqProductImage}
                      name={CompareProductsContent.PRODUCTNAME4}
                      brandName={CompareProductsContent.BRANDNAME4}
                    />
                  </div>
                  <div className="button-tpc">
                    <button className="compare-button-tpc">
                      {CompareProductsContent.COMPAREBUTTON}
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="d-flex trending-product-comp-2card-div ">
                <div className="compared-products-card-2">
                  <div className="product-details-container">
                    <ProductCardComparePage
                      image={SalesforceProductImage}
                      name={CompareProductsContent.PRODUCTNAME1}
                      brandName={CompareProductsContent.BRANDNAME1}
                    />
                    <VsFunction />
                    <ProductCardComparePage
                      image={OdooProductImage}
                      name={CompareProductsContent.PRODUCTNAME2}
                      brandName={CompareProductsContent.BRANDNAME2}
                    />
                  </div>
                  <div className="button-tpc">
                    <button className="compare-button-tpc">
                      {CompareProductsContent.COMPAREBUTTON}
                    </button>
                  </div>
                </div>
                <div className="compared-products-card-2">
                  <div className="product-details-container">
                    <ProductCardComparePage
                      image={GsuiteProductImage}
                      name={CompareProductsContent.PRODUCTNAME3}
                      brandName={CompareProductsContent.BRANDNAME3}
                    />
                    <VsFunction />
                    <ProductCardComparePage
                      image={SeeqProductImage}
                      name={CompareProductsContent.PRODUCTNAME4}
                      brandName={CompareProductsContent.BRANDNAME4}
                    />
                  </div>
                  <div className="button-tpc">
                    <button className="compare-button-tpc">
                      {CompareProductsContent.COMPAREBUTTON}
                    </button>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      </div>
    </>
  );
};
export default TrendingProductsComparison;
