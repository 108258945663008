import React from 'react';
import './tachbanner.css'

const TermsAndConditionsHb = ({ title, spanName }) => {
        return (
                <>
                        <section className='home-banner-tac'>
                                <div className="d-flex align-items-center justify-content-center">
                                        <div style={{ marginTop: "150px" }}>
                                                <h1>{title} & <span style={{ color: "#3EB489" }}>{spanName}</span></h1>
                                        </div>
                                </div>
                        </section>
                </>
        )
}

export default TermsAndConditionsHb