import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../ApiBaseUrl/Api";

export const getAllProductList = createAsyncThunk("productList", async () => {
    try {
        var requestOptions = {
            method: "GET",
            redirect: "follow",
        };
        const response = await fetch(`${BaseUrl}/product/Get-All-Products`, requestOptions);
        const result = await response.json();
        // console.log("result", result.data);
        return result.data;
    } catch (error) {
        console.log("error", error);
        throw error;
    }
});


export const myReducer = createSlice({
  name: "allProductList",
  initialState: {
    loading: false,
    productList: [],
    compareProductList: [],
  },
  reducers: {
    updateSelectProductList: (state, action) => {
      state.selectedSolutionId = action.payload;
    },
    updateCompareProductList: (state, action) => {
      state.compareProductList = action.payload;
    },
  },
  extraReducers: {
    [getAllProductList.pending]: (state) => {
      state.loading = true;
    },
    [getAllProductList.fulfilled]: (state, {payload}) => {
      state.loading = false;
      state.productList = payload; 
    },
   

  },


})

export const {
    updateSelectProductList, updateCompareProductList

} = myReducer.actions;



export default myReducer.reducer;