import React, { useState, useEffect } from 'react';
import './addIcon.css';
import plusbutton from '../../../Assets/Images/Union.png';
import ProductPopup from '../ProductPopup';
import { getAllProductList } from '../../../Store/Reducer/AllProductsList';
import { useDispatch, useSelector } from 'react-redux';
import ImageCompo from '../ImageCompo';
import { updatedCurrentSelectedProduct2, updatedCurrentSelectedProduct3 } from '../../../Store/Reducer/AllProductsReducer';
import ImageCompo2 from '../ImageCompo2';

const AddIcon2 = () => {

        const { currentSelectedProduct2, currentSelectedProduct1, currentSelectedProduct3 } = useSelector((state) => state.allProducts);
        const [open, setOpen] = useState(false);
        const [showProduct, setShowProduct] = useState(currentSelectedProduct2);

        console.log("show product", showProduct)

        const dispatch = useDispatch();

        const handleCloseProduct = () => {
                dispatch(updatedCurrentSelectedProduct2(currentSelectedProduct3));
                dispatch(updatedCurrentSelectedProduct3(null));
        }

        const handleClose = () => {
                setOpen(false);
        }

        console.log("open", open)



        const { AllProducts } = useSelector((state) => state.allProducts);
        console.log("AllProducts", AllProducts)


        function filterItemsById(id) {
                return AllProducts.filter((item) => item.id !== id)
        }

        const itemId = currentSelectedProduct1?.id;
        // console.log("itemId", itemId)
        const filteredItems = filterItemsById(itemId);


        return (
                <>
                        {currentSelectedProduct2 ? (
                                <ImageCompo2 handleCloseProduct={handleCloseProduct} />) : (<>
                                        <ProductPopup open={open} handleClose={handleClose} allProducts={filteredItems} />
                                        <section className='add-product-compo'>
                                                <div className="add-plus-icon" style={{ marginTop: "70px" }}>
                                                        <img src={plusbutton} alt="" onClick={() => setOpen(true)} style={{ cursor: "pointer" }} />
                                                </div>
                                                <div className='d-flex align-items-center justify-content-center' style={{ marginTop: "30px" }}>
                                                        <button className='add-product-btn' onClick={() => setOpen(true)}>
                                                                <span className='add-product-btn-name' >Add Product</span>
                                                        </button>
                                                </div>
                                        </section></>)}
                </>
        )
}

export default AddIcon2