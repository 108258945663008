import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SingleProductPageReducer, {
  updatedSingleProductsData,
} from "../../../Store/Reducer/SingleProductPageReducer";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { PRODUCT_DETAILS } from "../../../ApiEndPoints";
import ProductEnquiryPopup from "../../../Models/ProductEnquiryPopup/ProductEnquiryPopup";
import FadeLoader from "react-spinners/FadeLoader";
import "./AllProducts.css";
import { getRandomRating } from "../../../Utlis/Utils";

const AllProductCard = ({
  image,
  CardTopName,
  title,
  rating,
  content,
  price,
  getButton,
  id,
}) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch();
  // console.log({ id }, "id of all products");
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);
  const onClickGetProduct = (e) => {
    // console.log(e, "event from clickGetProduct");
    {e.target.className != "all-products-content" &&  e.target.className != "product-title" &&
        navigate("/" + title.replace(/ /g, "-")); }
  };

  const onClickCallNow = (e) => {
    e.stopPropagation();
    setPopup(!popup);
    // console.log("popup clicked");
  };

  const array = [4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9];
  const fakeRate = getRandomRating(array)

  return (
    <>
      <section>
        <div className="all-product-card" onClick={onClickGetProduct}>
          <div className="all-product-top-name">
            <center className="loader">
              {loader && <FadeLoader color="#36d7b7" />}
            </center>
            <p
              className="d-flex align-items-center justify-content-center py-2"
              style={{ color: "white", fontSize: "13px" }}
            >
              {CardTopName}
            </p>
          </div>
          <div
            className=" container d-flex align-items-center"
            style={{ height: "100px" }}
          >
            <div style={{ width: "80%" }}>
              <img
                style={{ height: "80px", width: "80px" }}
                src={image}
                alt=""
              />
            </div>
            <div style={{ width: "120%" }}>
              <h4 className="product-title" style={{ fontWeight: "500", fontSize: "18px" }}>{title}</h4>
              {/* <img src={rating} alt="" /> <span>(4.4)</span> */}
              <div style={{ marginTop: "-25px" }}>
                <Rate
                  defaultValue={rating > 0 ? rating : fakeRate}
                  // onChange={onChange}
                  style={{ fontSize: 40 }}
                  allowHalf
                  allowClear={true}
                  disabled={true}
                />
              </div>
            </div>
          </div>
          <div
            className="all-products-content-div px-3"
            style={{ height: "70px", paddingTop: "15px" }}
          >
            <p id="pcontent" className="all-products-content">
              {content}{" "}
            </p>
          </div>

          <div className="all-products-hr-and-btn">
            <div className="px-3">
              <hr />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between px-3">
            <div>
              <h6 style={{ fontWeight: 500 }}>{price}</h6>
            </div>

            <div>
              <h5
                style={{ fontWeight: 500 }}
                className="get-btn"
                onClick={onClickCallNow}
              >
                {getButton}
              </h5>
            </div>
          </div>
        </div>
      </section>
      {popup && <ProductEnquiryPopup closeModal={setPopup} />}
    </>
  );
};

export default AllProductCard;
