import React, { useState, useEffect } from "react";
import "./ConqtUniversity.css";
import HomePageLayout from "../../Layouts/HomePageLayout";
import ConqtUniversityBanner from "../../Components/ConqtUniversity/ConqtUniversityBanner/ConqtUniversityBanner";
import WelcomeHeader from "../../Components/ConqtUniversity/WelcomeHeader/WelcomeHeader";
import HowToBecomePartner from "../../Components/ConqtUniversity/HowToBecomePartner/HowToBecomePartner";
import HowToBecomePartnerSteps from "../../Components/ConqtUniversity/HowToBecomePartnerSteps/HowToBecomePartnerSteps";
import Form from "../../Components/ConqtUniversity/Form/Form";
import { Helmet } from "react-helmet";

const ConqtUniversity = () => {
  const [showBecomePartner, setShowBecomePartner] = useState(true);
  const [showProject, setShowProject] = useState(false);
  const [showSubmitProposal, setShowSubmitProposal] = useState(false);
  const [showAddProducts, setShowAddProducts] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HomePageLayout>
        <Helmet>
          <title>
            {
              "ConQt University: Empowering Students with Cutting-Edge Knowledge and Skills"
            }
          </title>
          <meta
            name="description"
            content={
              "Our tutorials are made in a way where you get to know how things work, and how you can use them to your advantage."
            }
          />
        </Helmet>
        <h1>
          <ConqtUniversityBanner
            title={"CONQT"}
            spanName={"UNIVERSITY"}
            name={"Home"}
            pageName={"ConQT University"}
          />
        </h1>
        <WelcomeHeader
          showBecomePartner={showBecomePartner}
          setShowBecomePartner={setShowBecomePartner}
          showProject={showProject}
          setShowProject={setShowProject}
          showSubmitProposal={showSubmitProposal}
          setShowSubmitProposal={setShowSubmitProposal}
          showAddProducts={showAddProducts}
          setShowAddProducts={setShowAddProducts}
        />
        <HowToBecomePartner
          showProject={showProject}
          showBecomePartner={showBecomePartner}
          showSubmitProposal={showSubmitProposal}
          showAddProducts={showAddProducts}
        />
        <HowToBecomePartnerSteps
          showProject={showProject}
          showBecomePartner={showBecomePartner}
          showSubmitProposal={showSubmitProposal}
          showAddProducts={showAddProducts}
        />
        <Form />
      </HomePageLayout>
    </>
  );
};
export default ConqtUniversity;
