import { createSlice } from "@reduxjs/toolkit";

export const myReducer = createSlice({
  name: "conqt",
  initialState: {
    singleDepartment:{department_name:"",department_id:0},
  },
  reducers: {
    updatedSingleDepartmentProductsData: (state, action) => {
       console.log("*****in the store of SingleDepartment", action);
       console.log(action.payload, "action.payload in the reducer of SingleDepartment");
      state.singleDepartment = action.payload;
    },
  },
  extraReducers: {},
});
export const { updatedSingleDepartmentProductsData } = myReducer.actions;

export default myReducer.reducer;