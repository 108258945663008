import React from "react";
import "./breadcrumbs.css";
import HomeIcon from "../../Assets/Svg/BCHome.svg";
import { useNavigate } from "react-router-dom";
import {IoIosArrowForward} from "react-icons/io"

const BreadCrumbs = ({ title1, title2, title3, sep1, sep2, sep3, sep4 ,onClickTitle1 ,Title2class,Title1class }) => {
  const navigate = useNavigate();

  const onClickHomeIcon = () => {
    navigate("/");
  };

  return (
    <>
      <section className="d-flex pt-4">
        <div onClick={onClickHomeIcon} class="d-flex breadcrum-icons" >
          <img
            src={HomeIcon}
            alt=""
            // style={{ marginTop: "-10px", cursor: "pointer", height:"15px" }}
          />
         <span className="px-2"> <img src={sep2} alt=""/></span> 
        </div>
        <div className="d-flex">
          <span onClick={onClickTitle1} style={{ cursor: "pointer" }} className={Title1class}>{title1}</span>
          <span className="px-2"><img src={sep2} alt=""/></span>
        </div>
        <div className="d-flex">
          <span style={{ cursor: "pointer" }} className={Title2class}>{title2}</span>
          <span className="px-2">
            {/* <img src={sep3} alt=""/> */}
            </span>
        </div>
        <div className="d-flex">
          <span>{title3}</span> 
        </div>
      </section>
    </>
  );
};

export default BreadCrumbs;
