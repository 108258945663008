import React from 'react';
import './bestseller.css';
import Rate from 'rc-rate';
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";

const CatCard = ({ image ,title ,desc }) => {
    return (
        <>
            <section className='cat-mbl-top-card' style={{ width: "300px" }}>
                <div className='d-flex align-items-center justify-content-center'>
                    <img className='new-arrival-images' src={image} alt="" />
                </div>
                <div className='' style={{ marginTop: "20px" }}>
                    <h3>{title}</h3>

                    <h5>{desc}</h5>
                    {/* <div style={{ marginTop: "-30px" }}>
                        <Rate
                            defaultValue={5}
                            // onChange={onChange}
                            style={{ fontSize: 40 }}
                            // allowHalf
                            // allowClear={true}
                            disabled={true}
                        />
                    </div> */}
                    {/* <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <p>Available: <strong>200</strong></p>
                        </div>
                        <div>
                            <p>Sold: <strong>10</strong></p>
                        </div>

                    </div> */}
                    {/* <div>
                        <ProgressBar
                            percent={40}
                            filledBackground="#0F2B95"
                        />
                    </div> */}
                    {/* <div className='d-flex align-items-center justify-content-center'
                        style={{ marginTop: "20px" }}
                    >
                        <button className='get-cat-btn-mbl'>Get Pricing</button>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default CatCard