import React from "react";
import "./FaqAboutIndustries.css";
import FaqAboutIndustriesImg from "../../../Assets/Images/faq-about-industries.png";
import { FaqContent } from "../../../Content/Faq.content";

const FaqAboutIndustries = () => {
  return (
    <>
      <div className="container about-registration-div">
        <div className="faq-about-registration-heading-div">
        <h2 className="faq-about-registration-heading">
              {FaqContent.FAQREGISTERHEADING}
            </h2>
        </div>
        <div className="row mt-5 flex-column flex-md-row">
          
          
          <div className="col-12 col-lg-4 faq-about-registration-col-img">
            <img
              alt="FAQ About Industries"
              src={FaqAboutIndustriesImg}
              className="faq-about-industries-img"
            />
          </div>

          <div className="col-12 col-lg-8 faq-about-registration-col-que">
            
            <div className="accordion" id="accordionExampl">
              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingFifteen">
                  <button
                    className="accordion-button faq-button "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFifteen"
                    aria-expanded="true"
                    aria-controls="collapseFifteen"
                  >
                    {FaqContent.FAQREGISTERQ1}
                  </button>
                </h2>
                <div
                  id="collapseFifteen"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingFifteen"
                  data-bs-parent="#accordionExampl"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQREGISTERA1}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingSixteen">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSixteen"
                    aria-expanded="false"
                    aria-controls="collapseSixteen"
                  >
                     {FaqContent.FAQREGISTERQ2}
                  </button>
                </h2>
                <div
                  id="collapseSixteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSixteen"
                  data-bs-parent="#accordionExampl"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQREGISTERA2}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingSeventeen">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeventeen"
                    aria-expanded="false"
                    aria-controls="collapseSeventeen"
                  >
                     {FaqContent.FAQREGISTERQ3} 
                  </button>
                </h2>
                <div
                  id="collapseSeventeen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingSeventeen"
                  data-bs-parent="#accordionExampl"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQREGISTERA3}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingEighteen">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEighteen"
                    aria-expanded="false"
                    aria-controls="collapseEighteen"
                  >
                     {FaqContent.FAQREGISTERQ4}
                  </button>
                </h2>
                <div
                  id="collapseEighteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEighteen"
                  data-bs-parent="#accordionExampl"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQREGISTERA4}
                  </div>
                </div>
              </div>
              
              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingnNineteen">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNineteen"
                    aria-expanded="false"
                    aria-controls="collapseNineteen"
                  >
                     {FaqContent.FAQREGISTERQ5}
                  </button>
                </h2>
                <div
                  id="collapseNineteen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEighteen"
                  data-bs-parent="#accordionExampl"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQREGISTERA5}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqAboutIndustries;
