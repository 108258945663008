import React from 'react';
import './similarProduct.css';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import "swiper/swiper.min.css";
import { useSelector } from 'react-redux';
import StarRatings from 'react-star-ratings';
import { useNavigate } from 'react-router-dom';

const SimilarProductMobile = () => {

    const navigate = useNavigate()
    const { compareProductList } = useSelector((state) => state.allProductsList);
    const similarProducts = compareProductList && compareProductList?.similarProduct

    const FindProduct = () => {
        navigate("/view-all-product");
      };
    return (
        <>
            <div className='d-flex flex-column align-items-center justify-content-center'
                style={{ paddingTop: "40px", paddingBottom: "25px" }}>
                <h1 className='top-similar-name'>Similar Products</h1>
                <p>Recommendations for you</p>
            </div>
            <div className="container">
                <Swiper
                    // navigation={true}
                    slidesPerView={3}
                    spaceBetween={10}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        240: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                    }}
                    modules={[Autoplay, Navigation, Pagination]}
                    className="mySwiper"
                >
                    {similarProducts?.map((product, index) => (
                        <SwiperSlide>
                            <div className="mbl-view-compare-product-border py-3">
                                <div className="d-flex align-items-center justify-content-center">
                                    <img
                                        className="" src={product.image_url} alt="" style={{ width: "150px" }}
                                    />
                                </div>
                                <div className='container d-flex flex-column justify-content-start'>
                                    <h5 className='py-3'>{product.product_name}</h5>
                                    <div  className='d-flex align-items-center' >
                                        <StarRatings
                                            rating={product.avg_rating}
                                            starRatedColor="#E8AA42"
                                            numberOfStars={5}
                                            starDimension="16px"
                                            starSpacing="5px"
                                        />
                                        <span className='px-2' style={{marginTop:"7px"}}>({product.avg_rating}/5)</span>
                                    </div>
                                </div>


                            </div>
                        </SwiperSlide>))}

                    {/* <SwiperSlide>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <img className="" src={UK} alt=""></img><h5 className='py-3'>UK</h5>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <img className="" src={Aus} alt=""></img><h5 className='py-3'>Australia</h5>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <img className="" src={Sing} alt=""></img><h5 className='py-3'>Singapore</h5>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <img className="" src={India} alt=""></img><h5 className='py-3'>India</h5>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <img className="" src={Thai} alt=""></img><h5 className='py-3'>Thailand</h5>
                        </div>
                    </SwiperSlide> */}

                </Swiper>
            </div>
            <div className='d-flex align-items-center justify-content-center my-3'>
                <button className='view-more-btn-swiper' onClick={FindProduct}>view more</button>
            </div>
        </>
    )
}

export default SimilarProductMobile