const CompareProductsContent = {
  COMPAREPRODUCTSHEADER: "COMPARE PRODUCT",
  COMPAREPRODUCTDESCRIPTION:
    "Detailed comparison between software for all business types based on features, specifications, reviews and more. Explore some of our most viewed software comparisons for trending products to find out what’s best for you.",

  APTCHEADER: "ADD PRODUCTS TO COMPARE",
  PRODUCTNAME1: "Salesforce CRM",
  PRODUCTNAME2: "Odoo",
  PRODUCTNAME3: "G Suite",
  PRODUCTNAME4: "Seeq",
  BRANDNAME1: "Salesforce",
  BRANDNAME2: "Odoo",
  BRANDNAME3: "Google",
  BRANDNAME4: "Seeq",
  ADDPRODUCTBUTTON: "Add Product",
  COMPAREBUTTON: "COMPARE",

  TPCHEADER: "Trending Products Comparison",

  CEHEADER: "We can help you find the best Software",
  CEBUTTONCONTENT: "Call Expert Now",

  SPHEADER : "Similar Products",
  SPSIDEHEADING:"Recommendations for you",
  SPBUTTON: "View more",
  PRODUCTDETAILS1:"Features",
  PRODUCTDETAILS2:"Price",
  PRODUCTDETAILS3:"Specifications",


};
module.exports = {
  CompareProductsContent,
};
