import { createSlice } from "@reduxjs/toolkit";

export const myReducer = createSlice({
  name: "conqt",
  initialState: {
    singleIndustry: { industry_id: 0 },
  },
  reducers: {
    updatedSingleIndustryProductsData: (state, action) => {
      // console.log("*****in the store of SingleIndustry", action);
      // console.log(
      //   action.payload,
      //   "action.payload in the reducer of SingleIndustry"
      // );
      state.singleIndustry = action.payload;
    },
  },
  extraReducers: {},
});
export const { updatedSingleIndustryProductsData } = myReducer.actions;

export default myReducer.reducer;
