import React from 'react';
import './compareFeatures.css';
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const CompareFeatures = ({pName, content}) => {
    return (
        <>
            <section className='mbl-view-compare-product-border py-3 my-3' >
                <div className='container'>
                    <div className='price-top-name'>
                        <p>{pName}</p>
                    </div>
                    <div className='pt-3'>
                        {parse(content)}
                    </div>
                </div>
            </section>
        </>
    )
}

export default CompareFeatures