import React, { useState, useEffect } from "react";
import "./categoryCard.css";
import Rate from "rc-rate";
import axios from "axios";
import HeadSet from "../../../Assets/Svg/getcallbtn.svg";
import PercentIcon from "../../../Assets/Svg/percent.svg";
import Compare from "../../../Assets/Svg/compare.svg";
import { Key } from "@mui/icons-material";
import ProductEnquiryPopup from "../../../Models/ProductEnquiryPopup/ProductEnquiryPopup";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import {
  PRODUCTS_CATEGORY_DETAILS,
  PRODUCT_DETAILS,
} from "../../../ApiEndPoints";
import SingleProductPageReducer, {
  updatedSingleProductsData,
} from "../../../Store/Reducer/SingleProductPageReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Rupee from "../../../Assets/Svg/rupee.svg";
import FadeLoader from "react-spinners/FadeLoader";
import ReactModal from "react-modal";
import HomePagePopup from "../../HomePage/Popup/HomePagePopup";
import { getRandomRating } from "../../../Utlis/Utils";
import { toast } from "react-toastify";
import CustomPagination from "../../CustomPagination/CustomPagination";

const CategoryCard = (props) => {
  const [data, setData] = useState([]);
  const [productId, setProductId] = useState(0);
  const [sortArry, setSortArry] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const { singleCategory } = useSelector(
    (state) => state.singleCategoryDetails
  );
  const [popup, setPopup] = useState(false);

  const onClickCallNow = (e) => {
    e.stopPropagation();
    setPopup(!popup);
    //console.log("popup clicked");
  };

  const onRequestClose = (close) => {
    setIsOpen(close);
  };

  const { id, name } = useParams();
  useEffect(() => {
    setLoader(true);
    //console.log(id, name, "Category Details");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      category_name: name.split("-").join(" "),
      category_id: +id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${PRODUCTS_CATEGORY_DETAILS}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result.data, "Category Data");
        window.scrollTo(0, 0);
        if (result.data.length) {
          setLoader(false);
          setData(result.data);
          setSortArry(result.data);
        } else {
          setLoader(false);
          setData([]);
          setSortArry([]);
        }
      })
      .catch((error) => console.log("error", error));
  }, [id]);

  useEffect(() => {
    const filteredData =
      data &&
      data.filter(
        (e) =>
          e.product_name &&
          e.product_name
            .toLowerCase()
            .match(props.searchText && props.searchText.toLowerCase())
      );
    setSortArry(filteredData);
  }, [props.searchText]);

  useEffect(() => {
    const sortedData = [...data].sort((a, b) => {
      if (props.sortOrder === "asc") {
        return a.product_name.localeCompare(b.product_name);
      } else {
        return b.product_name.localeCompare(a.product_name);
      }
    });
    setSortArry(sortedData);
  }, [props.sortOrder]);

  console.log(data, "category data");

  // Product Single Card Page Link
  const onClickGetProduct = (e, pid) => {
    // console.log(pid, "onclickGetProduct");
    axios
      .get(`${BaseUrl}${PRODUCT_DETAILS}${pid}`)
      .then((response) => {
        //console.log(response.data.data, "single products api data");

        dispatch(updatedSingleProductsData(response.data.data));
        navigate(
          "/" + response.data.data.productDetail.product_name.replace(/ /g, "-")
        );
        window.scrollTo(0, 0);
        // setData(response.data.data);
      })
      .catch((error) => console.log(error));

    //console.log("Category Card", data);
  };
  const onClickReview = (e, items) => {
    // e.preventDefault()
    // console.log("onClickReview", items);
    const loginType = localStorage.getItem("logintype");
    if (loginType == 1) {
      navigate("/write-a-review");
      localStorage.setItem("productName", items.product_name);
      localStorage.setItem("productId", items.id);
    } else if (loginType == 2) {
      toast.error("Only registered customers can give feedback");
    } else {
      navigate("/login");
    }
  };

  // pagination handling by number of shown items as well
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const newItemsPerPage =
      props.numOfShow > data.length ? data.length : props.numOfShow;
    setItemsPerPage(newItemsPerPage);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    data.length > 5
      ? sortArry.slice(indexOfFirstItem, indexOfLastItem)
      : sortArry.slice(0, props.numOfShow);

  // console.log(currentItems, "current");
  // console.log(sortArry, "sortArry");

  // console.log(props.numOfShow);

  // pagination length setting by numofshow
  useEffect(() => {
    setItemsPerPage(props.numOfShow);
  }, [props.numOfShow]);

  return (
    <>
      <center>{loader && <FadeLoader color="#36d7b7" />}</center>
      {/* {sortArry.length > 0 ? ( */}
      {currentItems.map((items, index) => {
        const array = [4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9];
        const fakeRate = getRandomRating(array);
        // console.log(items, "items");
        return (
          <>
            <section className="cat-card">
              <div className="container">
                <div className="row card-body">
                  <div className="col-12 col-lg-4 category-image">
                    <img
                      src={items.image_url}
                      alt="conqt product"
                      className="img-single-cat-res"
                      onClick={(e) => {
                        // console.log(e, items.id, "e in category");
                        onClickGetProduct(e, items.id);
                      }}
                    />
                  </div>

                  <div className="col-12 col-lg-8 product-card-mobile">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h1
                          className="cat-card-header"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            onClickGetProduct(e, items.id);
                          }}
                        >
                          {items.product_name}
                        </h1>
                        <div
                          className="d-flex align-items-center "
                          style={{ marginTop: "-10px" }}
                        >
                          <div style={{ marginTop: "-4px" }}>
                            <Rate
                              // defaultValue={items.avg_rating}
                              value={
                                items.avg_rating > 0
                                  ? items.avg_rating
                                  : fakeRate
                              }
                              // onChange={onChange}
                              style={{ fontSize: 15 }}
                              allowHalf
                              allowClear={true}
                              disabled={true}
                            />
                          </div>
                          <div className="rate-value px-1">
                            (
                            {items.avg_rating > 0 ? items.avg_rating : fakeRate}
                            )
                          </div>
                          <div className="pt-3">
                            <p
                              className="wr-cat px-1"
                              onClick={(e) => {
                                onClickReview(e, items);
                              }}
                            >
                              Write a Review
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <img
                          onClick={() => setIsOpen(true)}
                          src={HeadSet}
                          alt=""
                          style={{
                            width: "130px",
                            cursor: "pointer",
                            marginTop: "-20px",
                          }}
                        />
                        <ReactModal
                          isOpen={isOpen}
                          contentLabel="Example Modal"
                          onRequestClose={() => setIsOpen(false)}
                        >
                          <HomePagePopup close={onRequestClose} />
                        </ReactModal>
                      </div>
                    </div>

                    <div>
                      {items.is_price == "Price" ? (
                        <>
                          <div className="d-flex">
                            <span
                              style={{ color: "#FF7A00" }}
                              className="mny-month"
                            >
                              {items.discount !== null ? (
                                <>{items.discount} %</>
                              ) : (
                                ""
                              )}
                            </span>
                            <span className="px-2 mny-month">
                              {/* <img src={items.price_currency} alt="" className="px-1" /> */}
                              {items.price_currency} {items.price}
                            </span>
                          </div>
                          <div style={{ color: "#33A179" }}>
                            Inclusive of all taxes
                          </div>
                        </>
                      ) : items.is_price == "Package" ? (
                        <div style={{ color: "#33A179" }}>
                          Check the Packages
                        </div>
                      ) : (
                        <div style={{ color: "#33A179" }}>Request on Quote</div>
                      )}
                    </div>
                    <div className="pt-3">
                      <p>
                        {parse(
                          items.product_description
                            .substring(0, 100)
                            .replace(/^(.{70}[^\s]*).*/, "$1")
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <hr />
              </div>
              <div className="container d-flex align-items-center justify-content-end">
                {/* <div className="d-flex align-items-center">
                                                                        <img src={Compare} alt="" />
                                                                        <h3 style={{ fontWeight: 500 }} className="compare">
                                                                                Compare Product
                                                                        </h3>
                                                                </div> */}
                <div>
                  <button className="get-cat-btn" onClick={onClickCallNow}>
                    Get Pricing
                  </button>
                </div>
              </div>
            </section>
          </>
        );
      })}

      <hr style={{ height: "1px", color: "#c6c6c6" }}></hr>
      {data.length > 5 && (
        <CustomPagination
          totalItems={data.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
      {popup && <ProductEnquiryPopup closeModal={setPopup} />}
    </>
  );
};

export default CategoryCard;
