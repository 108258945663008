const FaqContent = {
  FAQHEADING: "The FAQs",
  FAQMAINHEADING: "Help Center",
  FAQHEROBANNERTAGLINE: "Everything you need to know about using our portal. Find the answers you’re looking for..",

  FAQCUSTOMERHEADING: "Customer Queries",
  FAQCUSTOMERQ1: "How can I publish my requirement on ConQt?",
  FAQCUSTOMERQ2: "How long can it take to connect to the right people?",
  FAQCUSTOMERQ3: "How will I be notified if a vendor responds to my listing?",
  FAQCUSTOMERA1: "Click the “Find Vendor” button or “Sign up” button and register as a customer. When you are ready to publish your requirements, we recommend you enlist them with as many details as possible. The portal also aids you by providing an easy template where you can mention details such as scope of work, budget, timeline, location etc. A clearer picture attracts better vendor responses.",
  FAQCUSTOMERA2: "Usually, 3-5 days, given that you as a customer have added your project needs accurately. The key is to enlist requirements with as many details as possible. And don’t forget to update your information if there is a change in what needs to be done.",
  FAQCUSTOMERA3: "When a vendor responds, you will be notified by email. The submitted proposal shall also be available to view on your dashboard. Alternatively, with your permission, vendors can also establish direct contact with you.",
  

  FAQOVERVIEWHEADING: "Overview",
  OVERVIEWFAQQ: "What does the ConQt platform actually do ?",
  OVERVIEWFAQA: "A. Our unique marketplace connects buyers and sellers for Information technology requirements. This AI driven business matching portal, matches customers' listed projects with relevant vendors based on their selected category preferences. Customers can then liaise with vendors directly.",
  OVERVIEWFAQQ1: "How to apply to Projects as Vendor ? How long does it take to connect to the right people?",
  OVERVIEWFAQA1: "A. Getting the project executed can take time. However, you could get the right resources faster. The key is to list requirement with as much detail as possible. And, don’t forget to update your information if there is a change in what needs to be done. This speeds up the process a lot.",
  OVERVIEWFAQQ2: "What does the ConQt platform actually do ?",
  OVERVIEWFAQA2: "A. Our unique marketplace connects buyers and sellers for Information technology requirements. This AI driven business matching portal, matches customers' listed projects with relevant vendors based on their selected category preferences. Customers can then liaise with vendors directly.",
  OVERVIEWFAQQ3: "How to apply for Projects as a Vendor?",
  OVERVIEWFAQA3: "A. ConQt has a separate portal for vendors to get register. Upon registration, vendors get an option to choose in which categories lies their expertise. Once registered, vendors can see projects from the categories selected and can apply for projects instantly.",
  OVERVIEWFAQQ4: "Are there any fees involved to get registered?",
  OVERVIEWFAQA4: "A. Buyers of services can list their requirements absolutely free. However, if they want to use our services, there is also a subscription module which they can opt for. vendors have to first register by paying a nominal fee of 20 USD (converted into your local currency). Once registered, each Vendors can apply for 3 projects for free. After this subscription Module. See our pricing page for more information on listing fees and subscription.",
  OVERVIEWFAQQ5: "How can i publish my requirement on ConQt?",
  OVERVIEWFAQA5: "A. When you’re ready to list, we recommend listing down your requirements with as many details as possible. You will be asked to mention details like Scope of Work, Timelines for completion and expected price range for the project (Wont be shown to the vendors) and any other information relevant to your business type. With all these details handy, you can now create a Listing. To get started, simply select ‘Create Listing’ from our Homepage or navigate here.",
  OVERVIEWFAQQ6: "How will I be notified if a Vendor responds to my Listing?",
  OVERVIEWFAQA6: "A. As soon as a listing is created, all the Vendors operating in the space in which the listing is created get notified over email. The listing also appears in the Announcements space to all the Vendors. The vendors may choose to apply to the listing which will be then notified to you. This way you can see who all have applied for your requirement and compare and choose the best service provider.",
  OVERVIEWFAQQ7: "How will I be notified if there is a new listing in the space where I operate?",
  OVERVIEWFAQA7: "A. As soon as a listing is created in the space in which a Vendor operates, there is a notification sent to you over email and the listing also appears in the Announcements space. You may choose to respond to the listing by opting to apply for it.",


  FAQVENDORQUERIESHEADING: "Vendor Queries",
  FAQVENDORQUERIESQ1: "If the same client puts up multiple requirements will it be mentioned anywhere?",
  FAQVENDORQUERIESA1: "A. If the point of contact is varied (eg two differernt departments in the same organisation or two separate locations ), then the project will be posted separately.",
  FAQVENDORQUERIESQ2: "Is there a CAP on how many vendors can apply for a particular project?",
  FAQVENDORQUERIESA2: "A. Only if a customer mentions he wants a cap, then there will be. Otherwise it will remain open.",
  FAQVENDORQUERIESQ3: "Do the projects mention date of publishing?",
  FAQVENDORQUERIESA3: "A. Yes.",
  FAQVENDORQUERIESQ4: "If any vendor has won the project, will be there be an indication?",
  FAQVENDORQUERIESA4: "A. Level of Contact: Decision Maker or C Level",
  FAQVENDORQUERIESQ5:"What is the hierarchical level of the customer contact point provided by ConQT?",
  FAQVENDORQUERIESQ6:"Is the contact 100% verified for vendors to discuss and send the proposal?",
  FAQVENDORQUERIESQ7:"Once the contact information is received, do we directly contact the end client or is there another step to follow?",
  FAQVENDORQUERIESQ8:"How many partners do you have in Thailand market and what is the percentage between large Enterprise, SME and startup?",
  FAQVENDORQUERIESQ9:"What is the average percentage of winning a deal on your platform?",
  FAQVENDORQUERIESQ10:"Will you provide assistance in pursuing opportunities with the clients after we purchase their details from you?",
  FAQVENDORQUERIESQ11:"What is the commission rate if we successfully come to an agreement with the client?",
  FAQVENDORQUERIESQ12:"How do you ensure these potential customers do have the demand?",
  FAQVENDORQUERIESQ13:"What is your average conversion rate? How many of your potential leads does it take to turn into an actual deal?",
  FAQVENDORQUERIESQ14:"What is ConQT - Enterprise package?",
  FAQVENDORQUERIESQ15:"Where are our clients from or Regions we focus on?",
  FAQVENDORQUERIESQ16:"What is avg rate of incoming projects per month?",
  FAQVENDORQUERIESQ17:"Which Industries do we focus on?",
  FAQVENDORQUERIESQ18:"How do I apply for Projects as a Vendor?",
  FAQVENDORQUERIESQ19:"How will I get notified if there is a new listing in the space that I operate?",

  FAQVENDORQUERIESA5:"A. Level of Contact: Decision Maker or C Level.",
  FAQVENDORQUERIESA6:"A. All customers and contacts are verified with a 100% accuracy rate.",
  FAQVENDORQUERIESA7:"A. Once you receive the contact information, you may directly reach out to the customer and ensure that you draft a well-written email introducing yourself and your company. It is imperative to avoid poorly written emails as they may elicit negative responses from the recipient. Do mention you have received the project through our platform.",
  FAQVENDORQUERIESA8:"A. We have 4,000 customers in our database as of today and it is updating on daily basis. We are working with companies like PTT, SCG, Bangchak, Royal Thai Navy, etc. Here is the ratio: Public Listed: 70% SME: 30%.",
  FAQVENDORQUERIESA9:"A. The success of closing a project is entirely reliant on the vendor's sales and technical teams. If the customer feels at ease with your team and finds them agreeable, then closing the project is almost certain. Our primary responsibility is to match you with the appropriate opportunity and decision maker.",
  FAQVENDORQUERIESA10:"A. We strongly encourage you to initiate contact with potential customers and demonstrate your competencies and successfully completed projects. Frequently, service providers attempt to arrange meetings or send unsolicited emails to potential customers who may not be interested in their offerings, resulting in a negative impression and reluctance to engage. To alleviate this issue, if you require further assistance in closing deals, we suggest purchasing our enterprise package, which includes allocation of a dedicated resource to support you in closing opportunities amongst other additional services provided.",
  FAQVENDORQUERIESA11:"A. We do not levy any commission fees. Our objective is to facilitate connections between you and potential customers. Furthermore, you may also avail of our customer database, which includes SMEs and publicly listed companies, to establish connections with potential customers.",
  FAQVENDORQUERIESA12:"A. All the opportunities are verified by our team before they are posted on the platform.",
  FAQVENDORQUERIESA13:"A. Converting the opportunity is completely in the hands of the vendor and based on your credibility, background, your presentation to the customer, quotation, payment terms and conditions and previous projects executed in the same domain. All these mean it would be easy to win the project.",
  FAQVENDORQUERIESA14:"A. A. This package is available for $5000 USD. It Includes: 1. Become our exclusive partner. 2. Opportunities will be shared with you first before any other vendor. 3. Access to exclusive opportunities. 5. Support from our team to help close opportunities. Connect with our team for more details.",
  FAQVENDORQUERIESA15:"Our major clientele is from Thailand & India. We have also executed projects for clients in Singapore, USA and UK, Phillipines, Malaysia etc. We are looking to expand in the middle east regions and Australia.",
  FAQVENDORQUERIESA16:"A. We have an average of 30 leads per month.",
  FAQVENDORQUERIESA17:"A. We started with Petrochem, Oil and Gas and Manufacturing industries. We have now ventured into healthcare sector, Chemical industries and universities. Government bodies such as Royal Thai Navy, Royal Thai airforce are our repeated customers.",
  FAQVENDORQUERIESA18: "A. ConQt has a separate portal for vendors to “sign up as a partner”. Upon registration, vendors get an option to choose categories in which their expertise lie. You can then filter projects based on the category of your choice and apply instantly.",
  FAQVENDORQUERIESA19: "A. As soon as a listing is created in the space in which a Vendor operates, there is a notification sent to you over email and the listing also appears in the Announcements space. You may choose to respond to the listing by opting to 'contact customer' on the listing.",
  

  FAQREGISTERHEADING: "Registrations",
  FAQREGISTERQ1: "Can I use my personal email address for registration?",
  FAQREGISTERA1: "A. Although, you can use personal email address, It is preferred you use your official company email address for registration. Ensure your address is active since you will receive an OTP during the registration process.",
  FAQREGISTERQ2: "Why is my password getting rejected?",
  FAQREGISTERA2: "A. The password should be minimum 8 and maximum 10 characters only. It must conatin atleast 1 uppercase, 1 lowercase, 1 number & 1 special character (such as ! , $, # etc).",
  FAQREGISTERQ3: "How do I re-check my password after typing?",
  FAQREGISTERA3: "A. Click on 'Show password' to ensure you have typed an appropriate password.",
  FAQREGISTERQ4: "What do I do if I get an “Insufficient Data Error”?",
  FAQREGISTERA4: "A. Ensure you have ticked/checked both the boxes for terms and conditions before clicking 'submit' on the registration page. Also, refrain from clicking on any tab in multiple immediate successive attempts. Revert back to our team/ use the chat-box for help if the problem persists.",
  FAQREGISTERQ5:"Should I sign up as a customer or a partner?",
  FAQREGISTERA5:"A. If you are a service provider/vendor, please register yourself as our 'partner' or click on “Find Customer”. If you intend to buy services through the portal, please register as 'customer' or click on “Find Vendor”.",
  FAQREGISTERQ6: "Are there any fees involved to get registered?",
  FAQREGISTERA6: "A. No. Registering on our portal is free.",
  

}

module.exports = {
  FaqContent
}