const appScreen = {
    HEADER: "HELLO WORLD TESTING",
    BOTTOMCONTENT: "©2023 ConQt All Rights Reserved. Designed by Tech Curve AI & Innovations Co., Ltd.",
    FAQ: "Frequently Asked Questions.",
    FAQTOP: "Posting a requirement on ConQt?",
    FAQPARA: "With new requirements getting posted on ConQt every hour, now might be the right time to list your requirements too. Its fast, easy and free.",
    FAQ1: "How long does it take to connect to the right people? ",
    FAQ2: "What does the ConQt platform actually do ?",
    FAQ3: "How do I apply for projects as a vendor?",
    FAQ4: "Are there any fees involved to get registered?",
    FAQ5: "How do I publish my project on ConQt?",
    FAQ6: "How will I be notified of a vendor response to my listing?",
    FAQ7: "How will I be notified if there is a new listing in the space where I operate?",
    REVIEW: ""

}
module.exports = {
    appScreen
}