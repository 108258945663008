import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { useSelector } from "react-redux";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { useDispatch } from 'react-redux';
import homeicon from "../../../Assets/Images/home-cp.png";
import SalesforceImage from "../../../Assets/Images/salesforceimg.png";
import LeadSquaredImage from "../../../Assets/Images/LeadSquared.png";
import DeskeraImage from "../../../Assets/Images/deskera.png";
import PriceImage from "../../../Assets/Images/walleticon.png";
import IndustriesImage from "../../../Assets/Images/industriesVector.png";
import DeapartmentImage from "../../../Assets/Images/departmentvector.png";
import FeaturesImage from "../../../Assets/Images/featuresvector.png";
import ReviewsImage from "../../../Assets/Images/ReviewsVector.png";
import ProductImagesVec from "../../../Assets/Images/productImages-vector.png";
import ProductVideoVec from "../../../Assets/Images/product-video-Vector.png";
import PlatformsSuppVec from "../../../Assets/Images/Platforms-supp-Vector.png";
import RightVector from "../../../Assets/Images/right-Vector.png";
import WrongVector from "../../../Assets/Images/WrongVector.png";
import "./ComparisonBetweenProductsAccordion.css";
import { ComparisonBetweenProductsContent } from "../../../Content/ComparisonBetweenProducts.content";
// import Youtube from "../../../Components/SingleProduct";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Ytube from "../../CompareProducts/yTube";
import StarRatings from 'react-star-ratings';
import { useNavigate } from "react-router-dom";
import ProductEnquiryPopup from "../../../Models/ProductEnquiryPopup/ProductEnquiryPopup";
import { updatedCurrentSelectedProduct1, updatedCurrentSelectedProduct2, updatedCurrentSelectedProduct3 } from "../../../Store/Reducer/AllProductsReducer";
import { toast } from "react-toastify";


const ProductDetails = ({ image, pName, pBrandName }) => {

  return (
    <>
      <div className="three-pc-table-product-image-div">
        <img src={image} alt="" className="Three-pc-table-product-image" />
      </div>
      <div className="three-pc-table-product-names">
        <h4 className="three-pc-table-product-pname">{pName}</h4>
        <h4 className="three-pc-table-product-bname">{pBrandName}</h4>
      </div>
    </>
  );
};

const CustomAccordionHeaderProductComparison = ({ icon, paraname }) => {

  return (
    <>
      <div className="three-pc-para-div">
        <div>
          <img src={icon} alt="" className="three-pc-parameter-icon" />
          <span className="three-pc-paraname">{paraname}</span>
        </div>
      </div>
    </>
  );
};

const ComparisonBetweenProductsAccordion = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [popup, setPopup] = useState(false);
  const { compareProductList } = useSelector((state) => state.allProductsList);
  console.log("compareProductList", compareProductList);

  const onClickCallNow = () => {
    setPopup(!popup);
    // console.log("popup clicked");
  };

  const onClickWriteReview = () => {
    const loginType = localStorage.getItem("logintype");
    if (loginType == 1) {
      localStorage.removeItem('productName');
      localStorage.removeItem('productId');
      navigate("/write-a-review");
    } else if (loginType == 2) {
      toast.error("Only registered customers can give feedback");
    } else {
      navigate("/login");
    }
  };

  const product1 = compareProductList && compareProductList?.compareProduct[0]
  const product2 = compareProductList && compareProductList?.compareProduct[1]
  const product3 = compareProductList && compareProductList?.compareProduct[2]
  console.log("product1", product1)

  // const YoutubeId1 = SingleProduct.productDetail.video_link
  //   ? SingleProduct.productDetail.video_link.split("https://youtu.be/")[1]
  //   : "";

  const proFeature1 = parse(product1 && product1.features ? product1.features.substring(0, 1100).replace(/^(.{1090}[^\s]*).*/, "$1") : "")
  const proFeature2 = parse(product2 && product2.features ? product2.features.substring(0, 1100).replace(/^(.{1090}[^\s]*).*/, "$1") : "")
  const proFeature3 = parse(product3 && product3.features ? product3.features.substring(0, 1100).replace(/^(.{1090}[^\s]*).*/, "$1") : "")

  const YoutubeId1 = product1 && product1?.video_link ? product1?.video_link.split("https://youtu.be/")[1] : "";
  const YoutubeId2 = product2 && product2?.video_link ? product2?.video_link.split("https://youtu.be/")[1] : "";
  const YoutubeId3 = product3 && product3?.video_link ? product3?.video_link.split("https://youtu.be/")[1] : "";




  const handleClickCompareProduct =() => {
    navigate("/compare-products")
    dispatch(updatedCurrentSelectedProduct1(null))
    dispatch(updatedCurrentSelectedProduct2(null))
    dispatch(updatedCurrentSelectedProduct3(null))
  }
  return (
    <>
      {popup && <ProductEnquiryPopup closeModal={setPopup} />}
      <div className="three-pc-comparison-div">
        <Breadcrumb onClick={handleClickCompareProduct}>
          <Breadcrumb.Item href="#">
            <img className="homeicon" src={homeicon} />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#">Compare Product</Breadcrumb.Item>
        </Breadcrumb>
        <div className="tpc-header">
          <h1 className="tpc-heading">
            {ComparisonBetweenProductsContent.COMPAREPRODUCTSHEADER}
          </h1>
          <div className="tpc-line"></div>
          <p className="tpc-description">
            {ComparisonBetweenProductsContent.COMPAREPRODUCTDESCRIPTION}
          </p>
        </div>
        {/* {JSON.stringify(compareProductList)} */}
        <div className="three-pc-table container">
          <div className="three-pc-table-pdetails">
            <div className="three-pc-table-pdetails1 col-lg-3">
              <h3 className="three-pc-table-pdetails1text">
                {ComparisonBetweenProductsContent.THREEPCTAC1}
              </h3>
            </div>
            <div className="three-pc-table-pdetails2 col-lg-3">
              <div className="three-pc-table-product-div">
                <ProductDetails
                  image={product1 && product1?.brand_logo_url}
                  pName={product1 && product1?.product_name}
                  pBrandName={product1 && product1?.brand_name}
                />
              </div>
            </div>
            <div className="three-pc-table-pdetails2 col-lg-3">
              <div className="three-pc-table-product-div">
                <ProductDetails
                  image={product2 && product2?.brand_logo_url}
                  pName={product2 && product2?.product_name}
                  pBrandName={product2 && product2?.brand_name}
                />
              </div>
            </div>

            <div className="three-pc-table-pdetails3 col-lg-3">
              <div className="three-pc-table-product-div">
                <ProductDetails
                  image={product3 && product3?.brand_logo_url}
                  pName={product3 && product3?.product_name}
                  pBrandName={product3 && product3?.brand_name}
                />
              </div>
            </div>
          </div>
          <div className="">
            <Accordion allowMultiple>
              <AccordionItem
                header={
                  <CustomAccordionHeaderProductComparison
                    icon={PriceImage}
                    paraname={ComparisonBetweenProductsContent.THREEPCPARA1}
                  />
                }
              >
                <div className="three-pc-table-pdetails">
                  <div className="three-pc-table-pdetails1 col-lg-3">
                    <h3 className="three-pc-table-pdetails1text">Price</h3>
                  </div>
                  <div className="three-pc-table-pdetails2 col-lg-3">
                    <h3 className="">
                      {product1 && product1?.price ? (product1?.price) : (product1?.price == null) ? (
                        <div>
                          <p>Price on Request</p>
                          <button className="price-on-req-btn" onClick={onClickCallNow}>Request</button>
                        </div>
                      ) : ""}

                    </h3>

                  </div>
                  <div className="three-pc-table-pdetails2 col-lg-3">
                    <h3 className="">
                      {product2 && product2?.price ? (product2?.price) : (product2?.price == null) ? (
                        <div>
                          <p>Price on Request</p>
                          <button className="price-on-req-btn" onClick={onClickCallNow}>Request</button>
                        </div>
                      ) : ""}
                    </h3>
                    <h3 className="three-pc-table-pdetails1-price">

                    </h3>

                  </div>

                  <div className="three-pc-table-pdetails3 col-lg-3">
                    <h3 className="">
                      {/* {JSON.stringify(product3)} */}
                      {product3 && (product3?.price ? (product3?.price) : (product3?.price == null) ? (
                        <div>
                          <p>Price on Request</p>
                          <button className="price-on-req-btn" onClick={onClickCallNow}>Request</button>
                        </div>
                      ) : "")}
                    </h3>
                  </div>
                </div>
              </AccordionItem>

              {/* Industry */}

              <AccordionItem
                header={
                  <CustomAccordionHeaderProductComparison
                    icon={IndustriesImage}
                    paraname={ComparisonBetweenProductsContent.THREEPCPARA2}
                  />
                }
              >
                <div className="three-pc-table-pdetails">
                  <div className="dep-1 col-lg-3">
                    <h3 className="three-pc-table-pdetails1text">Industries</h3>
                  </div>
                  <div className="dep-2 col-lg-3">
                    {product1 && product1?.industry_name ? product1?.industry_name : ""}
                  </div>

                  <div className="dep-3 col-lg-3">
                    {product2 && product2?.industry_name ? product2?.industry_name : ""}
                  </div>

                  <div className="dep-4 col-lg-3">
                    {product3 && product3?.industry_name ? product3?.industry_name : ""}
                  </div>
                </div>
              </AccordionItem>

              {/* Department */}

              <AccordionItem
                header={
                  <CustomAccordionHeaderProductComparison
                    icon={DeapartmentImage}
                    paraname={ComparisonBetweenProductsContent.THREEPCPARA3}
                  />
                }
              >
                <div className="three-pc-table-pdetails">
                  <div className=" dep-1 col-lg-3">
                    <h3 className="three-pc-table-pdetails1text">Department</h3>
                  </div>
                  <div className=" dep-2 col-lg-3">
                    {product1 && product1?.department_name ? product1?.department_name : ""}
                  </div>


                  <div className="dep-3 col-lg-3">
                    {product2 && product2?.department_name ? product2?.department_name : ""}
                  </div>

                  <div className=" dep-4 col-lg-3">
                    {product3 && product3?.department_name ? product3?.department_name : ""}
                  </div>
                </div>
              </AccordionItem>

              {/* features */}

              <AccordionItem
                header={
                  <CustomAccordionHeaderProductComparison
                    icon={FeaturesImage}
                    paraname={ComparisonBetweenProductsContent.THREEPCPARA4}
                  />
                }
              >
                <div className="three-pc-table-pdetails">
                  <div className=" fea-1 col-lg-3">
                    <h3 className="three-pc-table-pdetails1text">Features</h3>
                  </div>
                  <div className=" fea-2 col-lg-3">
                    {proFeature1}
                  </div>


                  <div className=" fea-3 col-lg-3">
                    <div className=" ">
                      {proFeature2}
                    </div>
                  </div>

                  <div className="fea-4 col-lg-3">
                    {proFeature3}
                  </div>
                </div>
              </AccordionItem>
              <AccordionItem
                header={
                  <CustomAccordionHeaderProductComparison
                    icon={ReviewsImage}
                    paraname={ComparisonBetweenProductsContent.THREEPCPARA5}
                  />
                }
              >
                <div className="three-pc-table-pdetails">
                  <div className="three-pc-table-pdetails1 col-lg-3">
                    <h3 className="three-pc-table-pdetails1text">Avg Rating</h3>
                  </div>

                  <div className="three-pc-table-pdetails2 col-lg-3">
                    {product1 && product1?.avg_rating > 0 ? (
                      <StarRatings
                        rating={product1 && product1?.avg_rating}
                        starRatedColor="#E8AA42"
                        numberOfStars={5}
                        starDimension="16px"
                        starSpacing="5px"
                      />
                    ) : product1?.avg_rating == 0 ? (
                      <div>
                        <button className="wr-review-cp-btn" onClick={onClickWriteReview}>write a review</button>
                      </div>
                    ) : ""}
                  </div>

                  <div className="three-pc-table-pdetails2 col-lg-3">
                    {product2 && product2?.avg_rating > 0 ? (
                      <StarRatings
                        rating={product2 && product2?.avg_rating}
                        starRatedColor="#E8AA42"
                        numberOfStars={5}
                        starDimension="16px"
                        starSpacing="5px"
                      />
                    ) : product2?.avg_rating == 0 ? (
                      <div>
                        <button className="wr-review-cp-btn" onClick={onClickWriteReview}>write a review</button>
                      </div>
                    ) : ""}
                  </div>

                  <div className="three-pc-table-pdetails3 col-lg-3">
                    {product3 && product3?.avg_rating > 0 ? (
                      <StarRatings
                        rating={product3 && product3?.avg_rating}
                        starRatedColor="#E8AA42"
                        numberOfStars={5}
                        starDimension="16px"
                        starSpacing="5px"
                      />
                    ) : product3?.avg_rating == 0 ? (
                      <div>
                        <button className="wr-review-cp-btn" onClick={onClickWriteReview}>write a review</button>
                      </div>
                    ) : ""}

                  </div>
                </div>
              </AccordionItem>
              <AccordionItem
                header={
                  <CustomAccordionHeaderProductComparison
                    icon={ProductImagesVec}
                    paraname={ComparisonBetweenProductsContent.THREEPCPARA6}
                  />
                }
              >
                <div className="three-pc-table-pdetails">
                  <div className="three-pc-table-pdetails1 col-lg-3">
                    <h3 className="three-pc-table-pdetails1text">Product Images</h3>
                  </div>
                  <div className="three-pc-table-pdetails2 col-lg-3">
                    <h3 className="three-pc-table-pdetails1text">
                      <img src={product1 && product1?.image_url} alt="" style={{ width: "150px" }} />
                    </h3>
                  </div>
                  <div className="three-pc-table-pdetails2 col-lg-3">
                    <h3 className="three-pc-table-pdetails1text">
                      <img src={product2 && product2?.image_url} alt="" style={{ width: "150px" }} />
                    </h3>
                  </div>
                  <div className="three-pc-table-pdetails3 col-lg-3">
                    <h3 className="three-pc-table-pdetails1text">
                      <img src={product3 && product3?.image_url} alt="" style={{ width: "150px" }} />
                    </h3>
                  </div>
                </div>
              </AccordionItem>
              <AccordionItem
                header={
                  <CustomAccordionHeaderProductComparison
                    icon={ProductVideoVec}
                    paraname={ComparisonBetweenProductsContent.THREEPCPARA7}
                  />
                }
              >
                <div className="three-pc-table-pdetails">
                  <div className="three-pc-table-pdetails1 col-lg-3">
                    <h3 className="three-pc-table-pdetails1text">Product Video</h3>
                  </div>
                  <div className="three-pc-table-pdetails2 col-lg-3">
                    <Ytube VideoID={YoutubeId1} />
                  </div>
                  <div className="three-pc-table-pdetails2 col-lg-3">
                    <Ytube VideoID={YoutubeId2} />
                  </div>
                  {product3 && product3?.video_link ? (
                    <div className="three-pc-table-pdetails3 col-lg-3">
                      <Ytube VideoID={YoutubeId3} />
                    </div>) : (<div className="three-pc-table-pdetails3 col-lg-3">
                      { }
                    </div>)};
                </div>
              </AccordionItem>
              {/* <AccordionItem
              header={
                <CustomAccordionHeaderProductComparison
                  icon={PlatformsSuppVec}
                  paraname={ComparisonBetweenProductsContent.THREEPCPARA8}
                />
              }
            >
              <div className="three-pc-table-pdetails">
                <div className="three-pc-table-pdetails1">
                  <h3 className="three-pc-table-pdetails1text">{ }</h3>
                </div>
                <div className="three-pc-table-pdetails2">
                  <h3 className="three-pc-table-pdetails1text">{ }</h3>
                </div>
                <div className="three-pc-table-pdetails2">
                  <h3 className="three-pc-table-pdetails1text">{ }</h3>
                </div>
                <div className="three-pc-table-pdetails3">
                  <h3 className="three-pc-table-pdetails1text">{ }</h3>
                </div>
              </div>
            </AccordionItem> */}
            </Accordion>
          </div>
        </div>
      </div>

    </>

  );
};

export default ComparisonBetweenProductsAccordion;
