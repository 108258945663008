import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import "./ProductReviews.css";
import { useSelector } from "react-redux";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { calculatePercentage } from "../../../Utlis/Utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ProductReviews = () => {
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);
  const navigate = useNavigate();

  const productReviews =
    SingleProduct.productReviews.length > 0 && SingleProduct.productReviews;
  const productDetails =
    SingleProduct.productDetail && SingleProduct.productDetail;
  console.log(productReviews, "product reviews");
  console.log(productDetails, "product details");
  const overallPercent = calculatePercentage(productDetails.avg_rating, 5);
  const featuresPercent = calculatePercentage(
    productDetails.avg_feature_rate,
    5
  );
  const moneyPercent = calculatePercentage(productDetails.avg_money_rate, 5);
  const usePercent = calculatePercentage(productDetails.avg_use_rate, 5);
  const supportPercent = calculatePercentage(
    productDetails.avg_support_rate,
    5
  );
  const onClickReview = () => {
    const loginType = localStorage.getItem("logintype");
    if (loginType == 1) {
      navigate("/write-a-review");
      localStorage.setItem("productName", productDetails.product_name);
      localStorage.setItem("productId", productDetails.product_id);
    } else if (loginType == 2) {
      toast.error("Only registered customers can give feedback");
    } else {
      navigate("/login");
    }
  };

  return (
    <>
      <Card className="container my-3">
        <Card.Header className="single-desc-main">
          <p className="single-faq-top-header  single-review-top-header">
           All in One Reviews
          </p>
        </Card.Header>
        <Card.Body>
          <div className="sp-rate-div">
            <div className="sp-circle-pg">
              <div className="sp-circle">
                <CircularProgressbar
                  value={overallPercent}
                  text={`${overallPercent}%`}
                  styles={buildStyles({
                    textColor: "#3EB489",
                    pathColor: "#3EB489",
                    textSize: "14px",
                  })}
                />
              </div>
              <p className="sp-circle-text pt-3">
                Have recommended this product
              </p>
            </div>
            <div className="sp-overall-div">
              <div className="sp-review-overall">
                <p className="sp-over-all-text">Overall Score</p>
                <p className="sp-over-all-rate">
                  {productDetails.avg_rating}/5
                </p>
                <div className="sp-ov-star">
                  <Rate
                    defaultValue={productDetails.avg_rating}
                    style={{ fontSize: 40 }}
                    allowHalf
                    allowClear={true}
                    disabled={true}
                  />
                </div>
                <p className="sp-rev-text">
                  Based on {productReviews.length} reviews
                </p>
              </div>
              <div className="sp-rev-vl"></div>
              <div className="sp-hr-bar-div">
                <div className="sp-hr-bar-label">
                  <p>Features</p>
                  <p>{productDetails.avg_feature_rate}/5</p>
                </div>
                <ProgressBar
                  now={featuresPercent}
                  className="sp-hr-progress-bar"
                />
                <div className="sp-hr-bar-label">
                  <p>Value for Money</p>
                  <p>{productDetails.avg_money_rate}/5</p>
                </div>
                <ProgressBar
                  now={moneyPercent}
                  className="sp-hr-progress-bar"
                />
                <div className="sp-hr-bar-label">
                  <p>Ease of Use</p>
                  <p>{productDetails.avg_use_rate}/5</p>
                </div>
                <ProgressBar now={usePercent} className="sp-hr-progress-bar" />
                <div className="sp-hr-bar-label">
                  <p>Customer Support</p>
                  <p>{productDetails.avg_support_rate}/5</p>
                </div>
                <ProgressBar
                  now={supportPercent}
                  className="sp-hr-progress-bar"
                />
              </div>
            </div>
            <div className="sp-review-btn-div">
              <p className="sp-rev-we-love-text">
                we love to hear from our buyers!
              </p>
              <p className="sp-if-you-text">
                {" "}
                If you have used the solution already, why not share your
                thoughts and help others make the right purchase.
              </p>
              <div className="sp-rev-add-btn-div">
                <button className="sp-rev-add-btn" onClick={onClickReview}>
                  Add Review
                </button>
              </div>
            </div>
          </div>
          {productReviews.map((e) => {
            const formattedDate = moment(e.updated_at).format("MMM DD, YYYY");
            return (
              <div className="sp-cust-review-div">
                <div className="sp-cust-details-rate">
                  <div className="sp-cust-details-div">
                    <div className="sp-cust-img-div">
                      <img
                        className="sp-cust-img"
                        src={e.profile_img_url}
                        alt="N/A"
                      />
                    </div>
                    <div className="sp-cust-details">
                      <p className="sp-rev-name">{e.name}</p>
                      <p className="sp-rev-dep">
                      Department: {e.department_name} 
                      </p>
                      <p className="sp-rev-dep  sp-rev-pos">
                        {/* {e.no_of_employee} Employees */}
                        Position: {e.position}
                      </p>
                      <p className="sp-rev-dep sp-rev-company">
                        {e.company_name}
                      </p>
                    </div>
                  </div>
                  <div className="sp-cust-rating-div">
                    <div className="sp-cust-rating pt-4">
                      <p className="sp-rate-text">Overall</p>
                      <div className="sp-rev-rates">
                        <Rate
                          defaultValue={e.overall_rating}
                          style={{ fontSize: 10 }}
                          allowHalf
                          allowClear={true}
                          disabled={true}
                        />
                        <p className="sp-rev-num">{e.overall_rating}/5</p>
                      </div>
                    </div>
                  </div>
                  <hr className="sp-rev-hr"></hr>
                  <div className="sp-cust-rating-div pt-2">
                    <div className="sp-cust-rating">
                      <p className="sp-rate-text">Features</p>
                      <div className="sp-rev-rates">
                        <Rate
                          defaultValue={e.features_rate}
                          style={{ fontSize: 10 }}
                          allowHalf
                          allowClear={true}
                          disabled={true}
                        />
                        <p className="sp-rev-num">{e.features_rate}/5</p>
                      </div>
                    </div>
                  </div>
                  <div className="sp-cust-rating-div">
                    <div className="sp-cust-rating">
                      <p className="sp-rate-text">Value for Money</p>
                      <div className="sp-rev-rates">
                        <Rate
                          defaultValue={e.money_rate}
                          style={{ fontSize: 10 }}
                          allowHalf
                          allowClear={true}
                          disabled={true}
                        />
                        <p className="sp-rev-num">{e.money_rate}/5</p>
                      </div>
                    </div>
                  </div>
                  <div className="sp-cust-rating-div">
                    <div className="sp-cust-rating">
                      <p className="sp-rate-text">Customer Support</p>
                      <div className="sp-rev-rates">
                        <Rate
                          defaultValue={e.support_rate}
                          style={{ fontSize: 10 }}
                          allowHalf
                          allowClear={true}
                          disabled={true}
                        />
                        <p className="sp-rev-num">{e.support_rate}/5</p>
                      </div>
                    </div>
                  </div>
                  <div className="sp-cust-rating-div">
                    <div className="sp-cust-rating">
                      <p className="sp-rate-text">Likelihood to recommend</p>
                      <div className="sp-rev-rates">
                        <Rate
                          defaultValue={e.recommended / 2}
                          style={{ fontSize: 10 }}
                          allowHalf
                          allowClear={true}
                          disabled={true}
                        />
                        <p className="sp-rev-num">{e.recommended / 2}/5</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sp-cust-rev">
                  <p className="sp-rev-title"> {e.review_title}</p>
                  <p className="sp-rev-feedback pt-3">
                    <span className="sp-rev-reason">
                      Reason for switching :
                    </span>{" "}
                    {e.feedback}
                  </p>
                  <p className="sp-rev-date"> Posted - {formattedDate}</p>
                </div>
              </div>
            );
          })}
        </Card.Body>
      </Card>
    </>
  );
};

export default ProductReviews;
