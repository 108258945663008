import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import YouTube from 'react-youtube';


const breakpointSizes = {
    small: 480,
    medium: 768,
    large: 1024,
};

const Ytube = ({ VideoID, }) => {

    // const [breakpoint, setBreakpoint] = useState('');
    // const [playerWidth, setPlayerWidth] = useState('');
    // const [playerHeight, setPlayerHeight] = useState('');


    // const handleWindowSizeChange = () => {
    //     const { innerWidth } = window;
    //     let newBreakpoint = '';

    //     if (innerWidth < breakpointSizes.small) {
    //         newBreakpoint = 'small';
    //     } else if (innerWidth < breakpointSizes.medium) {
    //         newBreakpoint = 'medium';
    //     } else {
    //         newBreakpoint = 'large';
    //     }

    //     let newPlayerWidth = '';
    //     let newPlayerHeight = '';

    //     switch (newBreakpoint) {
    //         case 'small':
    //             newPlayerWidth = '100%';
    //             newPlayerHeight = '200px';
    //             break;
    //         case 'medium':
    //             newPlayerWidth = '100%';
    //             newPlayerHeight = '300px';
    //             break;
    //         case 'large':
    //             newPlayerWidth = '1000';
    //             newPlayerHeight = '400px';
    //             break;
    //         default:
    //             newPlayerWidth = '100%';
    //             newPlayerHeight = '360px';
    //             break;
    //     }

    //     setBreakpoint(newBreakpoint);
    //     setPlayerWidth(newPlayerWidth);
    //     setPlayerHeight(newPlayerHeight);
    // };

    // useEffect(() => {
    //     window.addEventListener('resize', handleWindowSizeChange);
    //     handleWindowSizeChange();

    //     return () => {
    //         window.removeEventListener('resize', handleWindowSizeChange);
    //     };
    // }, []);

    const opts = {
        height:"140px",
        width: "230px",
        // playerVars: {
        //     autoplay: 1,
        // },
        borderRadius: "10px"
    };
    return (
        <>

            <YouTube
            style={{width:"150px", marginLeft:"-80px"}}
                videoId={VideoID}
                opts={opts}
            />
        </>
    )
}

export default Ytube