import React, { useState } from "react";
import { useSelector } from "react-redux";
import "swiper/css/navigation";
import "swiper/swiper.min.css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import "./SingleProductTopInMobile.css";
import call from "../../../Assets/Images/call.png";
import share from "../../../Assets/Images/share.png";
import { RWebShare } from "react-web-share";
import verified from "../../../Assets/Images/verified.png";
import review from "../../../Assets/Images/rev-btn.png";
import ProductEnquiryPopup from "../../../Models/ProductEnquiryPopup/ProductEnquiryPopup";
import parse from "html-react-parser";
import HomePagePopup from "../../HomePage/Popup/HomePagePopup";
import ReactModal from "react-modal";
import { getRandomRating } from "../../../Utlis/Utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SingleProductTopInMobile = () => {
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);
  const productDetails = SingleProduct.productDetail;
  const navigate = useNavigate();

  const [popup, setPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const imagesData = SingleProduct.productImages;
  const parentImages = {
    original: SingleProduct.productDetail.product_image_url,
    thumbnail: SingleProduct.productDetail.product_image_url,
  };
  const images = imagesData.map((image) => {
    return { original: image.img_url, thumbnail: image.img_url };
  });

  images.unshift(parentImages);

  const onClickCallNow = () => {
    setPopup(!popup);
  };
  const onRequestClose = (close) => {
    setIsOpen(close);
  };
  const onClickReview = () => {
    const loginType = localStorage.getItem("logintype");
    if (loginType == 1) {
      navigate("/write-a-review");
      localStorage.setItem("productName", productDetails.product_name);
      localStorage.setItem("productId", productDetails.product_id);
    } else if (loginType == 2) {
      toast.error("Only registered customers can give feedback");
    } else {
      navigate("/login");
    }
  };

  const priceType = SingleProduct.productDetail.is_price;

  const array = [4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9];
  const fakeRate = getRandomRating(array);
  return (
    <>
      <section>
        <div className="sp-slider-and-icons">
          <div className="sp-in-mb-slider">
            <Swiper
              centeredSlides={true}
              slidesPerView={1}
              spaceBetween={5}
              pagination={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                240: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
            >
              {images.map((e) => {
                return (
                  <SwiperSlide>
                    <div className="slider">
                      <img
                        className="sp-slider-images"
                        src={e.original}
                        alt=""
                      ></img>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="sp-icons">
            <img
              onClick={onClickCallNow}
              style={{ width: "40px" }}
              src={call}
              alt=""
            />
            <div style={{ display: "grid", gap: "20px" }}>
              <button className="sp-mb-rev-btn" onClick={onClickReview}>
                <img src={review} alt="" />
              </button>
              <RWebShare
                data={{
                  url: window.location.href,
                }}
                onClick={() => console.info("share successful!")}
              >
                <button className="sp-share-btn">
                  {" "}
                  <img className="sp-share-in-mb" src={share} alt="" />
                </button>
              </RWebShare>
            </div>
          </div>
        </div>
        <div className="sp-content">
          <h4>{SingleProduct.productDetail.product_name}</h4>
          <div className="sp-brand-div pb-2">
            <p className="sp-brand-by">
              by: &nbsp;
              <span className="sp-brand-name">
                {SingleProduct.productDetail.brand_name}
              </span>
            </p>
            <div>
              <img src={verified} alt="" />
            </div>
          </div>
          <p className="sp-desc-in-mb">
            {parse(
              SingleProduct.productDetail.product_description
                .substring(0, 150)
                .replace(/^(.{145}[^\s]*).*/, "$1")
            )}
          </p>
          <div className="sp-price-and-rate pt-3">
            <div className="sp-price-div">
              <p className="sp-price d-flex">
                <span className="sp-disc">
                  {" "}
                  {+SingleProduct.productDetail.discount > 0 &&
                  priceType === "Price"
                    ? "-" + SingleProduct.productDetail.discount + "%"
                    : ""}
                </span>
                {"  "} &nbsp;
                {priceType === "Price" ? (
                  <div
                    className={
                      +SingleProduct.productDetail.discount > 0 ? "" : "pt-4"
                    }
                  >
                    {" "}
                    {"₹" + " " + SingleProduct.productDetail.price}
                  </div>
                ) : priceType === "Quote" ? (
                  <div className="pt-4"> Request Quotation </div>
                ) : priceType === "Package" ? (
                  <div className="d-flex">
                    <span className="sp-disc">
                      {" "}
                      {SingleProduct.productPackages[0] &&
                      SingleProduct.productPackages[0].discount &&
                      +SingleProduct.productPackages[0].discount > 0 &&
                      priceType === "Package"
                        ? "-" + SingleProduct.productPackages[0].discount + "%"
                        : ""}
                    </span>{" "}
                    &nbsp;
                    <div
                      className={
                        SingleProduct.productPackages[0] &&
                        SingleProduct.productPackages[0].discount &&
                        +SingleProduct.productPackages[0].discount > 0
                          ? ""
                          : "pt-4"
                      }
                    >
                      {" "}
                      {"₹" + " " + SingleProduct.productPackages[0].price}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <span className="sp-price-time pt-1">
                  {priceType === "Package" &&
                    "/" + SingleProduct.productPackages[0].price_type}
                </span>
              </p>

              {SingleProduct.productPackages[0] &&
                SingleProduct.productPackages[0].discount &&
                +SingleProduct.productPackages[0].discount > 0 && (
                  <p className="sp-mrp">
                    M.R.P.:{" "}
                    <s className="sp-mrp-price">
                      {priceType === "Package"
                        ? "₹" +
                          " " +
                          SingleProduct.productPackages[0]
                            .price_without_discount
                        : "Price not Available"}
                    </s>
                  </p>
                )}

              {+SingleProduct.productDetail.discount > 0 && (
                <p className="sp-mrp">
                  M.R.P.:{" "}
                  <s className="sp-mrp-price">
                    {priceType === "Price"
                      ? "₹" +
                        " " +
                        SingleProduct.productDetail.price_without_discount
                      : "Price not Availble"}
                  </s>
                </p>
              )}
              {priceType != "Quote" && (
                <p className="sp-inclusive">Inclusive of all taxes</p>
              )}
            </div>
            <div className="sp-rate-mb-div">
              <p className="sp-rate-mb">
                Rating{" "}
                {SingleProduct.productDetail.avg_rating > 0
                  ? SingleProduct.productDetail.avg_rating
                  : fakeRate}
                /5
              </p>
            </div>
          </div>
          <div className="sp-mb-btns">
            <button className="sp-mb-call" onClick={() => setIsOpen(true)}>
              Call Expert
            </button>
            <button className="sp-mb-get" onClick={onClickCallNow}>
              Get Pricing
            </button>
            <ReactModal
              isOpen={isOpen}
              contentLabel="Example Modal"
              onRequestClose={() => setIsOpen(false)}
            >
              <HomePagePopup close={onRequestClose} />
            </ReactModal>
          </div>
        </div>
      </section>
      {popup && <ProductEnquiryPopup closeModal={setPopup} />}
    </>
  );
};

export default SingleProductTopInMobile;
