import React from "react";
import "../../Styles/Styles.css";
import "./TrustedByNew.css";

const TrustedByNew = () => {
  function importsAll(r) {
    let images = {};
    // console.log(images)
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }
 
  const images = importsAll(
    require.context("../../Assets/Images/NewClients", false, /\.(png)$/)
  );
    //  console.log(images)
  return (
    <section className="new-trusted-by-main">
      <div className="trusted-by-div">
        <div className="d-flex align-items-center justify-content-center py-3">
          <h1 style={{ color: "white" }}>TRUSTED BY</h1>
        </div>
        <div className="container wrapper">
          {Object.values(images).map((item) => {
            return (
              <div>
                <img className="trusted-by-img" src={item} alt=""></img>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TrustedByNew;
