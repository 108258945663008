import React, { useState } from "react";
import "./singleProductTopCompo.css";
import gsafe from "../../../Assets/Images/gsafe.png";
import bestprice from "../../../Assets/Images/bestprice.png";
import Arrow from "../../../Assets/Images/Arrow.png";
import callexpert from "../../../Assets/Images/callexpert.png";
import { useSelector } from "react-redux";
import ProductEnquiryPopup from "../../../Models/ProductEnquiryPopup/ProductEnquiryPopup";
import SingleProductImageSlider from "../SingleProductImageSlider/SingleProductImageSlider";
import SingleProductTopContent from "../SingleProductTopContent/SingleProductTopContent";

const SingleProductTopImage = () => {
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);
  const [popup, setPopup] = useState(false);

  const onClickCallNow = () => {
    setPopup(!popup);
    // console.log("popup clicked");
  };

  return (
    <>
      <div className="container py-4">
        <div className="spi-main">
          <div className="col-12 col-lg-6 sp-img-slider">
            <SingleProductImageSlider />
          </div>

          <div className="col-12 col-lg-6" style={{ width: "65%" }}>
            <SingleProductTopContent />
          </div>
        </div>
        {popup && <ProductEnquiryPopup closeModal={setPopup} />}
      </div>
    </>
  );
};

export default SingleProductTopImage;
