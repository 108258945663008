import React from "react";
import "./FaqAboutProducts.css";
import { FaqContent } from "../../../Content/Faq.content";
import FaqAboutProductimg from "../../../Assets/Images/Faq-About-Products.png";
import { appScreen } from "../../../Content/app.content";

const FaqAboutProducts = () => {
  return (
    <>
      <div className="container">
      <div className="vendor-query-heading-div">
              <h2 className="faq-vendor-query-heading">
                {FaqContent.FAQVENDORQUERIESHEADING}
              </h2>
            </div>
        <div className="row mt-3 flex-column flex-md-row ">
          <div className="col-12 col-lg-8 vendor-query-col-que">
            
            <div className="accordion" id="accordionExample">
              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingNine">
                  <button
                    className="accordion-button faq-button "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseNine"
                    aria-expanded="true"
                    aria-controls="collapseNine"
                  >
                    {FaqContent.FAQVENDORQUERIESQ1}
                  </button>
                </h2>
                <div
                  id="collapseNine"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingNine"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA1}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTen">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                     {FaqContent.FAQVENDORQUERIESQ2}
                  </button>
                </h2>
                <div
                  id="collapseTen"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTen"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA2}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingEleven">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseEleven"
                    aria-expanded="false"
                    aria-controls="collapseEleven"
                  >
                     {FaqContent.FAQVENDORQUERIESQ3} 
                  </button>
                </h2>
                <div
                  id="collapseEleven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingEleven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA3}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="heading4">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapse4"
                  >
                     {FaqContent.FAQVENDORQUERIESQ4}
                  </button>
                </h2>
                <div
                  id="collapse4"
                  className="accordion-collapse collapse"
                  aria-labelledby="heading4"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA4}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTwenty">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwenty"
                    aria-expanded="false"
                    aria-controls="collapseTwenty"
                  >
                     {FaqContent.FAQVENDORQUERIESQ5}
                  </button>
                </h2>
                <div
                  id="collapseTwenty"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwenty"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA4}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTwentyone">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwentyone"
                    aria-expanded="false"
                    aria-controls="collapseTwentyone"
                  >
                     {FaqContent.FAQVENDORQUERIESQ6}
                  </button>
                </h2>
                <div
                  id="collapseTwentyone"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwentyone"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA6}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTwentytwo">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwentytwo"
                    aria-expanded="false"
                    aria-controls="collapseTwentytwo"
                  >
                     {FaqContent.FAQVENDORQUERIESQ7}
                  </button>
                </h2>
                <div
                  id="collapseTwentytwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwentytwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA7}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTwentythree">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwentythree"
                    aria-expanded="false"
                    aria-controls="collapseTwentythree"
                  >
                     {FaqContent.FAQVENDORQUERIESQ8}
                  </button>
                </h2>
                <div
                  id="collapseTwentythree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwentythree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA8}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTwentyfour">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwentyfour"
                    aria-expanded="false"
                    aria-controls="collapseTwentyfour"
                  >
                     {FaqContent.FAQVENDORQUERIESQ9}
                  </button>
                </h2>
                <div
                  id="collapseTwentyfour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwentyfour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA9}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTwentyfive">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwentyfive"
                    aria-expanded="false"
                    aria-controls="collapseTwentyfive"
                  >
                     {FaqContent.FAQVENDORQUERIESQ10}
                  </button>
                </h2>
                <div
                  id="collapseTwentyfive"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwentyfive"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA10}
                  </div>
                </div>
              </div>

              {/* <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTwentysix">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwentysix"
                    aria-expanded="false"
                    aria-controls="collapseTwentysix"
                  >
                     {FaqContent.FAQVENDORQUERIESQ11}
                  </button>
                </h2>
                <div
                  id="collapseTwentysix"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwentysix"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA11}
                  </div>
                </div>
              </div> */}

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTwentyseven">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwentyseven"
                    aria-expanded="false"
                    aria-controls="collapseTwentyseven"
                  >
                     {FaqContent.FAQVENDORQUERIESQ12}
                  </button>
                </h2>
                <div
                  id="collapseTwentyseven"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwentyseven"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA12}
                  </div>
                </div>
              </div>

              {/* <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTwentyeight">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwentyeight"
                    aria-expanded="false"
                    aria-controls="collapseTwentyeight"
                  >
                     {FaqContent.FAQVENDORQUERIESQ13}
                  </button>
                </h2>
                <div
                  id="collapseTwentyeight"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwentyeight"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA13}
                  </div>
                </div>
              </div> */}

              {/* <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingTwentynine">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwentynine"
                    aria-expanded="false"
                    aria-controls="collapseTwentynine"
                  >
                     {FaqContent.FAQVENDORQUERIESQ14}
                  </button>
                </h2>
                <div
                  id="collapseTwentynine"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwentynine"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA14}
                  </div>
                </div>
              </div> */}

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingThirty">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirty"
                    aria-expanded="false"
                    aria-controls="collapseThirty"
                  >
                     {FaqContent.FAQVENDORQUERIESQ15}
                  </button>
                </h2>
                <div
                  id="collapseThirty"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThirty"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA15}
                  </div>
                </div>
              </div>

              {/* <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingThirtyone">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirtyone"
                    aria-expanded="false"
                    aria-controls="collapseThirtyone"
                  >
                     {FaqContent.FAQVENDORQUERIESQ16}
                  </button>
                </h2>
                <div
                  id="collapseThirtyone"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThirtyone"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA16}
                  </div>
                </div>
              </div> */}

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingThirtytwo">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirtytwo"
                    aria-expanded="false"
                    aria-controls="collapseThirtytwo"
                  >
                     {FaqContent.FAQVENDORQUERIESQ17}
                  </button>
                </h2>
                <div
                  id="collapseThirtytwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThirtytwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA17}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingThirtythree">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirtythree"
                    aria-expanded="false"
                    aria-controls="collapseThirtythree"
                  >
                     {FaqContent.FAQVENDORQUERIESQ18}
                  </button>
                </h2>
                <div
                  id="collapseThirtythree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThirtythree"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA18}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingThirtyfour">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThirtyfour"
                    aria-expanded="false"
                    aria-controls="collapseThirtyfour"
                  >
                     {FaqContent.FAQVENDORQUERIESQ19}
                  </button>
                </h2>
                <div
                  id="collapseThirtyfour"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingThirtyfour"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQVENDORQUERIESA19}
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div className="col-12 col-lg-4 text-center vendor-query-col-img">
            <img
              alt="Faq-About-Products"
              src={FaqAboutProductimg}
              className="faqAboutProductsImage"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqAboutProducts;
