import React from 'react';
import "./compareRating.css";
import StarRatings from 'react-star-ratings';

const CompareRating = ({ rating, pName }) => {
    return (
        <>

            <section className='mbl-view-compare-product-border py-3 my-3' >
                <div className='container'>
                    <div className='price-top-name'>
                        <p>{pName}</p>
                    </div>
                    <div className='pt-3 d-flex align-items-center justify-content-between'>
                        <div>
                            Avg Rating
                        </div>
                        <div>
                            <StarRatings
                                rating={rating}
                                starRatedColor="#E8AA42"
                                numberOfStars={5}
                                starDimension="16px"
                                starSpacing="5px"
                            />
                        </div>
                        <div>
                            {rating}/5
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default CompareRating