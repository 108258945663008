import React from "react";
import "./LandingPageForAdvtContact.css";
import whatsIcon from "../../../Assets/Svg/whatsapp-Vector.svg";
import callIcon from "../../../Assets/Svg/callicon.svg";
import LinkedInIcon from "../../../Assets/Svg/devicon_linkedin.svg";

const LandingPageForAdvtContact = () => {
  const goToLinkedin =()=>{
    window.location.href= 'https://www.linkedin.com/company/conqt/';
  }
  const singaporeWhatsapp =()=>{
    window.location.href = 'https://wa.me/+66988656621';
  }
  const indiaWhatsapp =()=>{
    window.location.href = 'https://wa.me/+919136850189';
  }
  return (
    <div className="lp-advt-contact-div">
      <div className="lp-advt-contact-div-content">
        <div className="lp-advt-contact-col-1 ">
        <h2 className="lp-advt-contact-heading">Questions?</h2>
        <h2 className="lp-advt-contact-heading">Contact our team at info@ConQt.com or call us at</h2>
          <p className="lp-advt-contact-tagline">
            <span>Follow us on  </span>
            <img alt="" src={LinkedInIcon} onClick={goToLinkedin} className="lp-linkedin-icon"/>{" "}
            <span>
                 for the latest updates and industry insights.
            </span>
          </p>
        </div>
        <div class="lp-advt-contact-vertical-line"></div>

        <div className="lp-advt-contact-col-2 ">
          <div className="lp-advt-contact1">
          <p className="lp-advt-contact-text">Singapore
            </p>
            <img src={whatsIcon} alt="" className="lp-advt-contact-wt-icon"  onClick={singaporeWhatsapp}/><span className="lp-advt-contact-phone">+65 85 496 022</span>
          </div>
          <div className="lp-advt-contact1">
          <p className="lp-advt-contact-text">Thailand
            </p>
            <img src={callIcon} alt="" className="lp-advt-contact-ph-icon" /><span className="lp-advt-contact-phone">+66 98 865 6621</span>
          </div>
          <div className="lp-advt-contact1">
          <p className="lp-advt-contact-text">India
            </p>
            <img src={whatsIcon} alt="" className="lp-advt-contact-wt-icon" onClick={indiaWhatsapp}/><img src={callIcon}className="lp-advt-contact-ph-icon1"  alt=""/><span className="lp-advt-contact-phone">+91 913 685 0189</span>
          </div>

        </div>
      </div>
    </div>
  );
};

export default LandingPageForAdvtContact;
