import React from "react";
// import "./FaqAboutIndustries.css";
import FaqCustomerQueryImg from "../../../Assets/Images/customer-query.png";
import { FaqContent } from "../../../Content/Faq.content";

const FaqCustomerQueries = () => {
  return (
    <>
      <div className="container about-registration-div">
        <div className="faq-about-registration-heading-div">
        <h2 className="faq-about-registration-heading " style={{whiteSpace: "nowrap"}}>
              {FaqContent.FAQCUSTOMERHEADING}
            </h2>
        </div>
        <div className="row mt-5 flex-column flex-md-row">
          
          
          <div className="col-12 col-lg-4 faq-about-registration-col-img">
            <img
              alt="FAQ About Industries"
              src={FaqCustomerQueryImg}
              className="faq-about-industries-img"
            />
          </div>
          <div className="col-12 col-lg-8 faq-about-registration-col-que">
            
            <div className="accordion" id="accordionExamp">
              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingFifty">
                  <button
                    className="accordion-button faq-button "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFifty"
                    aria-expanded="true"
                    aria-controls="collapseFifty"
                  >
                    {FaqContent.FAQCUSTOMERQ1}
                  </button>
                </h2>
                <div
                  id="collapseFifty"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingFifty"
                  data-bs-parent="#accordionExamp"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQCUSTOMERA1}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingFiftyOne">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFiftyOne"
                    aria-expanded="false"
                    aria-controls="collapseFiftyOne"
                  >
                     {FaqContent.FAQCUSTOMERQ2}
                  </button>
                </h2>
                <div
                  id="collapseFiftyOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFiftyOne"
                  data-bs-parent="#accordionExamp"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQCUSTOMERA2}
                  </div>
                </div>
              </div>

              <div className="accordion-item faq-item">
                <h2 className="accordion-header faq-header" id="headingFiftyTwo">
                  <button
                    className="accordion-button faq-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFiftyTwo"
                    aria-expanded="false"
                    aria-controls="collapseFiftyTwo"
                  >
                     {FaqContent.FAQCUSTOMERQ3} 
                  </button>
                </h2>
                <div
                  id="collapseFiftyTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingFiftyTwo"
                  data-bs-parent="#accordionExamp"
                >
                  <div className="accordion-body faq-body">
                    {FaqContent.FAQCUSTOMERA3}
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FaqCustomerQueries;
