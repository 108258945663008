import React, { useState } from "react";
import "./CustomPagination.css";
import leftArrow from "../../Assets/Images/left-arrow.png";
import rightArrow from "../../Assets/Images/right-arrow.png";
import firstpage from "../../Assets/Images/firstpage.png";
import lastpage from "../../Assets/Images/lastpage.png";

const CustomPagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxPageButtons = 5;
  const [startPage, setStartPage] = useState(1);

  const handlePageChange = (page) => {
    onPageChange(page);
    window.scrollTo(0, 0);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      if (startPage === currentPage && startPage > 1) {
        setStartPage((prev) => Math.max(prev - maxPageButtons, 1));
      }
      handlePageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      if (currentPage >= startPage + maxPageButtons - 1) {
        setStartPage(startPage + maxPageButtons);
      }
      handlePageChange(currentPage + 1);
    }
  };

  const handleFirstPage = () => {
    handlePageChange(1);
    setStartPage(1);
  };

  const handleLastPage = () => {
    handlePageChange(totalPages);
    setStartPage(Math.max(totalPages - maxPageButtons + 1, 1));
  };

  const generatePageButtons = () => {
    const buttons = [];
    const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={i === currentPage ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className="pagination-container">
      <button
        onClick={handleFirstPage}
        disabled={currentPage === 1}
        style={{ borderRadius: "8px 0px 0px 8px", width: "50px" }}
      >
        <img src={firstpage} alt="first" />
      </button>
      <button onClick={handlePreviousPage} disabled={currentPage === 1}>
        <img src={leftArrow} alt="previous" />
      </button>

      {generatePageButtons()}

      <button onClick={handleNextPage} disabled={currentPage === totalPages}>
        <img src={rightArrow} alt="next" />
      </button>
      <button
        onClick={handleLastPage}
        disabled={currentPage === totalPages}
        style={{ borderRadius: "0px 8px 8px 0px", width: "50px" }}
      >
        <img src={lastpage} alt="last" />
      </button>
    </div>
  );
};

export default CustomPagination;
