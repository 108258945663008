import React from 'react';
import './singleswiper.css';
import "swiper/css/navigation";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import Sw1 from '../../../Assets/Svg/sw1.svg';
import Sw2 from '../../../Assets/Svg/sw2.svg';
import Sw3 from '../../../Assets/Svg/sw3.svg';
import Sw4 from '../../../Assets/Svg/sw4.svg';
import Sw5 from '../../../Assets/Svg/sw5.svg';
import Sw6 from '../../../Assets/Svg/sw6.svg';
import Sw7 from '../../../Assets/Svg/sw7.svg';
import Sw8 from '../../../Assets/Svg/sw8.svg';

const SingleCategorySwiper = () => {
    return (
        <>
           
            <div className='single-cat-swiper'>
                <Swiper
                    slidesPerView={6}
                    spaceBetween={10}
                    // pagination={{
                    //     clickable: true,
                    // }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    breakpoints={{
                        240: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 10,
                        },
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='slider'>
                            <img src={Sw1} alt="" className='sliderImg'></img>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider'>
                            <img src={Sw2} alt="" className='sliderImg'></img>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider'>
                            <img src={Sw3} alt="" className='sliderImg'></img>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider'>
                            <img src={Sw4} alt="" className='sliderImg'></img>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div>
                            <img src={Sw5} alt="" className='sliderImg'></img>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider'>
                            <img src={Sw6} alt="" className='sliderImg'></img>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider'>
                            <img src={Sw7} alt="" className='sliderImg'></img>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className='slider'>
                            <img src={Sw8} alt="" className='sliderImg'></img>
                        </div>
                    </SwiperSlide>

                </Swiper>
            </div>
        </>
    )
}

export default SingleCategorySwiper