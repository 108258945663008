import React ,{useEffect}from "react";
import LoginBackground from "../../Components/Login/LoginBackground/LoginBackground";
import LoginForm from "../../Components/Login/LoginForm/LoginForm";
import "./Login.css";

const Login = () => {
  useEffect (()=>{
    window.scrollTo(0, document.body.scrollHeight);
  },[])
  return (
    <>
      <section className="login-main">
   
          <LoginBackground />
          <LoginForm />
   
      
      </section>
    </>
  );
};

export default Login;
