import { createSlice } from "@reduxjs/toolkit";

export const myReducer = createSlice({
  name: "conqt",
  initialState: {
    singleCategory: { category_name: "", category_id: 0 },
  },
  reducers: {
    updatedSingleCategoryProductsData: (state, action) => {
      // console.log("*****in the store of SingleCategory", action);
      // console.log(
      //   action.payload,
      //   "action.payload in the reducer of SingleCategory"
      // );
      state.singleCategory = action.payload;
    },
  },
  extraReducers: {},
});
export const { updatedSingleCategoryProductsData } = myReducer.actions;

export default myReducer.reducer;
