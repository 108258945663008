export const PRODUCT_DETAILS = "/product/Get-Product-Details-New?product_id="
export const INDUSTRY_DETAILS = "/ProductMaster/Get-Industries"
export const CATEGORY_DETAILS = "/product/Get-Parent-Category"
export const DEPARTMENT_DETAILS = "/ProductMaster/Get-Departments"
export const PRODUCTS_DEPARTMENT_DETAILS = "/product/Get-Products-By-Department"
export const PRODUCTS_INDUSTRY_DETAILS = "/product/Get-Products-By-Industry"
export const PRODUCTS_CATEGORY_DETAILS = "/product/Get-Products-By-Category"
export const ADD_PRODUCT_REVIEW = "/ProductReview/Add-Product-Review"
export const ADD_EXPERT = "/expert/Add-Expert"
export const CUSTOMER_LOGIN ="/user/User-login"
