import React, { useState } from "react";
import "./features.css";
import Card from "react-bootstrap/Card";
import Cirlce from "../../../Assets/Svg/check-circle.svg";
import showall from "../../../Assets/Svg/showall.svg";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const Features = ({ Features }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Card className="container my-3">
        <Card.Header className="single-desc-main">
          <p className="single-fea-top-header">Features</p>
        </Card.Header>
        <Card.Body>
          {/* <Card.Title>What is <strong style={{ color: "#3EB489" }}>Microsoft 365 Business</span></Card.Title> */}
          <Card.Text>
            <div className="mbl-fea">
              <p className="mbl-fea"> {parse(Features)}</p>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </>
  );
};

export default Features;
