import React, { useState } from "react";
import { useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SmCard1 from "../../../../Assets/Svg/sm-card-1.svg";
import SmCard2 from "../../../../Assets/Svg/sm-card-2.svg";
import "swiper/css/navigation";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import Acronis from "../../../../Assets/Svg/sm-card-1.svg";
import CatCard from "./CatCard";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";

const BestSeller = () => {
  const { AllProducts } = useSelector((state) => state.allProducts);
  const newData = [...AllProducts].sort(() => 0.5 - Math.random());

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (SyntheticEvent, number) => {
    setValue(number);
  };
  return (
    <>
      <Box sx={{ width: "100%" }} className="box-cat-right mt-3">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* <Tab label="Best Sellers" {...a11yProps(0)} /> */}
            <Tab label="New Arrivals" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Swiper
            slidesPerView={4}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              240: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {newData.slice(0, 3).map((e) => {
              return (
                <SwiperSlide>
                  <div className="d-flex align-items-center justify-content-center">
                    <CatCard
                      image={e.image_url}
                      title={e.product_name}
                      desc={parse(
                        e.product_description
                          .substring(0, 80)
                          .replace(/^(.{70}[^\s]*).*/, "$1")
                      )}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </TabPanel>
      </Box>
    </>
  );
};

export default BestSeller;
