import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import "./Packages.css";
import { PricingContent } from "../../../Content/SingleProduct.content";
import tick from "../../../Assets/Images/pg-tick.png";
import cross from "../../../Assets/Images/pg-cross.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Packages = () => {
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);
  const data =
    SingleProduct.productPackages.length && SingleProduct.productPackages;
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(data);
  const [showCheckBoxContent, setShowCheckBoxContent] = useState(false);
  const [yearPrice, setYearPrice] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  // console.log(data, "Packages Data");

  const onClickYearly = (e) => {
    if (e.target.value) {
      // const filtered = data.filter(
      //   (item) => item.price_type === e.target.value
      // );
      // setFilteredData(filtered);
      setYearPrice(true);
    } else {
      // setFilteredData(data);
      setYearPrice(false);
    }
  };
  const onClickMonthly = (e) => {
    if (e.target.value) {
      setYearPrice(false);
    }
  };

  // useEffect(() => {
  //   const isYearly = data.some((e) => {
  //     return e.price_type === "Year";
  //   });
  //   const isMonthly = data.some((e) => {
  //     return e.price_type === "Month";
  //   });
  //   setShowCheckBoxContent(isMonthly && isYearly);
  // }, [filteredData]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 900;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  // document.getElementById("Month").checked = true;

  return (
    <>
      <Card className="container my-3">
        <Card.Header className="single-desc-main">
          <p className="single-desc-top-header">Pricing</p>
        </Card.Header>
        <Card.Body>
          <h6 className="package-header">{PricingContent.HEADER}</h6>

          { (
            <div className="pge-round-check-box">
              <div className={`${!isMobile ? "rounds" : ""}`}>
                <input
                  type="checkbox"
                  name="month"
                  value="Month"
                  id="month"
                  onChange={onClickMonthly}
                  checked={!yearPrice ? true : false}
                />
                <label htmlFor="month"></label>
              </div>
              <p className="pge-round-text">Pay Monthly</p>
              <div className={`${!isMobile ? "rounds" : ""}`}>
                <input
                  type="checkbox"
                  name="month"
                  value="Year"
                  id="year"
                  onChange={onClickYearly}
                  checked={yearPrice ? true : false}
                />
                <label htmlFor="year"></label>
              </div>
              <p className="pge-round-text">Pay Annually</p>
            </div>
          )}

          <div
            className={
              data && data.length <= 3
                ? "pge-cards cardOne"
                : "pge-cards cardFour"
            }
          >
            {data.map((e) => {
              const isRecommanded = e.is_recommanded != 1;
              return (
                <div
                  className={
                    isRecommanded
                      ? "package-card"
                      : "package-card rmd-package-card"
                  }
                >
                  <h3
                    className={
                      isRecommanded ? "pge-card-header" : "pge-card-header rec"
                    }
                  >
                    {e.package_name}
                  </h3>

                  <p className={isRecommanded ? "pge-desc" : "pge-desc rec"}>
                    {e.description}
                  </p>
                  <div className="d-flex">
                    <p
                      className={isRecommanded ? "pge-price" : "pge-price rec"}
                    >
                      {yearPrice ? e.yearly_price : e.price}
                    </p>{" "}
                    &nbsp;
                    <p className={isRecommanded ? "pge-type" : "pge-type rec"}>
                      / {yearPrice ? "Year" : "Month"}
                    </p>
                  </div>
                  <button className="pge-btn">Buy Now</button>
                  <div className="pt-5 pge-features">
                    {e.package_contents.map((v) => {
                      return (
                        <div className="d-flex pge-features-div">
                          <img
                            className="pge-tick-img"
                            src={v.is_checked === 1 ? tick : cross}
                          />{" "}
                          &nbsp; &nbsp;
                          <p
                            className={
                              isRecommanded
                                ? "pge-active-feature"
                                : "pge-active-feature rec"
                            }
                          >
                            {v.content}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Packages;
