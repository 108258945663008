import React, { useState, useEffect } from "react";
import { Modal, Button, ButtonToolbar, Placeholder } from "rsuite";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductList } from "../../../Store/Reducer/AllProductsList";
import "./productPopup.css";
import cross from "../../../Assets/Images/close-button.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoCloseCircle } from "react-icons/io5";
import {
  updatedAllProductsData,
  updatedCurrentSelectedProduct1,
  updatedCurrentSelectedProduct2,
  updatedCurrentSelectedProduct3,
} from "../../../Store/Reducer/AllProductsReducer";
import { current } from "@reduxjs/toolkit";

const ProductPopup = ({ open, handleClose, allProducts }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectProductPopup, setSelectProductPopup] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [showSelectedProduct, setShowSelectedProduct] = useState(true);
  const [close, setClose] = useState(handleClose);

  const dispatch = useDispatch();
  const {
    currentSelectedProduct1,
    currentSelectedProduct2,
    currentSelectedProduct3,
  } = useSelector((state) => state.allProducts);

  console.log("currentSelectedProduct1", currentSelectedProduct1);

  useEffect(() => {}, [updatedAllProductsData]);

  const handleSelectProduct = () => {
    setSelectProductPopup(true);
    setShowSelectedProduct(false);
  };

  console.log("open", open);

  console.log("selected product", selectedProduct);

  const CustomOption = ({
    label,
    image,
    brand,
    onChange,
    onSelect,
    styles,
  }) => (
    <>
      <div className="show-products">
        <div className=" container inner-pro">
          <div className="d-flex align-items-center justify-content-between">
            <div className="p-2">
              <img
                src={image}
                alt=""
                // style={{ width: "100px", height: "80px" }}
                className="popup-select-image"
              />
            </div>
            <div className="compare-popup-labels">
              <h5 className="pro-brand-name">{label}</h5>
              <p className="pro-brand-label">{brand}</p>
            </div>
          </div>
          <div>
            <button className="pro-brand-btn" onClick={onChange}>
              <p className="pro-brand-btn-name">Select</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );

  const customStyles = {
    options: (provided, state) => ({
      ...provided,
      height: "370px",
    }),
  };

  return (
    <>
      <Modal
        open={open}
        handleClose={handleClose}
        size="sm"
        className="rs-suite-bg-color"
      >
        <div
          className="d-flex align-items-center justify-content-end"
          style={{ marginTop: "-10px" }}
        >
          {/* <img src={cross} alt="" onClick={handleClose} style={{ cursor: "pointer" }} /> */}
          <IoCloseCircle
            onClick={handleClose}
            style={{ fontSize: "30px", cursor: "pointer" }}
          />
        </div>

        <Modal.Body
          className="select-product-modal custom-modal-body"
          style={{ maxHeight: "400px" }}
        >
          <>
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  maxHeight: "450px",
                }),
              }}
              options={allProducts?.map((option) => ({
                value: option.product_name,
                label: option.product_name,
                id: option.id,
                image: option.image_url,

                customOption: (
                  <>
                    <div className="select-main-compo">
                      <CustomOption
                        label={option.product_name}
                        image={option.image_url}
                        brand={option.brand_name}
                        onSelect={() => {
                          handleSelectProduct();
                        }}
                      />
                    </div>
                  </>
                ),
              }))}
              components={{
                Option: ({ innerProps, innerRef, data }) => (
                  <>
                    <div {...innerProps} ref={innerRef}>
                      {data.customOption}
                    </div>
                  </>
                ),
              }}
              className="search-bar-popup-products"
              menuIsOpen={true}
              onChange={(selectedOption) => {
                setSelectedProduct(selectedOption);
                setShowSelectedProduct(true);
                setClose(handleClose(false));
                if (currentSelectedProduct1 == null) {
                  dispatch(updatedCurrentSelectedProduct1(selectedOption));
                }
                if (
                  currentSelectedProduct1 &&
                  currentSelectedProduct2 == null
                ) {
                  dispatch(updatedCurrentSelectedProduct2(selectedOption));
                }
                if (currentSelectedProduct1 && currentSelectedProduct2) {
                  dispatch(updatedCurrentSelectedProduct3(selectedOption));
                }
              }}
            />
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProductPopup;
