import React from "react";
import Card from "react-bootstrap/Card";
import "./faq.css";
import Plus from "../../../Assets/Svg/plus+.svg";
import Minus from "../../../Assets/Svg/minus-.svg";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";

const FAQ = () => {
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);
  const faq = SingleProduct.productFAQs.length && SingleProduct.productFAQs;
  return (
    <>
      <Card className="container my-3">
        <Card.Header className="single-desc-main">
          <p className="single-faq-top-header">Frequently Asked Questions</p>
        </Card.Header>
        <Card.Body>
          <Accordion>
            {faq.map((e, index) => {
              // console.log(index.toString(), "index");
              return (
                <Accordion.Item eventKey={index.toString()}>
                  <Accordion.Header>
                    <p className="sp-faq-question">{e.question}</p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p className="sp-faq-answer">{e.answer}</p>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </Card.Body>
      </Card>
    </>
  );
};

export default FAQ;
