function getRandomRating(arr) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const item = arr[randomIndex];
    return item;
  }
  function calculatePercentage(numerator, denominator) {
    return Math.round((numerator / denominator) * 100) ;
  }


  module.exports = {
    getRandomRating,
    calculatePercentage
  };
  