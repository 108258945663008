import React from "react";
import "./AddProductsToCompareCard.css";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import { CompareProductsContent } from "../../../Content/CompareProducts.content";
import { Link, useNavigate } from "react-router-dom";
import leftpatch from "../../../Assets/Images/ads-left-patch.png";
import rightpatch from "../../../Assets/Images/ads-right-patch.png";
import SalesforceImage from "../../../Assets/Images/product-logo-cp.png";
import closebutton from "../../../Assets/Images/close-button.png";
import AddProductPlusIconBox from "../AddProductPlusIconBox/AddProductPlusIconBox";
import AddedProductToCompare from "../AddedProductToCompare/AddedProductToCompare";
import SearchAddProductPopUpPage from "../SearchAddProductPopUpPage/SearchAddProductPopUpPage";
import { getAllProductList, updateCompareProductList } from '../../../Store/Reducer/AllProductsList';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from "../AddIcon";
import ImageCompo from "../ImageCompo";
import AddIcon2 from "../AddIcon2";
import PlusMinus from "../Plus-Minus";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { toast } from "react-toastify";
import axios from "axios";
import { updatedCurrentSelectedProduct1, updatedCurrentSelectedProduct2, updatedCurrentSelectedProduct3 } from "../../../Store/Reducer/AllProductsReducer";
import MobileAddIcon from "../AddIcon/MobileAddIcon";
import MobileAddIcon2 from "../AddIcon2/MobileAddIcon2";
import MobileAddIcon3 from "../AddIcon2/MobileAddIcon3";

const AddProductsToCompare = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const CompareProduct =()=>{
  //   navigate("/");
  // }

  const { currentSelectedProduct1, currentSelectedProduct2, currentSelectedProduct3 } = useSelector(
    (state) => state.allProducts
  );
  console.log("currentSelectedProduct2", currentSelectedProduct2);
  console.log("currentSelectedProduct1", currentSelectedProduct1);
  const id1 = currentSelectedProduct1 && currentSelectedProduct1?.id ? currentSelectedProduct1?.id : null;
  const id2 = currentSelectedProduct2 && currentSelectedProduct2?.id ? currentSelectedProduct2?.id : null;
  const id3 = currentSelectedProduct3 && currentSelectedProduct3?.id ? currentSelectedProduct3?.id : null;
  console.log("id1", id1);
  console.log("id2", id2);
  console.log("id3", id3);

  const sendProducts = () => {
    // const ids = [id1, id2, id3];
    // ids.split(",")  
    var ids;
    
    if (id1 == null) {
      ids = [id2, id3];
    }
    else if (id2 ==null) {
      ids = [id1, id3];
    }
    else if ( id3== null) {
      ids = [id2, id1];
    }
    else if (id1, id2, id3) {
      ids = [id1, id2, id3];
    } 


    console.log("ids", ids);
    const data = { "product_id": ids.toString() }
    console.log("data", data);
    // const token = localStorage.getItem("token");

    axios
      .post(`${BaseUrl}/product/Get-Compare-Products`, data)
      .then((res) => {
        console.log("res", res);
        if (res.data.status === 200) {
          console.log("res.data.data", res.data.data);
          dispatch(updateCompareProductList(res.data.data));
          // dispatch(updatedCurrentSelectedProduct1(null));
          // dispatch(updatedCurrentSelectedProduct2(null));
          // dispatch(updatedCurrentSelectedProduct3(null));
          navigate("/compare-between-products");

        }
        else if (res.data.status === 401) {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log("error", error);
      })


  };


  return (
    <>
      <div className="add-product-to-compare-div">
        <div >
          <h2 className="add-product-to-compare-div-heading">{CompareProductsContent.APTCHEADER}</h2>
        </div>
        <div className="container  d-none d-md-block">
          <div className="added-products-div">
            <AddIcon />
            <AddIcon2 />
            {currentSelectedProduct2 ? <PlusMinus /> : ""}
          </div>
        </div>
        <div className="d-block d-sm-none">
          <MobileAddIcon />
          <MobileAddIcon2 />
          {currentSelectedProduct2 ? <MobileAddIcon3 /> : ""}
        </div>

        <div className="button-cp">
          {(currentSelectedProduct1 && currentSelectedProduct2) || 
          (currentSelectedProduct2 && currentSelectedProduct3)  ||
          (currentSelectedProduct1 && currentSelectedProduct3)
          ?
          (<button className="compare-button" onClick={sendProducts}>
            COMPARE
          </button>):
          (<button className="compare-button-disabled">
          COMPARE
        </button>)}
        </div>
      </div>
    </>
  );
};

export default AddProductsToCompare;
