import React, { useState } from "react";
import "./WelcomeHeader.css";
import { ConqtUniversityContent } from "../../../Content/ConqtUniversity.content";
import handshakeblack from "../../../Assets/Images/handshakeblack.png";
import project from "../../../Assets/Images/project.png";
import proposal from "../../../Assets/Images/proposal.png";
import products from "../../../Assets/Images/products.png";

const WelcomeHeader = ({
  showProject,
  setShowProject,
  showBecomePartner,
  setShowBecomePartner,
  showSubmitProposal,
  setShowSubmitProposal,
  showAddProducts,
  setShowAddProducts,
}) => {
  const onClickBecomePartner = () => {
    setShowBecomePartner(true);
    setShowProject(false);
    setShowSubmitProposal(false);
    setShowAddProducts(false);
  };
  const onClickGetProject = () => {
    setShowProject(true);
    setShowSubmitProposal(false);
    setShowAddProducts(false);
    setShowBecomePartner(false);
  };
  const onClickSubmitProposal = () => {
    setShowSubmitProposal(true);
    setShowAddProducts(false);
    setShowBecomePartner(false);
    setShowProject(false);
  };
  const onClickAddProducts = () => {
    setShowAddProducts(true);
    setShowBecomePartner(false);
    setShowProject(false);
    setShowSubmitProposal(false);
  };

  return (
    <>
      <div className="intro-to-university">
        <h3 className="welcome-university">
          {ConqtUniversityContent.UNIVERSITYHEADER}
        </h3>
        <hr className="line-university" />
        <p className="tagline-university">
          {ConqtUniversityContent.UNIVERSITYDESCRIPTION}
        </p>
      </div>
      <div className="info-cards">
        <div
          className={
            !showBecomePartner
              ? "individual-card"
              : "individual-card card-highlighted-active"
          }
          onClick={onClickBecomePartner}
        >
          <div className="img-container-handshake">
            <img className="img-card-handshake" src={handshakeblack} alt="" />
          </div>
          <h3 className="partner-header">
            {ConqtUniversityContent.PARTNERHEADER}{" "}
          </h3>
          <p className="partner-tagline">
            {ConqtUniversityContent.PARTNERDESCRIPTION}
          </p>
        </div>
        <div
          className={
            !showProject
              ? "individual-card"
              : "individual-card card-highlighted-active"
          }
          onClick={onClickGetProject}
        >
          <div className="img-container-project">
            <img className="img-card-project" src={project} alt="" />
          </div>
          <h3 className="partner-header">
            {ConqtUniversityContent.PROJECTHEADER}
          </h3>
          <p className="partner-tagline">
            {ConqtUniversityContent.PROJECTDESCRIPTION}
          </p>
        </div>
        <div
          className={
            !showSubmitProposal
              ? "individual-card"
              : "individual-card card-highlighted-active"
          }
          onClick={onClickSubmitProposal}
        >
          <div className="img-container-proposal">
            <img className="img-card-proposal" src={proposal} alt="" />
          </div>
          <h3 className="partner-header">
            {ConqtUniversityContent.PROPOSALHEADER}
          </h3>
          <p className="partner-tagline">
            {ConqtUniversityContent.PROPOSALDESCRIPTION}
          </p>
        </div>
        <div
          className={
            !showAddProducts
              ? "individual-card"
              : "individual-card card-highlighted-active"
          }
          onClick={onClickAddProducts}
        >
          <div className="img-container-products">
            <img className="img-card-products" src={products} alt="" />
          </div>
          <h3 className="partner-header">
            {ConqtUniversityContent.PRODUCTHEADER}
          </h3>
          <p className="partner-tagline">
            {ConqtUniversityContent.PRODUCTDESCRIPTION}
          </p>
        </div>
      </div>
    </>
  );
};
export default WelcomeHeader;
