import React, { useEffect } from 'react';
import './imageCompo.css'
import { useSelector } from 'react-redux';
import { IoCloseCircle } from 'react-icons/io5';
import cross from '../../../Assets/Svg/crossVector.svg';
import StarRatings from 'react-star-ratings';

const ImageCompo = ({ handleCloseProduct }) => {

        const { currentSelectedProduct1 } = useSelector((state) => state.allProducts);
        console.log("currentSelectedProduct11===>>", currentSelectedProduct1)

        const image = currentSelectedProduct1?.image;
        const productName = currentSelectedProduct1?.value;
        const productBrand = currentSelectedProduct1?.label;
        const rating = currentSelectedProduct1?.avg_rating;


        return (
                <>
                        <div className='d-none d-md-block'>
                                <section className='add-product-compo'>
                                        <div className=' container d-flex align-items-center justify-content-end pt-2' >
                                                {/* <IoCloseCircle onClick={handleCloseProduct} style={{ fontSize: "30px", cursor: "pointer" }} /> */}
                                                <img src={cross} alt="" onClick={handleCloseProduct} style={{ fontSize: "30px", cursor: "pointer" }} />
                                        </div>
                                        <div className="container d-flex align-items-center justify-content-center" style={{ marginTop: "40px" }}>
                                                <div>
                                                        <img src={image} alt="" style={{ width: "160px", height: "150px" }} />
                                                </div>
                                                <div className='px-2'>
                                                        <h5 className='pro-brand-name'>{productName}</h5>
                                                        <p className='pro-brand-label'>{productBrand}</p>
                                                        <div>
                                                                <StarRatings
                                                                        rating={rating}
                                                                        starRatedColor="#E8AA42"
                                                                        numberOfStars={5}
                                                                        starDimension="16px"
                                                                        starSpacing="5px"
                                                                />
                                                        </div>
                                                </div>

                                        </div>
                                </section>
                        </div>


                        <div className='d-block d-sm-none'>
                                <section className='add-product-compo-img'>
                                        <div className='container d-flex align-items-center justify-content-end pt-2' >
                                                <img src={cross} alt="" onClick={handleCloseProduct} style={{ fontSize: "30px", cursor: "pointer" }} />
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                        <img src={image} alt="" style={{ width: "120px", height: "120px" }} />
                                                        <div className='d-flex align-items-center justify-content-center flex-column' style={{width:"150px"}}>
                                                                <h5 className='pro-brand-name'>{productName}</h5>
                                                                <p className='pro-brand-label'>{productBrand}</p>
                                                        </div>

                                                </div>
                                                <div style={{width:"132px"}}>
                                                        <StarRatings
                                                                rating={rating}
                                                                starRatedColor="#E8AA42"
                                                                numberOfStars={5}
                                                                starDimension="20px"
                                                                starSpacing="2px"
                                                        />
                                                </div>
                                        </div>
                                </section>

                        </div>

                </>
        )
}

export default ImageCompo