import React from "react";
import "./SysStartSelling.css";
import { StartSellingOnConqtContent } from "../../../Content/SellYourSolution.content";

const SysStartSelling = () => {
  return (
    <div className="container sys-start-selling-div text-center">
      
      <a href="https://login.conqt.com/">      {/* Have to change the url, after getting the url*/}        
        <button className="sys-start-selling-button">
          {StartSellingOnConqtContent.STARTSELLINGBUTTONTEXT}
        </button>
      </a>
    </div>
  );
};

export default SysStartSelling;
