import React, { useState } from "react";
import DepartmentCard from "../../Components/SingleCategory/DepartmentCard";
import HomePageWithSideNav from "../../Layouts/HomeWithSideLayout";
import HomePageLayout from "../../Layouts/HomePageLayout";
import Acronis from "../../Assets/Svg/acronis.svg";
import "./singleCat.css";
import SingleCategorySwiper from "../../Components/SingleCategory/SingleSwiper";
import BestSeller from "../../Components/MobileComponents/SingleIndustry/BestSeller/Index";
import SideNavigation from "../../Components/SideNavigation";
import { Helmet } from "react-helmet";

const SingleDepartment = () => {
  const [data, setData] = useState("");
  const [numOfShow, setNumOfShow] = useState(5);
  const [sortOrder, setSortOrder] = useState("asc");
  const [toggle, setToggle] = useState(false);

  const handleShow = (e) => {
    setNumOfShow(e.target.value);
  };
  const onChangeSearch = (e) => {
    e.preventDefault();
    setData(e.target.value);
  };

  const handleSort = (e) => {
    const newSort =
      sortOrder === "asc" ? setSortOrder("desc") : setSortOrder("asc");
    setSortOrder(e.target.value, newSort);
    // console.log("sort", newSort)
  };

  const toggleMenu = () => {
    setToggle(!toggle);
  };
  console.log("state", sortOrder);
  const path = window.location.pathname;
  const segments = path.split("/");
  const valueAfterLastSlash = segments[segments.length - 1];

  //   console.log("currentURL", valueAfterLastSlash);

  return (
    <>
      <Helmet>
        <title>{valueAfterLastSlash + " | ConQT"}</title>
        <meta
          name="description"
          content={
            "ConQt Helps million of Businesses to Buy the Best Software Online. 500+ verified Software Vendors registered on ConQt."
          }
        />
      </Helmet>
      <div className="d-none d-lg-block">
        <HomePageWithSideNav>
          <div className="container" style={{ marginTop: "30px" }}>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <form className="nosubmit">
                  <input
                    className="nosubmit"
                    type="search"
                    placeholder="Search By Product Name"
                    onChange={onChangeSearch}
                  />
                </form>
              </div>
              <div className="d-flex ">
                <div className="px-2">
                  <label htmlFor="" for="category" className="shot-cat">
                    Sort By
                  </label>
                  <select
                    id="category"
                    className="sel-cat mx-2"
                    value={sortOrder}
                    onChange={handleSort}
                  >
                    <option value="asc">A to Z</option>
                    <option value="desc">Z to A</option>
                    <option value="High to Low">High to Low</option>
                    <option value="low to High">Low to High</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="" for="show" className="shot-cat">
                    Show
                  </label>
                  <select
                    id="show"
                    className="sel-cat mx-2"
                    value={numOfShow}
                    onChange={handleShow}
                  >
                    <option value="1">1</option>
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                  </select>
                </div>
              </div>
            </div>

            <DepartmentCard
              image={Acronis}
              searchText={data}
              numOfShow={numOfShow}
              sortOrder={sortOrder}
            />
          </div>
        </HomePageWithSideNav>
      </div>

      <div className="d-block d-sm-none">
        <HomePageLayout>
          <div>
            <BestSeller />
          </div>
          <div className="container">
            <form className="nosubmit">
              <input
                className="nosubmit"
                type="search"
                placeholder="Search By Product Name"
                onChange={onChangeSearch}
              />
            </form>
          </div>
          <div className="container d-flex  align-items-center justify-content-between pt-2">
            <div className="px-2">
              <label htmlFor="" for="category" className="shot-cat">
                Sort By
              </label>
              <select
                id="category"
                className="sel-cat mx-2"
                value={sortOrder}
                onChange={handleSort}
              >
                <option value="asc">A to Z</option>
                <option value="desc">Z to A</option>
                <option value="High to Low">High to Low</option>
                <option value="low to High">Low to High</option>
              </select>
            </div>

            <div>
              <label htmlFor="" for="show" className="shot-cat">
                Show
              </label>
              <select
                id="show"
                className="sel-cat mx-2"
                value={numOfShow}
                onChange={handleShow}
              >
                <option value="1">1</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
          </div>
          <DepartmentCard
            image={Acronis}
            searchText={data}
            numOfShow={numOfShow}
            sortOrder={sortOrder}
          />
          <div>
            <SingleCategorySwiper />
          </div>
        </HomePageLayout>
      </div>
    </>
  );
};

export default SingleDepartment;
