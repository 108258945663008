import React from 'react';
import HomePageLayout from '../../Layouts/HomePageLayout';
import SimilarProducts from "../../Components/CompareProducts/SimilarProducts/SimilarProducts";
import CompareProductsHeader from '../../Components/CompareProducts/CompareProductsHeader/CompareProductsHeader';
import ComparisonBetweenProductsFaq from '../../Components/ComparisonBetweenProducts/ComparisonBetweenProductsFaq/ComparisonBetweenProductsFaq';
import ComparisonBetweenProductsAccordion from '../../Components/ComparisonBetweenProducts/ComparisonBetweenProductsAccordion/ComparisonBetweenProductsAccordion';
import CampareProductsMobile from '../../Components/MobileComponents/campareProducts';
import SimilarProductMobile from '../../Components/MobileComponents/campareProducts/SimilarProduct';

const ComparisonBetweenProducts = () => {
    return (
        <HomePageLayout>
            <div className='d-none d-md-block'>
                <ComparisonBetweenProductsAccordion />
            </div>

            <div className='d-block d-sm-none'>
                <CampareProductsMobile />
            </div>

            <div className='d-none d-md-block'>
                <SimilarProducts />
            </div>

            <div className='d-block d-sm-none'>
                <SimilarProductMobile />
            </div>

            {/* <ComparisonBetweenProductsFaq/> */}
        </HomePageLayout>
    );
};

export default ComparisonBetweenProducts;