import React from "react";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import "./SimilarProducts.css";
import { CompareProductsContent } from "../../../Content/CompareProducts.content";
import SalesforceProductImage from "../../../Assets/Images/product-logo-cp.png";
import OdooProductImage from "../../../Assets/Images/odoo.png";
import SeeqProductImage from "../../../Assets/Images/Seeq.png";
import GsuiteProductImage from "../../../Assets/Images/gsuite.png";
import { useNavigate } from "react-router-dom";
import ProductCardInSimilarProducts from "./ProductCardInSimilarProducts";
import { useSelector } from "react-redux";

const SimilarProducts = () => {

  const { compareProductList } = useSelector((state) => state.allProductsList);
  const similarProducts = compareProductList && compareProductList?. similarProduct
  console.log("similarProducts in similar product", similarProducts);

  const navigate = useNavigate();
  const FindProduct = () => {
    navigate("/view-all-product");
  };
  // const SimilarProductsList = [
  //   {
  //     image: SalesforceProductImage,
  //     name: CompareProductsContent.PRODUCTNAME1,
  //   },
  //   {
  //     image: OdooProductImage,
  //     name: CompareProductsContent.PRODUCTNAME2,
  //   },
  //   {
  //     image: GsuiteProductImage,
  //     name: CompareProductsContent.PRODUCTNAME3,
  //   },
  //   {
  //     image: SeeqProductImage,
  //     name: CompareProductsContent.PRODUCTNAME4,
  //   },
  // ];

  const array = [4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9];
  const getRandomRating = (array) => {
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
  };
  const fakeRate = getRandomRating(array);

  const rating = 4;

  return (
    <>
      <div className="similar-products-container container">
        <div className="sp-header">
          <h3 className="sp-heading">{CompareProductsContent.SPHEADER}</h3>
        </div>
        <div className="side-heading-sp ">
          <div>
          <h5 className="side-heading">
            {CompareProductsContent.SPSIDEHEADING}
          </h5>
          </div>
          
          <div className="button-viewmore-container">
            <button className="button-sp-vm" onClick={FindProduct}>
              {CompareProductsContent.SPBUTTON}
            </button>
          </div>

        </div>
        <div className="" style={{marginTop:"-25px", marginBottom:"20px"}}>
          <hr  style={{height:"1px", color:"#C4C4C4"}}/>
        </div>
        <div className="container products-cards-sp">
          {similarProducts?.slice(0,4).map((product, index) => (
            <ProductCardInSimilarProducts
              key={index}
              image={product.image_url}
              name={product.product_name}
              rating={product.avg_rating}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SimilarProducts;
