import React, { useState, useEffect } from 'react';
import './mobileAddIcon.css';
import plusbutton from '../../../../Assets/Images/Union.png';
import ProductPopup from '../../ProductPopup';
import { getAllProductList } from '../../../../Store/Reducer/AllProductsList';
import { useDispatch, useSelector } from 'react-redux';
import ImageCompo from '../../ImageCompo';
import { updatedCurrentSelectedProduct1 } from '../../../../Store/Reducer/AllProductsReducer';

const MobileAddIcon = () => {

        const { currentSelectedProduct1 } = useSelector((state) => state.allProducts);
        const [open, setOpen] = useState(false);
        const [showProduct, setShowProduct] = useState(currentSelectedProduct1);

        console.log("show product", showProduct)

        const dispatch = useDispatch();

        const handleClose = () => {
                setOpen(false);
        }

        const handleCloseProduct = () => {
                dispatch(updatedCurrentSelectedProduct1(null));
        }

        console.log("open", open)

        useEffect(() => {
                updatedCurrentSelectedProduct1();
        }, [currentSelectedProduct1])

        const { AllProducts } = useSelector((state) => state.allProducts);
        console.log("AllProducts", AllProducts)

       

        return (
                <>
                        {currentSelectedProduct1 ? (
                                <ImageCompo handleCloseProduct={handleCloseProduct} />) : (<>
                                        <ProductPopup open={open} handleClose={handleClose} allProducts={AllProducts} />
                                        <section className='add-product-compo'>
                                                <div className="add-plus-icon">
                                                        <img src={plusbutton} alt="" onClick={() => setOpen(true)} style={{ cursor: "pointer" }} />
                                                </div>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                        <button className='add-product-btn' onClick={() => setOpen(true)}>
                                                                <span className='add-product-btn-name' >Add Product</span>
                                                        </button>
                                                </div>
                                        </section></>)}
                </>
        )
}

export default MobileAddIcon