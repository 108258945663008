import React, { useEffect, useState } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import { useSelector } from "react-redux";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { useDispatch } from 'react-redux';
import homeicon from "../../../Assets/Images/home-cp.png";
import SalesforceImage from "../../../Assets/Images/salesforceimg.png";
import LeadSquaredImage from "../../../Assets/Images/LeadSquared.png";
import DeskeraImage from "../../../Assets/Images/deskera.png";
import PriceImage from "../../../Assets/Images/walleticon.png";
import IndustriesImage from "../../../Assets/Images/industriesVector.png";
import DeapartmentImage from "../../../Assets/Images/departmentvector.png";
import FeaturesImage from "../../../Assets/Images/featuresvector.png";
import ReviewsImage from "../../../Assets/Images/ReviewsVector.png";
import ProductImagesVec from "../../../Assets/Images/productImages-vector.png";
import ProductVideoVec from "../../../Assets/Images/product-video-Vector.png";
import PlatformsSuppVec from "../../../Assets/Images/Platforms-supp-Vector.png";
import RightVector from "../../../Assets/Images/right-Vector.png";
import WrongVector from "../../../Assets/Images/WrongVector.png";
import "./campareProductsMobile.css";
import { ComparisonBetweenProductsContent } from "../../../Content/ComparisonBetweenProducts.content";
// import Youtube from "../../../Components/SingleProduct";
import parse, { domToReact, htmlToDOM, Element } from "html-react-parser";
import Ytube from "../../CompareProducts/yTube";
import StarRatings from 'react-star-ratings';
import { useNavigate } from "react-router-dom";
import TopImageComapreMobile from "./TopImageCompare";
import Verses from "../../../Assets/Svg/verses.svg"
import PriceCompare from "./PriceCompare";
import CompareIndustry from "./Industry";
import CompareDepartment from "./Department";
import CompareFeatures from "./Features";
import CompareRating from "./Rating";
import ProductImage from "./ProductImage";
import ProductVideosCompare from "./productVidoes";
import { updatedCurrentSelectedProduct1, updatedCurrentSelectedProduct2, updatedCurrentSelectedProduct3 } from "../../../Store/Reducer/AllProductsReducer";


const ProductDetails = ({ image, pName, pBrandName }) => {

  return (
    <>

      <div className="d-none d-md-block">
        <div className="three-pc-table-product-image-div">
          <img src={image} alt="" className="Three-pc-table-product-image" />
        </div>
        <div className="three-pc-table-product-names">
          <h4 className="three-pc-table-product-pname">{pName}</h4>
          <h4 className="three-pc-table-product-bname">{pBrandName}</h4>
        </div>
      </div>



    </>
  );
};

const CustomAccordionHeaderProductComparison = ({ icon, paraname }) => {

  return (
    <>
      <div className="three-pc-para-div">
        <div>
          <img src={icon} alt="" className="three-pc-parameter-icon" />
          <span className="three-pc-paraname">{paraname}</span>
        </div>
      </div>
    </>
  );
};

const CampareProductsMobile = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { compareProductList } = useSelector((state) => state.allProductsList);
  console.log("compareProductList", compareProductList);

  const product1 = compareProductList && compareProductList?.compareProduct[0]
  const product2 = compareProductList && compareProductList?.compareProduct[1]
  const product3 = compareProductList && compareProductList?.compareProduct[2]
  console.log("product1", product1)

  // const YoutubeId1 = SingleProduct.productDetail.video_link
  //   ? SingleProduct.productDetail.video_link.split("https://youtu.be/")[1]
  //   : "";

  const proFeature1 = parse(product1 && product1.features ? product1.features:"")
  const proFeature2 = parse(product2 && product2.features ? product2.features.substring(0, 150).replace(/^(.{140}[^\s]*).*/, "$1") : "")
  const proFeature3 = parse(product3 && product3.features ? product3.features.substring(0, 150).replace(/^(.{140}[^\s]*).*/, "$1") : "")

  const YoutubeId1 = product1 && product1?.video_link ? product1?.video_link.split("https://youtu.be/")[1] : "";
  const YoutubeId2 = product2 && product2?.video_link ? product2?.video_link.split("https://youtu.be/")[1] : "";
  const YoutubeId3 = product3 && product3?.video_link ? product3?.video_link.split("https://youtu.be/")[1] : "";

  const handleWriteaReview = () => {
    navigate("/write-a-review")
  }

  const handleCompare = () => {
    navigate("/compare-products")
    dispatch(updatedCurrentSelectedProduct1(null))
    dispatch(updatedCurrentSelectedProduct2(null))
    dispatch(updatedCurrentSelectedProduct3(null))
  }
  return (
    <div className="three-pc-comparison-div">
      <Breadcrumb  onClick={handleCompare}>
        <Breadcrumb.Item href="#">
          <img className="homeicon" src={homeicon} />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="#">Compare Product</Breadcrumb.Item>
      </Breadcrumb>
      <div className="tpc-header">
        <h1 className="tpc-heading">
          {ComparisonBetweenProductsContent.COMPAREPRODUCTSHEADER}
        </h1>
        <div className="tpc-line"></div>
        <p className="tpc-description">
          {ComparisonBetweenProductsContent.COMPAREPRODUCTDESCRIPTION}
        </p>
      </div>
      {/* {JSON.stringify(compareProductList)} */}
      <div className="three-pc-table container">
        <div className="d-none d-md-block three-pc-table-pdetails">
          <div className="three-pc-table-pdetails1 col-lg-3">
            <h3 className="three-pc-table-pdetails1text">
              {ComparisonBetweenProductsContent.THREEPCTAC1}
            </h3>
          </div>

          <div >
            <div className="three-pc-table-pdetails2 col-lg-3">
              <div className="three-pc-table-product-div">
                <ProductDetails
                  image={product1 && product1?.image_url}
                  pName={product1 && product1?.product_name}
                  pBrandName={product1 && product1?.brand_name}
                />
              </div>
            </div>
            <div className="three-pc-table-pdetails2 col-lg-3">
              <div className="three-pc-table-product-div">
                <ProductDetails
                  image={product2 && product2?.image_url}
                  pName={product2 && product2?.product_name}
                  pBrandName={product2 && product2?.brand_name}
                />
              </div>
            </div>

            <div className="three-pc-table-pdetails3 col-lg-3">
              <div className="three-pc-table-product-div">
                <ProductDetails
                  image={product3 && product3?.image_url}
                  pName={product3 && product3?.product_name}
                  pBrandName={product3 && product3?.brand_name}
                />
              </div>
            </div>
          </div>
        </div>

        {/* mobile view responsive design top component */}

        <div className="d-block d-sm-none">
          <div>
            <TopImageComapreMobile
              image={product1 && product1?.image_url}
              pName={product1 && product1?.product_name}
              pBrandName={product1 && product1?.brand_name}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center my-3">
            <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
          </div>
          <div className="mb-3">
            <TopImageComapreMobile
              image={product2 && product2?.image_url}
              pName={product2 && product2?.product_name}
              pBrandName={product2 && product2?.brand_name}
            />
          </div>
          {product3 &&
            <>
              <div className="d-flex align-items-center justify-content-center my-3">
                <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
              </div>

              <div className="mb-3">
                <TopImageComapreMobile
                  image={product3 && product3?.image_url}
                  pName={product3 && product3?.product_name}
                  pBrandName={product3 && product3?.brand_name}
                />
              </div>
            </>
          }

        </div>


        <div className="">
          <Accordion allowMultiple>
            <AccordionItem
              header={
                <CustomAccordionHeaderProductComparison
                  icon={PriceImage}
                  paraname={ComparisonBetweenProductsContent.THREEPCPARA1}
                />
              }
            >
              <div className="d-none d-md-block three-pc-table-pdetails">
                <div className="three-pc-table-pdetails1 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">Price</h3>
                </div>
                <div className="three-pc-table-pdetails2 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">
                    {product1 && product1?.price ? (product1?.price) : (product1?.price == null) ? (
                      <div>
                        <p>Price on Request</p>
                        <button className="price-on-req-btn">Request</button>
                      </div>
                    ) : ""}

                  </h3>

                </div>
                <div className="three-pc-table-pdetails2 col-lg-3">
                  <h3 className="three-pc-table-pdetails1-subfeature">
                    {product2 && product2?.price ? (product2?.price) : (product2?.price == null) ? (
                      <div>
                        <p>Price on Request</p>
                        <button className="price-on-req-btn">Request</button>
                      </div>
                    ) : ""}
                  </h3>
                  <h3 className="three-pc-table-pdetails1-price">

                  </h3>

                </div>

                <div className="three-pc-table-pdetails3 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">
                    {/* {JSON.stringify(product3)} */}
                    {product3 && (product3?.price ? (product3?.price) : (product3?.price == null) ? (
                      <div>
                        <p>Price on Request</p>
                        <button className="price-on-req-btn">Request</button>
                      </div>
                    ) : "")}
                  </h3>
                </div>
              </div>

              {/* Mobile responsive design for pricing */}

              <div className="d-block d-sm-none">
                <div>
                  <PriceCompare
                    price={product1 && product1?.price}
                    pName={product1 && product1?.product_name}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                </div>

                <div>
                  <PriceCompare
                    price={product2 && product2?.price}
                    pName={product2 && product2?.product_name}
                  />
                </div>

                {product3 && <>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                  </div>

                  <div>
                    <PriceCompare
                      price={product3 && product3?.price}
                      pName={product3 && product3?.product_name}
                    />
                  </div> </>}

              </div>
            </AccordionItem>

            <AccordionItem
              header={
                <CustomAccordionHeaderProductComparison
                  icon={IndustriesImage}
                  paraname={ComparisonBetweenProductsContent.THREEPCPARA2}
                />
              }
            >
              <div className="d-none d-md-block three-pc-table-pdetails">
                <div className="three-pc-table-pdetails1 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">Industries</h3>
                </div>
                <div className="three-pc-table-pdetails2 col-lg-3">
                  <div className="icon-feature-div">
                    <h3 className="three-pc-table-pdetails1-subfeature">
                      {product1 && product1?.industry_name ? product1?.industry_name.substring(0, 150).replace(/^(.{140}[^\s]*).*/, "$1") : ""}
                    </h3>
                  </div>
                </div>
                <div className="three-pc-table-pdetails2 col-lg-3">
                  <div className="icon-feature-div ">
                    {/* <img className="right-vector" src={RightVector} /> */}
                    <h3 className="three-pc-table-pdetails1-subfeature">
                      {/* {ComparisonBetweenProductsContent.THREEPCINDUC3T1} */}
                      {product2 && product2?.industry_name ? product2?.industry_name.substring(0, 150).replace(/^(.{140}[^\s]*).*/, "$1") : ""}
                    </h3>
                  </div>
                  {/* <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T2}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T3}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T4}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T5}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T6}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T7}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T8}
                      </h3>
                    </div> */}
                </div>

                <div className="three-pc-table-pdetails3 col-lg-3">
                  {/* <img className="right-vector" src={RightVector} /> */}
                  <h3 className="three-pc-table-pdetails1-subfeature">
                    {product3 && product3?.industry_name ? product3?.industry_name.substring(0, 150).replace(/^(.{140}[^\s]*).*/, "$1") : ""}
                  </h3>
                </div>
              </div>

              {/* mobile responsive design for Industry */}
              <div className="d-block d-sm-none">
                <div>
                  <CompareIndustry
                    pName={product1 && product1.product_name}
                    content={product1 && product1.industry_name}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                </div>

                <div>
                  <CompareIndustry
                    pName={product2 && product2.product_name}
                    content={product2 && product2.industry_name}
                  />
                </div>

                {product3 && <>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                  </div>

                  <div>
                    <CompareIndustry
                      pName={product3 && product3.product_name}
                      content={product3 && product3.industry_name}
                    />
                  </div> </>}

              </div>
            </AccordionItem>

            <AccordionItem
              header={
                <CustomAccordionHeaderProductComparison
                  icon={DeapartmentImage}
                  paraname={ComparisonBetweenProductsContent.THREEPCPARA3}
                />
              }
            >
              <div className="d-none d-md-block three-pc-table-pdetails">
                <div className="three-pc-table-pdetails1 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">Department</h3>
                </div>
                <div className="three-pc-table-pdetails2 col-lg-3">
                  <div className="icon-feature-div ">
                    {/* <img className="right-vector" src={RightVector} /> */}
                    <h3 className="three-pc-table-pdetails1-subfeature">
                      {/* {productData.features} */}
                      {product1 && product1?.department_name ? product1?.department_name.substring(0, 150).replace(/^(.{140}[^\s]*).*/, "$1") : ""}
                    </h3>
                  </div>
                  {/* <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T2}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T3}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T4}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T5}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T6}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={WrongVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T7}
                      </h3>
                    </div> */}
                </div>


                <div className="three-pc-table-pdetails2 col-lg-3">
                  <div className="icon-feature-div ">
                    {/* <img className="right-vector" src={RightVector} /> */}
                    <h3 className="three-pc-table-pdetails1-subfeature">
                      {/* {ComparisonBetweenProductsContent.THREEPCINDUC3T1} */}
                      {product2 && product2?.department_name ? product2?.department_name.substring(0, 150).replace(/^(.{140}[^\s]*).*/, "$1") : ""}
                    </h3>
                  </div>
                  {/* <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T2}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T3}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T4}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T5}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T6}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={RightVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T7}
                      </h3>
                    </div>
                    <div className="icon-feature-div">
                      <img className="right-vector" src={WrongVector} />
                      <h3 className="three-pc-table-pdetails1-subfeature">
                        {ComparisonBetweenProductsContent.THREEPCINDUC3T8}
                      </h3>
                    </div> */}
                </div>

                <div className="three-pc-table-pdetails3 col-lg-3">
                  <div className="icon-feature-div ">
                    {/* <img className="right-vector" src={RightVector} /> */}
                    <h3 className="three-pc-table-pdetails1-subfeature">
                      {/* {ComparisonBetweenProductsContent.THREEPCINDUC3T1} */}
                      {product3 && product3?.department_name ? product3?.department_name.substring(0, 150).replace(/^(.{140}[^\s]*).*/, "$1") : ""}
                    </h3>
                  </div>
                </div>
              </div>

              {/* mobile responsive design for Department */}

              <div className="d-block d-sm-none">
                <div>
                  <CompareDepartment
                    pName={product1 && product1.product_name}
                    content={product1 && product1.department_name}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                </div>

                <div>
                  <CompareDepartment
                    pName={product2 && product2.product_name}
                    content={product2 && product2.department_name}
                  />
                </div>

                {product3 && <>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                  </div>

                  <div>
                    <CompareDepartment
                      pName={product3 && product3.product_name}
                      content={product3 && product3.department_name}
                    />
                  </div></>}
              </div>
            </AccordionItem>

            <AccordionItem
              header={
                <CustomAccordionHeaderProductComparison
                  icon={FeaturesImage}
                  paraname={ComparisonBetweenProductsContent.THREEPCPARA4}
                />
              }
            >
              <div className="d-none d-md-block three-pc-table-pdetails">
                <div className="three-pc-table-pdetails1 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">Features</h3>
                </div>
                <div className="three-pc-table-pdetails2 col-lg-3">
                  <div className="icon-feature-div ">
                    {/* <img className="right-vector" src={RightVector} /> */}
                    <h3 className="three-pc-table-pdetails1-subfeature">
                      {/* {ComparisonBetweenProductsContent.THREEPCINDUC3T1} */}
                      {proFeature1}
                    </h3>
                  </div>
                </div>


                <div className="three-pc-table-pdetails2 col-lg-3">
                  <div className="icon-feature-div ">
                    {/* <img className="right-vector" src={RightVector} /> */}
                    <h3 className="three-pc-table-pdetails1-subfeature">
                      {/* {ComparisonBetweenProductsContent.THREEPCINDUC3T1} */}
                      {proFeature2}
                      {/* {product2 && product2?.features} */}
                    </h3>
                  </div>

                </div>
                <div className="three-pc-table-pdetails3 col-lg-3">
                  <h3 className="three-pc-table-pdetails1-subfeature">
                    {/* {product3 && product3?.features} */}
                    {/* {parse(product3?.features)} */}
                    {proFeature3}
                  </h3>
                </div>
              </div>

              {/* mobile responsive view design Avg rating */}

              <div className="d-block d-sm-none">
                <div>
                  <CompareFeatures
                    pName={product1 && product1?.product_name}
                    content={product1 && product1?.features}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                </div>

                <div>
                  <CompareFeatures
                    pName={product2 && product2?.product_name}
                    content={product2 && product2?.features}
                  />
                </div>

                {product3 && <>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                  </div>

                  <div>
                    <CompareFeatures
                      pName={product3 && product3?.product_name}
                      content={product3 && product3?.features}
                    />
                  </div>
                </>}

              </div>

            </AccordionItem>
            <AccordionItem
              header={
                <CustomAccordionHeaderProductComparison
                  icon={ReviewsImage}
                  paraname={ComparisonBetweenProductsContent.THREEPCPARA5}
                />
              }
            >
              <div className="d-none d-md-block three-pc-table-pdetails">
                <div className="three-pc-table-pdetails1 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">Avg Rating</h3>
                </div>

                <div className="three-pc-table-pdetails2 col-lg-3">
                  {product1 && product1?.avg_rating > 0 ? (
                    <StarRatings
                      rating={product1 && product1?.avg_rating}
                      starRatedColor="#E8AA42"
                      numberOfStars={5}
                      starDimension="16px"
                      starSpacing="5px"
                    />
                  ) : product1?.avg_rating == 0 ? (
                    <div>
                      <button className="wr-review-cp-btn" onClick={handleWriteaReview}>write a review</button>
                    </div>
                  ) : ""}
                </div>

                <div className="three-pc-table-pdetails2 col-lg-3">
                  {product2 && product2?.avg_rating > 0 ? (
                    <StarRatings
                      rating={product2 && product2?.avg_rating}
                      starRatedColor="#E8AA42"
                      numberOfStars={5}
                      starDimension="16px"
                      starSpacing="5px"
                    />
                  ) : product2?.avg_rating == 0 ? (
                    <div>
                      <button className="wr-review-cp-btn" onClick={handleWriteaReview}>write a review</button>
                    </div>
                  ) : ""}
                </div>

                <div className="three-pc-table-pdetails3 col-lg-3">
                  {product3 && product3?.avg_rating > 0 ? (
                    <StarRatings
                      rating={product3 && product3?.avg_rating}
                      starRatedColor="#E8AA42"
                      numberOfStars={5}
                      starDimension="16px"
                      starSpacing="5px"
                    />
                  ) : product3?.avg_rating == 0 ? (
                    <div>
                      <button className="wr-review-cp-btn" onClick={handleWriteaReview}>write a review</button>
                    </div>
                  ) : ""}

                </div>
              </div>

              <div className="d-block d-sm-none">
                <div>
                  <CompareRating
                    pName={product1 && product1?.product_name}
                    rating={product1 && product1?.avg_rating}
                  />
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                </div>

                <div>
                  <CompareRating
                    pName={product2 && product2?.product_name}
                    rating={product2 && product2?.avg_rating}
                  />
                </div>
                {product3 && <>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                  </div>

                  <div>
                    <CompareRating
                      pName={product3 && product3?.product_name}
                      rating={product3 && product3?.avg_rating}
                    />
                  </div></>}

              </div>
            </AccordionItem>
            <AccordionItem
              header={
                <CustomAccordionHeaderProductComparison
                  icon={ProductImagesVec}
                  paraname={ComparisonBetweenProductsContent.THREEPCPARA6}
                />
              }
            >
              <div className="d-none d-md-block three-pc-table-pdetails">
                <div className="three-pc-table-pdetails1 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">Product Images</h3>
                </div>
                <div className="three-pc-table-pdetails2 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">
                    <img src={product1 && product1?.brand_logo_url} alt="" style={{ width: "150px" }} />
                  </h3>
                </div>
                <div className="three-pc-table-pdetails2 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">
                    <img src={product2 && product2?.brand_logo_url} alt="" style={{ width: "150px" }} />
                  </h3>
                </div>
                <div className="three-pc-table-pdetails3 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">
                    <img src={product3 && product3?.brand_logo_url} alt="" style={{ width: "150px" }} />
                  </h3>
                </div>
              </div>

              <div className="d-block d-sm-none">
                <div>
                  <ProductImage
                    pName={product1 && product1?.product_name}
                    image={product1 && product1?.brand_logo_url}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                </div>

                <div>
                  <ProductImage
                    pName={product2 && product2?.product_name}
                    image={product2 && product2?.brand_logo_url}
                  />
                </div>

                {product3 && <>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                  </div>

                  <div>
                    <ProductImage
                      pName={product3 && product3?.product_name}
                      image={product3 && product3?.brand_logo_url}
                    />
                  </div>
                </>}


              </div>


            </AccordionItem>
            <AccordionItem
              header={
                <CustomAccordionHeaderProductComparison
                  icon={ProductVideoVec}
                  paraname={ComparisonBetweenProductsContent.THREEPCPARA7}
                />
              }
            >
              <div className="d-none d-md-block three-pc-table-pdetails">
                <div className="three-pc-table-pdetails1 col-lg-3">
                  <h3 className="three-pc-table-pdetails1text">Product Video</h3>
                </div>
                <div className="three-pc-table-pdetails2 col-lg-3">
                  <Ytube VideoID={YoutubeId1} />
                </div>
                <div className="three-pc-table-pdetails2 col-lg-3">
                  <Ytube VideoID={YoutubeId2} />
                </div>
                {product3 && product3?.video_link ? (
                  <div className="three-pc-table-pdetails3 col-lg-3">
                    <Ytube VideoID={YoutubeId3} />
                  </div>) : (<div className="three-pc-table-pdetails3 col-lg-3">
                    { }
                  </div>)};
              </div>

              <div className="d-block d-sm-none">
                <div>
                  <ProductVideosCompare
                    pName={product1 && product1?.product_name}
                    video={YoutubeId1}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                </div>

                <div>
                  <ProductVideosCompare
                    pName={product2 && product2?.product_name}
                    video={YoutubeId2}
                  />
                </div>

                {product3 && <>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={Verses} alt="" style={{ width: "50px", marginTop: "10px" }} />
                  </div>

                  <div>
                    <ProductVideosCompare
                      pName={product3 && product3?.product_name}
                      video={YoutubeId3}
                    />
                  </div></>}

              </div>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>



  );
};

export default CampareProductsMobile;
