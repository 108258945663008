import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import "./SysFeedbackSlider.css";
import { FeedbackSliderContent } from "../../../Content/SellYourSolution.content";
import Pharmarack from "../../../Assets/Images/pharmarack.png";
import Jayasethi from "../../../Assets/Images/jayasethi.png";

SwiperCore.use([Navigation, Pagination]);

const SysFeedbackSlider = () => {
  return (
    <div className="sys-slider">
      <Swiper
        // navigation={true}
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        breakpoints={{
          240: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="sys-slider-box">
            <div className="row align-items-center gx-5">
              <div className="col-12 col-lg-6 sys-slider-box-part1">
                <h1 className="quotes-text">&#8220;</h1>
                <h2 className="sys-slider-b1-text">
                  {FeedbackSliderContent.FEEDBACKSLIDERHEADING1}
                </h2>
                <p className="sys-slider-b1-para">
                  {FeedbackSliderContent.FEEDBACKSLIDERPARA11}
                </p>
                <p className="sys-slider-b1-para">
                  {FeedbackSliderContent.FEEDBACKSLIDERPARA12}
                </p>
              </div>
              <div className="col-12 col-lg-6 sys-slider-box-part2">
                <img src={Pharmarack} alt="user" className="slider-user-dp" />
                <h5 className="sys-user-name">
                  {FeedbackSliderContent.FEEDBACKSLIDERUSERNAME1}
                </h5>
                <h5 className="sys-user-location">
                  {FeedbackSliderContent.FEEDBACKSLIDERUSERLOCATION1}
                </h5>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="sys-slider-box">
            <div className="row align-items-center gx-5">
              <div className="col-12 col-lg-6 sys-slider-box-part1">
                <h1 className="quotes-text">&#8220;</h1>
                <h2 className="sys-slider-b1-text">
                  {FeedbackSliderContent.FEEDBACKSLIDERHEADING2}
                </h2>
                <p className="sys-slider-b1-para">
                  {FeedbackSliderContent.FEEDBACKSLIDERPARA21}
                </p>
                <p className="sys-slider-b1-para">
                  {FeedbackSliderContent.FEEDBACKSLIDERPARA22}
                </p>
              </div>
              <div className="col-12 col-lg-6 sys-slider-box-part2">
                <img src={Pharmarack} alt="user" className="slider-user-dp" />
                <h5 className="sys-user-name">
                  {FeedbackSliderContent.FEEDBACKSLIDERUSERNAME2}
                </h5>
                <h5 className="sys-user-location">
                  {FeedbackSliderContent.FEEDBACKSLIDERUSERLOCATION2}
                </h5>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="sys-slider-box">
            <div className="row align-items-center gx-5">
              <div className="col-12 col-lg-6 sys-slider-box-part1">
                <h1 className="quotes-text">&#8220;</h1>
                <h2 className="sys-slider-b1-text">
                  {FeedbackSliderContent.FEEDBACKSLIDERHEADING3}
                </h2>
                <p className="sys-slider-b1-para">
                  {FeedbackSliderContent.FEEDBACKSLIDERPARA31}
                </p>
                <p className="sys-slider-b1-para">
                  {FeedbackSliderContent.FEEDBACKSLIDERPARA32}
                </p>
              </div>
              <div className="col-12 col-lg-6 sys-slider-box-part2">
                <img src={Pharmarack} alt="user" className="slider-user-dp" />
                <h5 className="sys-user-name">
                  {FeedbackSliderContent.FEEDBACKSLIDERUSERNAME3}
                </h5>
                <h5 className="sys-user-location">
                  {FeedbackSliderContent.FEEDBACKSLIDERUSERLOCATION3}
                </h5>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>

    </div>

  );
};

export default SysFeedbackSlider;
