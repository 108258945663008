const ConqtUniversityContent = {
  UNIVERSITYHEADER: "Welcome to our University",
  UNIVERSITYDESCRIPTION:
    "Our tutorials are made in a way where you get to know how things work, and how you can use them to your advantage.",

  PARTNERHEADER: "Become a Partner !",
  PARTNERDESCRIPTION:
    "Sign up on the portal and partner with us in our endeavor to connect vendors with clients globally.",
  PROJECTHEADER: "Get Project !",
  PROJECTDESCRIPTION:
    "Get access to a multitude of qualified opportunities spanning varied expertise from across the continents.",
  PROPOSALHEADER: "Submit a Proposal",
  PROPOSALDESCRIPTION:
    "Understand customer requirements and submit your proposal through an easy-to-use template on the portal.",
  PRODUCTHEADER: "Add products",
  PRODUCTDESCRIPTION:
    "Use our marketplace to showcase your products to a wider audience. Increase your outreach with our global customer database.",

  FORMHEADING: " How Can We Help",
  FORMDESCRIPTION:
    "Write us a few words about your project and we’ll prepare a proposal for you within 24 hours.",

  /*HTBAP: HOW TO BECOME A PARTNER*/
  HTBAPINTRODUCTIONHEADER: "How to become a partner?",
  HTBAPHOMEPAGEHEADER: "Home Page",
  HTBAPHOMEPAGEDESCRIPTION:
    "Visit to 'www.conqt.com' and click on the register button.",
  HTBAPREGISTRATIONHEADER: "Registration",
  HTBAPREGISTRATIONDESCRIPTION1: "1. Click on the Register button",
  HTBAPREGISTRATIONDESCRIPTION2: "2. If already Registered, click on Login",
  HTBAPREGISTRATIONDESCRIPTION3:
    "3. Once you click on the Register button, you will redirect to a selection page.",
  HTBAPPARTNERHEADER: "I am a Partner",
  HTBAPPARTNERDESCRIPTION:
    "Now you must select yourself as a Partner and move ahead for further process.",
  HTBAPFORMHEADER: "Registration Form",
  HTBAPFORMDESCRIPTION1: "1. Fill all the details on the registration form",
  HTBAPFORMDESCRIPTION2: "2. Select the check box",
  HTBAPFORMDESCRIPTION3: "3. Click on the Register Button.",
  HTBAPLOGINHEADER: "Login",
  HTBAPLOGINDESCRIPTION1:
    "1. After completing the Registration form, Select Partner",
  HTBAPLOGINDESCRIPTION2: "2. Fill the Username",
  HTBAPLOGINDESCRIPTION3: "3. Type the Password",
  HTBAPLOGINDESCRIPTION4: "4. Click on Login",
  HTBAPDASHBOARDHEADER: "Partner Dashboard",
  HTBAPDASHBOARDDESCRIPTION:
    "After completing the Registration and Login process you will redirect to the dashboard, Now you can apply the projects and view the projects.",

  GAPINTRODUCTIONHEADER: "How to get a Project?",
  GAPLOGINHEADER: "Login",
  GAPLOGINDESCRIPTION: "Login as a Partner.",
  GAPANNOUNCEMENTHEADER: "Announcement",
  GAPANNOUNCEMENTDESCRIPTION1:
    "1.On Mobile,Click on Announcement text, you will be redirected to the Project page",
  GAPANNOUNCEMENTDESCRIPTION2:
    "2.On Computer, check the footer and click on Announcement",
  GAPPROJECTHEADER: "Project",
  GAPPROJECTDESCRIPTION1: "1. Check the Projects and read about them.",
  GAPPROJECTDESCRIPTION2:
    "2. After understanding the project, you can submit the Proposal.",
  GAPPROJECTDESCRIPTION3:
    "3. When a proposal gets submitted successfully, you can move to the Project-list page from where you can apply for the projects and can complete rest of the process.",
  GAPREGISTRATIONFORMHEADER: "Registration Form",
  GAPREGISTRATIONFORMDESCRIPTION:
    "You can apply for the project by going through this page.",

  PROPOSALINTRODUCTIONHEADER: "How to Submit a proposal?",
  PROPOSALBUILDINGHEADER: "Build a Proposal",
  PROPOSALBUILDINGDESCRIPTION1:
    "1. After clicking on Apply for Project, you can build a proposal.",
  PROPOSALBUILDINGDESCRIPTION2:
    "2. Don't forget to accept the terms and conditions.",
  PROPOSALBUILDINGDESCRIPTION3:
    "3. After completing the proposal details, submit the proposal.",
  PROPOSALSUBMISSIONHEADER: "Successful submission of Proposal",
  PROPOSALSUBMISSIONDESCRIPTION:
    "After submitting the proposal, you will redirect again to the project-list page, where you can check your already applied projects.",
  PROPOSALMYPROPOSALHEADER: "My Proposal",
  PROPOSALMYPROPOSALDESCRIPTION:
    "Click on Proposal and select My Proposal, where you can see all your applied Projects or the status of Your projects.",

  ADDPRODUCTHEADER: "How to Add Products on ConQt?",
};
module.exports = {
  ConqtUniversityContent,
};
