import React from "react";
import "./TermsAndConditionsOld.css";
import HomePageLayout from "../../../Layouts/HomePageLayout";
import { ConqtTacContent } from "../../../Content/ConqtTermsAndConditionsContent";
import { Helmet } from "react-helmet";

const ConqtTermsAndConditions = () => {
  return (
    <>
      <HomePageLayout>
        <Helmet>
          <title>{"CONQT TERMS AND CONDITIONS - ConQT"}</title>
        </Helmet>
        <div className="container mt-5 mb-5 conqt-tac-container">
          <h3 className="conqt-tac-title">{ConqtTacContent.CTACINTROTITLE}</h3>
          <hr className="conqt-tac-hr-line"></hr>
          <h3 className="conqt-tac-heading">
            {ConqtTacContent.CTACINTROHEADING}
          </h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACINTROPARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACINTROPARA2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACINTROPARA3}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT1H1}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT1PARA1}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT2H1}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT2PARA1}</p>
          <p className="conqt-tac-list-item">
            {ConqtTacContent.CTACPOINT2LIST1}
          </p>
          <p className="conqt-tac-list-item">
            {ConqtTacContent.CTACPOINT2LIST2}
          </p>
          <p className="conqt-tac-list-item">
            {ConqtTacContent.CTACPOINT2LIST3}
          </p>
          <p className="conqt-tac-list-item">
            {ConqtTacContent.CTACPOINT2LIST4}
          </p>
          <p className="conqt-tac-list-item">
            {ConqtTacContent.CTACPOINT2LIST5}
          </p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT3H1}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT3PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT3PARA2}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT4H1}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT4PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT4PARA2}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT5H1}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT5PARA1}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT6H1}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT6PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT6PARA2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT6PARA3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT6PARA4}</p>
          <h3 className="conqt-tac-heading">
            {ConqtTacContent.CTACTRADEMARKH}
          </h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACTRADEMARKPARA}</p>
          <h3 className="conqt-tac-heading">
            {ConqtTacContent.CTACCOPYRIGHTH}
          </h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACCOPYRIGHTPARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACCOPYRIGHTPARA2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACCOPYRIGHTPARA3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACCOPYRIGHTPARA4}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACURLH}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACURLPARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACURLPARA2}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT7H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT7PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT7PARA2}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT8H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT8PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT8LIST2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT8LIST3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT8LIST4}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT8LIST5}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT8LIST6}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT8LIST7}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT8PARA2}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT9H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT9PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT9PARA2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT9PARA3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT9PARA4}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT9PARA5}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT10H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT10PARA1}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT11H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11PARA2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11PARA3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST01}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST02}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11PARA4}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11PARA5}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11PARA6}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST4}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST5}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST6}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST7}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST8}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST9}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST10}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST11}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST12}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST13}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST14}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11LIST15}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11PARA7}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11PARA8}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT11PARA9}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT12H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT12PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT12PARA2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT12LIST1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT12LIST2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT12LIST3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT12PARA3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT12PARA4}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT13H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT13PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT13PARA2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT13PARA3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT13PARA4}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT13LIST1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT13LIST2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT13LIST3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT13LIST4}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT13LIST5}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT13LIST6}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT14H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT14PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT14PARA2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT14PARA3}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT15H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT15PARA1}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT16H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT16LIST1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT16LIST2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT16LIST3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT16LIST4}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT16LIST5}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT16LIST6}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT16LIST7}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT17H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT17LIST1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT17LIST2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT17LIST3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT17LIST4}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT17LIST5}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT17LIST6}</p>
          <h3 className="conqt-tac-heading">{ConqtTacContent.CTACPOINT18H}</h3>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT18PARA1}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT18PARA2}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT18PARA3}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT18PARA4}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT18PARA5}</p>
          <p className="conqt-tac-para">{ConqtTacContent.CTACPOINT18PARA6}</p>
        </div>
      </HomePageLayout>
    </>
  );
};
export default ConqtTermsAndConditions;
