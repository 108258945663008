import React, { useState } from "react";
import { useSelector } from "react-redux";
import share from "../../../Assets/Images/share.png";
import cart from "../../../Assets/Images/cart.png";
import verified from "../../../Assets/Images/verified.png";
import callnow from "../../../Assets/Svg/callnowsp.svg";
import arrow from "../../../Assets/Svg/g-arrow.svg";
import gsafe from "../../../Assets/Images/gsafe.png";
import bestprice from "../../../Assets/Images/bestprice.png";
import callexpert from "../../../Assets/Images/callexpert.png";
import "./SingleProductTopContent.css";
import Rate from "rc-rate";
import parse from "html-react-parser";
import ProductEnquiryPopup from "../../../Models/ProductEnquiryPopup/ProductEnquiryPopup";
import { RWebShare } from "react-web-share";
import HomePagePopup from "../../HomePage/Popup/HomePagePopup";
import ReactModal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const SingleProductTopContent = () => {
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);
  const productDetails = SingleProduct.productDetail;
  const navigate = useNavigate();

  // console.log(SingleProduct, "Single Product details");
  const [popup, setPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const priceType = SingleProduct.productDetail.is_price;
  {
    priceType === "Price"
      ? console.log("price is there")
      : priceType === "Quote"
      ? console.log("Price not Availble")
      : console.log("Package  Availble");
  }
  // console.log(priceType, "price type");

  const onClickCallNow = () => {
    setPopup(!popup);
    // console.log("popup clicked");
  };

  const onRequestClose = (close) => {
    setIsOpen(close);
  };

  function getRandomItem(arr) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const item = arr[randomIndex];
    return item;
  }
  const array = [4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9];
  const result = getRandomItem(array);

  const onClickReview = () => {
    const loginType = localStorage.getItem("logintype");
    if (loginType == 1) {
      navigate("/write-a-review");
      localStorage.setItem("productName", productDetails.product_name);
      localStorage.setItem("productId", productDetails.product_id);
    } else if (loginType == 2) {
      toast.error("Only registered customers can give feedback");
    } else {
      navigate("/login");
    }
  };
  return (
    <div>
      {SingleProduct && (
        <>
          <div className="sp-title-icons">
            <h2>{SingleProduct.productDetail.product_name}</h2>
            <div>
              {/* <img src={cart} alt="" /> &nbsp; */}
              <RWebShare
                data={{
                  url: window.location.href,
                }}
                onClick={() => console.info("share successful!")}
              >
                <button className="sp-share-btn">
                  {" "}
                  <img src={share} alt="" />
                </button>
              </RWebShare>
            </div>
          </div>
          <div className="sp-brand-div">
            <p className="sp-brand-by">
              by: &nbsp;
              <span className="sp-brand-name">
                {SingleProduct.productDetail.brand_name}
              </span>
            </p>
            <div>
              <img src={verified} alt="" />
            </div>
          </div>
          <div className="d-flex sp-rate-review">
            <div>
            <Rate
              value={
                SingleProduct.productDetail.avg_rating > 0
                  ? SingleProduct.productDetail.avg_rating
                  : result
              }
              style={{ fontSize: 15 }}
              allowHalf
              allowClear={true}
              disabled={true}
            />
            <span className="">
              {" "}
              {SingleProduct.productDetail.avg_rating > 0
                ? SingleProduct.productDetail.avg_rating
                : result}{" "}
              Rating
            </span>
            </div>
            <span className="sp-review-question sp-reviews-top">
              {SingleProduct.productReviews.length} Reviews
            </span>
            <p className="sp-review-question" onClick={onClickReview}>
              Write a Review
            </p>
            {/* <p className="sp-review-question">Have Questions?</p> */}
          </div>
          <div className="sp-price-call-now">
            <div>
              <p className="sp-price d-flex">
                <span className="sp-disc">
                  {" "}
                  {SingleProduct.productDetail.discount &&
                  +SingleProduct.productDetail.discount > 0 &&
                  priceType === "Price"
                    ? "-" + SingleProduct.productDetail.discount + "%"
                    : ""}
                </span>
                {SingleProduct.productDetail.discount &&
                priceType === "Price" ? (
                  <div
                    className={
                      +SingleProduct.productDetail.discount > 0 ? "" : "pt-4"
                    }
                  >
                    {" "}
                    {"₹" + " " + SingleProduct.productDetail.price}
                  </div>
                ) : priceType === "Quote" ? (
                  <div className=""> Request For Quote </div>
                ) : priceType === "Package" ? (
                  <div className="d-flex">
                    <span className="sp-disc">
                      {" "}
                      {SingleProduct.productPackages[0] &&
                      SingleProduct.productPackages[0].discount &&
                      +SingleProduct.productPackages[0].discount > 0 &&
                      priceType === "Package"
                        ? "-" + SingleProduct.productPackages[0].discount + "%"
                        : ""}
                    </span>{" "}
                    &nbsp;
                    <div
                      className={
                        SingleProduct.productPackages[0] &&
                        SingleProduct.productPackages[0].discount &&
                        +SingleProduct.productPackages[0].discount > 0
                          ? ""
                          : "pt-4"
                      }
                    >
                      {" "}
                      {"₹" + " " + SingleProduct.productPackages[0].price}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <span className="sp-price-time pt-1">
                  {priceType === "Package" &&
                    "/" + SingleProduct.productPackages[0].price_type}
                </span>
              </p>

              {SingleProduct.productPackages[0] &&
                SingleProduct.productPackages[0].discount &&
                +SingleProduct.productPackages[0].discount > 0 && (
                  <p className="sp-mrp">
                    M.R.P.:{" "}
                    <s className="sp-mrp-price">
                      {priceType === "Package"
                        ? "₹" +
                          " " +
                          SingleProduct.productPackages[0]
                            .price_without_discount
                        : "Price not Available"}
                    </s>
                  </p>
                )}

              {+SingleProduct.productDetail.discount > 0 && (
                <p className="sp-mrp">
                  M.R.P.:{" "}
                  <s className="sp-mrp-price">
                    {priceType === "Price"
                      ? "₹" +
                        " " +
                        SingleProduct.productDetail.price_without_discount
                      : "Price not Availble"}
                  </s>
                </p>
              )}
              {priceType != "Quote" && (
                <p className="sp-inclusive">Inclusive of all taxes</p>
              )}
            </div>
            <div className="sp-call-now" onClick={() => setIsOpen(true)}>
              <img src={callnow} alt="" />
            </div>
            <ReactModal
              isOpen={isOpen}
              contentLabel="Example Modal"
              onRequestClose={() => setIsOpen(false)}
            >
              <HomePagePopup close={onRequestClose} />
            </ReactModal>
          </div>
          <p className="sp-desc">
            {parse(
              SingleProduct.productDetail.product_description
                .substring(0, 150)
                .replace(/^(.{145}[^\s]*).*/, "$1")
            )}
          </p>
          <div className="d-flex align-items-center">
            {SingleProduct.productDetail.parent_id === 1 && (
              <div className="sp-free-demo-div" onClick={onClickCallNow}>
                <button className="sp-free-demo-btn">Free Demo</button>
                <p className="sp-get-btn">Get started </p>
                <img src={arrow} alt="" />{" "}
              </div>
            )}
            <div
              style={{ paddingTop: "10px", paddingLeft: "20px" }}
              onClick={onClickCallNow}
            >
              <button className="buy-now-btn">Request Quotation</button>
            </div>
          </div>

          <div className="d-flex pt-5 sp-advice">
            <div className="d-flex align-items-center">
              <div>
                <img src={callexpert} alt="" style={{ width: "40px" }} />
              </div>
              <div className="px-2 pt-3">
                <p className="sp-pro-text">
                  Get Instant Expert <br /> Advice
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={gsafe} alt="" style={{ width: "40px" }} />
              </div>
              <div className="px-2 pt-3">
                <p className="sp-pro-text">
                  GSafe & Secure <br /> Payment
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>
                <img src={bestprice} alt="" style={{ width: "40px" }} />
              </div>
              <div className="px-2 pt-3">
                <p className="sp-pro-text">
                  Assured Best Price <br /> Guaranteed
                </p>
              </div>
            </div>
          </div>
        </>
      )}
      {popup && <ProductEnquiryPopup closeModal={setPopup} />}
    </div>
  );
};

export default SingleProductTopContent;
