
const ImageContent = {
    HEADER: "Microsoft Office Solutions 365",
    AUTHOR: "By : Microsoft Office ",
    CONTENT: "Lorem ipsum dolor sit amet,consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna,Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna,Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna,Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna "
}
const PricingContent = {
    HEADER : "Choose a plan that’s right for you"
}

module.exports = {
    ImageContent,
    PricingContent
}