import React from "react";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import { CompareProductsContent } from "../../../Content/CompareProducts.content";

const ProductCardInSimilarProducts = ({ image, name,rating }) => {
  // const array = [4.1, 4.2, 4.3, 4.4, 4.5, 4.6, 4.7, 4.8, 4.9];
  // const getRandomRating = (array) => {
  //   const randomIndex = Math.floor(Math.random() * array.length);
  //   return array[randomIndex];
  // };
  // const fakeRate = getRandomRating(array);

  // const rating = 4;


  return (
    <>
      <div className="individual-product-card-sp-1">
        <div className="product-image-sp">
          <img className="sp-image" src={image} alt="" />
        </div>
        <div className="product-details-sp">
          <h6 className="product-name-sp">{name}</h6>

          <div style={{ marginTop: "-25px" }}>
            <Rate
              // defaultValue={rating > 0 ? rating : fakeRate}
              value={rating}
              style={{ fontSize: 40 }}
              allowHalf
              allowClear={true}
              disabled={true}
            />
          </div>

          <div className="buttons-sp-container">
            <button className="button-sp-product button-1">
              {CompareProductsContent.PRODUCTDETAILS1}
            </button>
            <button className="button-sp-product button-2">
              {CompareProductsContent.PRODUCTDETAILS2}
            </button>
            <button className="button-sp-product button-3">
              {CompareProductsContent.PRODUCTDETAILS3}
            </button>
          </div>
        </div>
      </div>
    </>

  );
};

export default ProductCardInSimilarProducts;
