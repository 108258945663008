import React from "react";
import "./topCompo.css";
import LeftPatch from "../../../Assets/Images/ads-left-patch.png";
import RightPatch from "../../../Assets/Images/ads-right-patch.png";
import Location from "../../../Assets/Svg/location.svg";
import MailIcon from "../../../Assets/Svg/MailG.svg";
import Headset from "../../../Assets/Svg/headset.svg";
import ContactCard from "./ContactCard";
import call from "../../../Assets/Svg/callCon.svg";
import watsapp from "../../../Assets/Svg/WhatsApp.svg";

const ContactTop = () => {
  return (
    <>
      <section>
        <div className="contact-banner-main">
          <div className="ads-patches">
            <img className="ads-left-patch" src={LeftPatch} alt=""></img>
            <img className="ads-right-patch" src={RightPatch} alt=""></img>
          </div>
          <div className="container" style={{ marginTop: "-240px" }}>
            <div className="d-flex align-items-center justify-content-between contact-top-cards">
              <div className="col-12 col-lg-4 top-card-1">
                <ContactCard
                  image={Location}
                  title={"Visit Location"}
                  content={
                    <p
                      className=""
                      style={{ color: "white", lineHeight: "30px" }}
                    >
                      ConQt Technologies Pte. Ltd. <br /> 51 Goldhill Plaza{" "}
                      <br /> #07-07 Singapore 308900 <br />
                    </p>
                  }
                  size={"40px"}
                />
              </div>

              <div className="col-12 col-lg-4 top-card-2">
                <ContactCard
                  image={Headset}
                  title={"Quick Call Us"}
                  size={"40px"}
                />
                <div className="d-flex align-items-center justify-content-center">
                  <div>
                    <img src={call} alt="" />
                  </div>
                  <div className="px-3 d-flex flex-column align-items-start justify-content-start">
                    <div className="d-flex align-items-center">
                      {" "}
                      <p className="con-country">Singapore </p>
                      <span style={{ marginTop: "-50px" }}>
                        <img src={watsapp} alt="" />
                      </span>
                    </div>
                    <div className="con-number">+65 85 496 022</div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center py-2">
                  <div>
                    <img src={call} alt="" style={{ marginRight: "4px" }} />
                  </div>
                  <div className="d-flex flex-column align-items-start px-3">
                    <div className="con-country2">Thailand</div>
                    <div className="con-number2">+66 98 865 6621</div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <div>
                    <img src={call} alt="" style={{ marginRight: "4px" }} />
                  </div>
                  <div className="d-flex flex-column align-items-start px-3">
                    <div className="con-country2">India</div>
                    <div className="con-number2">+91 913 686 0189</div>
                    <div className="con-number2">+91 913 685 0189</div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-4 top-card-3">
                <ContactCard
                  image={MailIcon}
                  title={"Mail Us On"}
                  content={"info@conqt.com"}
                  size={"40px"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactTop;
