import React, { useEffect } from "react";
import "../../Styles/Styles.css";
import "./TrustedBy.css";
import { Swiper, SwiperSlide } from "swiper/react";
import  { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import "swiper/swiper.min.css";


const TrustedBy = () => {
  function importsAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
  }
  
  const images = importsAll(require.context('../../Assets/Images/NewClients', false, /\.(png)$/));
  return (
    <div className="trusted-by-main">
      <div className="trusted-by-header-div">
        <div className="d-none d-md-block">
          <h1 className="trusted-by-header vl">Trusted by</h1>
        </div>
        <div className="d-block d-sm-none">
          <h1 className="res-sm-trustedby">TRUSTED BY</h1>
        </div>
      </div>
      <div className="trusted-by-swiper py-2">
        <Swiper
          //   navigation={true}
          slidesPerView={4}
          spaceBetween={10}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            240: {
              slidesPerView: 1.5,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {/* <SwiperSlide>
            <div className="sliders">
              <img  className="techcurve-img" src={Techcurve} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="sliders">
              <img  style={{width:"140px"}} src={Seeq} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="sliders">
              <img  style={{width:"140px"}} src={Msc} alt=""></img>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="sliders">
              <img  style={{width:"140px"}} src={Tridiagonal} alt=""></img>
            </div>
          </SwiperSlide> */}
          {Object.values(images).map((item) => {
            return(
            <SwiperSlide>
            <div className="sliders">
              <img style={{width:"300px"}} src={item} alt=""></img>
            </div>
          </SwiperSlide>)
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default TrustedBy;
