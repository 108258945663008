import React, { useState, useEffect } from "react";
import Select from "react-select";
import "./dropdown.css";
import { useSelector, useDispatch } from "react-redux";
import { getAllProductList } from "../../../Store/Reducer/AllProductsList";

const Dropdown = (props) => {
  const dispatch = useDispatch();
  const { productList } = useSelector((state) => state.allProductsList);
  // console.log(productList, "All Products list");
  const productName = localStorage.getItem("productName");
  const productId = localStorage.getItem("productId");
  console.log(productName, productId, "Product Name");
  const [selectedOption, setSelectedOption] = useState([
    { label: productName, value: +productId },
  ]);

  useEffect(() => {
    props.onIdChange(selectedOption.length > 0 && selectedOption[0].value);
  }, []);

  // console.log(selectedOption.length , selectedOption[0].value);
  // console.log(selectedOption, "setSelectedOption");
  const options = productList.map((option) => ({
    label: option.product_name,
    value: option.id,
  }));
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    props.onIdChange(selectedOption.value);
  };

  // console.log(options, "options");
  // console.log(`Option selected:`, selectedOption);

  useEffect(() => {
    dispatch(getAllProductList());
  }, []);

  return (
    <>
      <div style={{ padding: "15px 0px" }}>
        <p className="wr-sel-top">Select Product to Add Review</p>
      </div>
      <div className="">
        <Select
          defaultValue={selectedOption}
          onChange={handleSelectChange}
          options={options}
        />
        {props.errors && <span className="error-msg">{props.errors}</span>}
      </div>
    </>
  );
};

export default Dropdown;
