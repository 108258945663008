import React from "react";
import "./CompareProductsHeader.css";
import { CompareProductsContent } from "../../../Content/CompareProducts.content";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import homeicon from "../../../Assets/Images/home-cp.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import logo1 from "../../../Assets/Images/cp-logo-1.png";
import logo2 from "../../../Assets/Images/cp-logo-2.png";
import logo3 from "../../../Assets/Images/cp-logo-3.png";
import logo4 from "../../../Assets/Images/cp-logo-4.png";
import logo5 from "../../../Assets/Images/cp-logo-5.png";
import logo6 from "../../../Assets/Images/cp-logo-6.png";
import logo7 from "../../../Assets/Images/cp-logo-7.png";
import logo8 from "../../../Assets/Images/cp-logo-8.png";
import SingleCategorySwiper from "../../SingleIndusty/SingleSwiper";
import { updatedCurrentSelectedProduct1, updatedCurrentSelectedProduct2, updatedCurrentSelectedProduct3 } from "../../../Store/Reducer/AllProductsReducer";

const CompareProductsHeader = () => {

  
  return (
    <>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="#">
            <img className="homeicon" src={homeicon} />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#">Compare Product</Breadcrumb.Item>
        </Breadcrumb>
        <div className="cp-header" >
          <h1 className="cp-heading">
            {CompareProductsContent.COMPAREPRODUCTSHEADER}
          </h1>
          {/* <div className="cp-line"></div> */}
          <div className="d-flex align-items-center justify-content-center" style={{ marginTop: "-32px" }}>
            <hr className="cp-line" />
          </div>
          <div className="d-flex  align-items-center justify-content-center">
            <p className="cp-description">
              {CompareProductsContent.COMPAREPRODUCTDESCRIPTION}
            </p>
          </div>

        </div>
        <div className="container cp-page-swiper " >
          <Swiper
            // navigation={true}
            slidesPerView={8}
            spaceBetween={10}
            // pagination={{
            //   clickable: true,
            // }}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              240: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 6,
                spaceBetween: 10,
              },
              576: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            modules={[Autoplay]}
            className="cp-page-Swiper-logo"
          >
            <SwiperSlide>
              <div className="cp-page-slider-logo-div">
                <img className="cp-page-slider-logo" src={logo1} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cp-page-slider-logo-div">
                <img className="cp-page-slider-logo" src={logo2} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cp-page-slider-logo-div">
                <img className="cp-page-slider-logo" src={logo3} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cp-page-slider-logo-div">
                <img className="cp-page-slider-logo" src={logo4} alt=""></img>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="cp-page-slider-logo-div">
                <img className="cp-page-slider-logo" src={logo5} alt=""></img>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="cp-page-slider-logo-div">
                <img className="cp-page-slider-logo" src={logo6} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cp-page-slider-logo-div">
                <img className="cp-page-slider-logo" src={logo7} alt=""></img>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="cp-page-slider-logo-div">
                <img className="cp-page-slider-logo" src={logo8} alt=""></img>
              </div>
            </SwiperSlide>

          </Swiper>
        </div>

        {/* <div>
          <SingleCategorySwiper />
        </div> */}
      </div>
    </>
  );
};

export default CompareProductsHeader;
