const SellYourSolutionContent = {
  BANNERHEADING1: "AI Driven B2B Business",
  BANNERHEADING2: "Matching Platform",
  BANNERSUBHEADING: "Join as a Vendor  on ConQt",
  BANNERBUTTON: "Start Selling",
  BANNERTAGLINE1: "Sell your solutions worldwide easily.",
  BANNERTAGLINE2: "T&C Apply*",
};
const HowToSellOnConqtContent = {
  HTSOCHEADING: "  How to Sell on ConQt ?",
  HTSOCBECOMEAPARTNERTITLE: "Become a Partner",
  HTSOCBECOMEAPARTNERTEXT:
    "Join conqt.com as a partner and grow your business with our innovative marketplace. Boost your sales and reach new customers today!",
  HTSOCGETPRJCTTITLE: "Get Project ?",
  HTSOCGETPRJCTTEXT:
    "Conqt.com is a platform where vendors can get qualified opportunities. If you're a vendor looking for new projects, conqt.com is worth checking out.",
  HTSOCSUBMITPROPOSALTITLE: "Submit a Proposal",
  HTSOCSUBMITPROPOSALTEXT:
    "Submit a proposal on conqt.com to apply for projects as a vendor. It's a simple, streamlined process.",
  HTSOCSELLONCONQTTITLE: "Sell on ConQt",
  HTSOCSELLONCONQTTEXT:
    "As a vendor, easily add your products to conqt.com to reach a wider audience and increase your sales.",
};
const FeedbackSliderContent = {
  FEEDBACKSLIDERHEADING1: "My experience was too good",
  FEEDBACKSLIDERPARA11: "ConQT has saved us so much time and money.",
  FEEDBACKSLIDERPARA12:
    "The variety of products and services available is impressive and the ability to compare prices and negotiate deals with multiple vendors is a game changer",
  FEEDBACKSLIDERUSERNAME1: "Aparna",
  FEEDBACKSLIDERUSERLOCATION1: "India",

  FEEDBACKSLIDERHEADING2: "My experience was too good",
  FEEDBACKSLIDERPARA21:
    "As a small business owner, I appreciate the convenience and personal support provided by this IT marketplace.",
  FEEDBACKSLIDERPARA22:
    "They take the time to understand our specific needs and always provide options that fit our budget.",
  FEEDBACKSLIDERUSERNAME2: "Anjali Tiwari",
  FEEDBACKSLIDERUSERLOCATION2: "India",

  FEEDBACKSLIDERHEADING3: "My experience was too good",
  FEEDBACKSLIDERPARA31:
    "I recently started using ConQT for my IT needs and I am thoroughly impressed with the platform.",
  FEEDBACKSLIDERPARA32:
    "The marketplace offers a wide range of products and services from reputable suppliers, making it easy for me to find exactly what I need at competitive prices.",
  FEEDBACKSLIDERUSERNAME3: "Jaya Sethi",
  FEEDBACKSLIDERUSERLOCATION3: "Thailand",
};
const StartSellingOnConqtContent = {
  STARTSELLINGBUTTONTEXT: "Start Selling On ConQt today it self",
};

module.exports = {
  SellYourSolutionContent,
  HowToSellOnConqtContent,
  StartSellingOnConqtContent,
  FeedbackSliderContent,
};
