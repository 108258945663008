import React, { useEffect } from "react";
import "./writeareview.css";
import HomePageLayout from "../../Layouts/HomePageLayout";
import Dropdown from "../../Components/WriteAReview/Dropdown";
import Rating from "../../Components/WriteAReview/Rating";
import Home from "../../Assets/Svg/BCHome.svg";
import { getAllProductList } from "../../Store/Reducer/AllProductsList";
import { useSelector, useDispatch } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";

const WriteAReview = () => {
  const dispatch = useDispatch();
  const { productList, loading } = useSelector(
    (state) => state.allProductsList
  );
  useEffect(() => {
    dispatch(getAllProductList());
  }, []);
  return (
    <>
      <HomePageLayout>
        <center>{loading && <FadeLoader color="#36d7b7" />}</center>

        <div
          className="container d-flex align-items-center"
          style={{ padding: "25px 0px 10px 10px" }}
        >
          <img src={Home} alt="" />{" "}
          <span style={{ color: "#C4C4C4" }} className="px-2">
            {">"}
          </span>
          <h4 className="wr-rev-top-bc">Write a Review</h4>
        </div>
        <div className="container mbl-wr-rev">
          <Rating />
        </div>
      </HomePageLayout>
    </>
  );
};

export default WriteAReview;
