import React, { useDebugValue } from "react";
import "./smcard.css";
import IncreseIcon from "../../../../Assets/Svg/Increase.svg";
import { PRODUCT_DETAILS } from "../../../../ApiEndPoints";
import { BaseUrl } from "../../../../ApiBaseUrl/Api";
import axios from "axios";
import { updatedSingleProductsData } from "../../../../Store/Reducer/SingleProductPageReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const RightSmallCard = ({ image, count, content, header, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickNewArrivals = () => {
    console.log(id, "onClickNewArrivals");
    axios
      .get(`${BaseUrl}${PRODUCT_DETAILS}${id}`)
      .then((response) => {
        dispatch(updatedSingleProductsData(response.data.data));
        navigate(
          "/" + response.data.data.productDetail.product_name.replace(/ /g, "-")
        );
        window.scrollTo(0, 0);
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div
        className="single-cat-right-card my-3"
        style={{ cursor: "pointer" }}
        onClick={onClickNewArrivals}
      >
        <div>
          <img src={image} alt="" style={{ width: "80px" }} />
        </div>
        <div className="product-card-right">
          <h5>{header}</h5>
          <div className="d-flex">
            {/* <img src={IncreseIcon} alt="" className='px-2' /> */}
            <h3 className="pt-2">{count}</h3>
          </div>
          <p>{content}</p>
        </div>
      </div>
    </>
  );
};

export default RightSmallCard;
