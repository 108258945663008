import React from 'react';
import './productImage.css'

const ProductImage = ({pName, image}) => {
  return (
    <>
         <section className='mbl-view-compare-product-border py-3 my-3' >
                <div className='container'>
                    <div className='price-top-name'>
                        <p>{pName}</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-center py-3'>
                        <img src={image} alt="" style={{width:"150px"}}/>
                    </div>
                </div>
            </section>
    </>
  )
}

export default ProductImage