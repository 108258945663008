const TwoProductsComparisonContent = {
    TPCHEADING: "Compare Software for your Business",
    TPCDESCRIPTION: "Detailed comparison between software for all business types based on features, specifications, reviews and more. Explore some of our most viewed software comparisons for trending products to find out what’s best for you.",

    FAQHEADING1: "FAQ",
    FAQHEADING2: "Frequently Asked Questions.",
    TALKTOUSBUTTON: "Talk to us",

    TPCFAQQ1: "Lorem ipsum dolor sit amet?",
    TPCFAQQ2: "Lorem ipsum dolor sit amet, consectetur?",
    TPCFAQQ3: "consectetur Lorem ipsum dolor sit amet ?",
    TPCFAQQ4: "dolor Lorem ipsum dolor sit amet, consectetur?",
    TPCFAQQ5: "Lorem ipsum dolor  consectetur?",
    TPCFAQA1: "A. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor",
    TPCFAQA2: "A. ",
    TPCFAQA3: "A. ",
    TPCFAQA4: "A. ",
    TPCFAQA5: "A. ",
}
module.exports ={
    TwoProductsComparisonContent,
}