import React, { useState } from 'react';
import './priceCompare.css'
import ProductEnquiryPopup from '../../../../Models/ProductEnquiryPopup/ProductEnquiryPopup';

const PriceCompare = ({ pName, price }) => {

    const [popup, setPopup] = useState(false)

    const onClickCallNow = () => {
        setPopup(!popup);
        // console.log("popup clicked");
      };
    return (
        <>
            {popup && <ProductEnquiryPopup closeModal={setPopup} />}
            <section className='mbl-view-compare-product-border py-3 my-3' >
                <div className='container'>
                    <div className='price-top-name'>
                        <p>{pName}</p>
                    </div>
                    {price ?
                        (<div>
                            {price}
                        </div>) :
                        (
                            <div className='d-flex align-items-center justify-content-between py-3'>
                                <div>
                                    <p>Price on Request</p>
                                </div>
                                <div>
                                    <button className='price-on-req-btn-mbl' onClick={onClickCallNow}>Get Pricing</button>
                                </div>
                            </div>

                        )}
                </div>
            </section>
        </>
    )
}

export default PriceCompare