import React from 'react';
import './compareDepartment.css'

const CompareDepartment = ({pName, content}) => {
    return (
        <>
            <section className='mbl-view-compare-product-border py-3 my-3' >
                <div className='container'>
                    <div className='price-top-name'>
                        <p>{pName}</p>
                    </div>
                    <div className='pt-3'>
                       {content}
                    </div>
                </div>
            </section>
        </>
    )
}

export default CompareDepartment