import React from 'react';
import "./topImageCompare.css"

const TopImageComapreMobile = ({image, pName, pBrandName}) => {
  return (
    <>
        <section className='mbl-view-compare-product'>
            <div>
                <img src={image} alt="" style={{width:"125px"}}/>
            </div>
            <div>
                <h4>{pName}</h4>
                <h6>{pBrandName}</h6>
            </div>
        </section>
    </>
  )
}

export default TopImageComapreMobile