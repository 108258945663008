import React, { useState } from "react";
import "./CallExpert.css";
import { CompareProductsContent } from "../../../Content/CompareProducts.content";
import leftpatch from "../../../Assets/Images/ads-left-patch.png";
import rightpatch from "../../../Assets/Images/ads-right-patch.png";
import callexpert from "../../../Assets/Images/call-expert.png";
import callexpertmainimage from "../../../Assets/Images/call-expert-image.png";
import HomePagePopup from "../../HomePage/Popup/HomePagePopup";
import ReactModal from "react-modal";

const CallExpert = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [popup, setPopup] = useState(false);

  const onClickSignIcon = () => {
    setPopup(true);
  };

  const onRequestClose = (close) => {
    setIsOpen(close);
  };

  const handleOpen = () => {
    console.log('clikced')
    setIsOpen(true);
  }



  return (
    <>
      <div className="callexpert">
        {/* <div className="patches">
          <img className="leftpatch-ce" src={leftpatch} />
          <img className="rightpatch-ce" src={rightpatch} />
        </div> */}

        <div className="call-expert-compare-product">
          <div className=" col-12 col-lg-6 d-flex align-items-center flex-column justify-content-center call-expert-image-compare-product">
            <h5 className="ce-heading">
              {CompareProductsContent.CEHEADER}
            </h5>
            <div className="ce-button-details" >
              <button className="button-ce" onClick={handleOpen}>
                <img className="image-ce" src={callexpert} />
                <span className="button-content">{CompareProductsContent.CEBUTTONCONTENT}</span>
              </button>
              <ReactModal
                isOpen={isOpen}
                contentLabel="Example Modal"
                onRequestClose={() => setIsOpen(false)}
              >
                <HomePagePopup close={onRequestClose} />
              </ReactModal>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <img className="call-expert-image" src={callexpertmainimage} />
          </div>
        </div>

      </div>
    </>
  );
};

export default CallExpert;
