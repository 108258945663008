const ComparisonBetweenProductsContent = {
    TPCHEADING: "Compare Software for your Business",
    TPCDESCRIPTION: "Detailed comparison between software for all business types based on features, specifications, reviews and more. Explore some of our most viewed software comparisons for trending products to find out what’s best for you.",

    FAQHEADING1: "FAQ",
    FAQHEADING2: "Frequently Asked Questions.",
    TALKTOUSBUTTON: "Talk to us",

    TPCFAQQ1: "Lorem ipsum dolor sit amet?",
    TPCFAQQ2: "Lorem ipsum dolor sit amet, consectetur?",
    TPCFAQQ3: "consectetur Lorem ipsum dolor sit amet ?",
    TPCFAQQ4: "dolor Lorem ipsum dolor sit amet, consectetur?",
    TPCFAQQ5: "Lorem ipsum dolor  consectetur?",
    TPCFAQA1: "A. Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor",
    TPCFAQA2: "A. ",
    TPCFAQA3: "A. ",
    TPCFAQA4: "A. ",
    TPCFAQA5: "A. ",


    THREEPCTAC1: "Comparison Parameters",
    THREEPCTAC2PNAME: "Salesforce CRM",
    THREEPCTAC2BNAME: "Salesforce",

    THREEPCTAC3PNAME: "Leadsquared CRM",
    THREEPCTAC3BNAME: "Leadsquared",

    THREEPCTAC4PNAME: "Deskera CRM",
    THREEPCTAC4BNAME: "Deskera",

    THREEPCPARA1: "PRICE",
    THREEPCPARA2: "INDUSTRIES",
    THREEPCPARA3: "DEPARTMENT",
    THREEPCPARA4: "FEATURES",
    THREEPCPARA5: "REVIEWS",
    THREEPCPARA6: "PRODUCT IMAGES",
    THREEPCPARA7: "PRODUCT VIDEO",
    THREEPCPARA8: "PLATFORMS SUPPORTED",

    THREEPCPRICET1: "Price on Request",
    THREEPCPRICEBT: "Request",

    THREEPCPRICEC3T1: "Starting from",
    THREEPCPRICEC3T2: "Rs. 17,700",
    THREEPCPRICEC3T3: "View all plans",

    THREEPCINDUC2T1: "All Industries",
    THREEPCINDUC3T1: "Education",
    THREEPCINDUC3T2: "Healthcare",
    THREEPCINDUC3T3: "Automobiles",
    THREEPCINDUC3T4: "Real Estate",
    THREEPCINDUC3T5: "Tours and Travels",
    THREEPCINDUC3T6: "Insurance",
    THREEPCINDUC3T7: "Financial Institutions",
    THREEPCINDUC3T8: "Banking",
    
    THREEPCFEATURESVA: "View ALL FEATURES",
};


module.exports ={
    ComparisonBetweenProductsContent,

};