import React from "react";
import "./RefundPolicy.css";
import HomePageLayout from "../../Layouts/HomePageLayout";
import { RefundPolicyContent } from "../../Content/RefundContent";
import { Helmet } from "react-helmet";

const RefundPolicy = () => {
  return (
    <>
      <HomePageLayout>
        <Helmet>
          <title>{"REFUND - ConQT"}</title>
        </Helmet>
        <div className="container refund-policy-container mt-5 mb-5">
          <h2 className="refund-policy-heading">
            {RefundPolicyContent.REPOHEADING1}
          </h2>
          <hr className="refund-policy-hr-line"></hr>
          <h3 className="refund-policy-title">
            {RefundPolicyContent.REPOSUBHEADING1}
          </h3>
          <p className="refund-policy-para">{RefundPolicyContent.REPOPARA1}</p>
          <p className="refund-policy-para">{RefundPolicyContent.REPOPARA2}</p>

          <h2 className="refund-policy-heading">
            {RefundPolicyContent.REPOHEADING2}
          </h2>
          <hr className="refund-policy-hr-line"></hr>
          <p className="refund-policy-para">{RefundPolicyContent.REPOPARA3}</p>
          <p className="refund-policy-para">{RefundPolicyContent.REPOPARA4}</p>
          <h2 className="refund-policy-heading">
            {RefundPolicyContent.REPOHEADING3}
          </h2>
          <hr className="refund-policy-hr-line"></hr>
          <h3 className="refund-policy-title">
            {RefundPolicyContent.REPOSUBHEADING2}
          </h3>
          <p className="refund-policy-para">{RefundPolicyContent.REPOPARA5}</p>
        </div>
      </HomePageLayout>
    </>
  );
};

export default RefundPolicy;
