import React, { useState, useEffect } from "react";
import "./Navbar.css";
import HeaderLogo from "../../../Assets/Svg/logo2.0W.svg";
import { Link } from "react-router-dom";
import SignIcon from "../../../Assets/Svg/signIcon.svg";
import RoundedButton from "../../RoundedButton";
import CallIcon from "../../../Assets/Svg/callRing.svg";
import Software from "../../../Assets/Svg/software.svg";
import Hardware from "../../../Assets/Svg/hardware.svg";
import Arrow from "../../../Assets/Svg/arrow.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import ReactModal from "react-modal";
import HomePagePopup from "../Popup/HomePagePopup.jsx";
import MobileScreenPopup from "../../../Models/MobileScreenPopup/MobileScreenPopup";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import axios from "axios";
import { DEPARTMENT_DETAILS } from "../../../ApiEndPoints";
import { INDUSTRY_DETAILS } from "../../../ApiEndPoints";
import { CATEGORY_DETAILS } from "../../../ApiEndPoints";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import { BsArrowBarRight } from "react-icons/bs";
import CampaignPopup from "../../../Models/CampaignPopup/CampaignPopup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  updatedCurrentSelectedProduct1,
  updatedCurrentSelectedProduct2,
  updatedCurrentSelectedProduct3,
} from "../../../Store/Reducer/AllProductsReducer";

const HomePageNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [logout, setLogout] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [loginLink, setLoginLink] = useState("");
  const [signUpLink, setSignUpLink] = useState("");

  const toggleLogout = () => {
    setLogout(!logout);
  };

  const handleClickAboutUs = () => {
    navigate("/about-us");
  };
  const handleClickContactUs = () => {
    navigate("/contact-us");
  };

  const handleClickAllProducts = () => {
    navigate("/view-all-product");
  };
  const onRequestClose = (close) => {
    setIsOpen(close);
  };

  const onClickLogo = () => {
    navigate("/");
  };

  const onClickSignIcon = () => {
    setPopup(true);
  };

  const handleClickCompareProduct = () => {
    navigate("/compare-products");
    dispatch(updatedCurrentSelectedProduct1(null));
    dispatch(updatedCurrentSelectedProduct2(null));
    dispatch(updatedCurrentSelectedProduct3(null));
  };

  const onClickWriteReview = () => {
    const loginType = localStorage.getItem("logintype");
    if (loginType == 1) {
      localStorage.removeItem("productName");
      localStorage.removeItem("productId");
      navigate("/write-a-review");
    } else if (loginType == 2) {
      toast.error("Only registered customers can give feedback");
    } else {
      navigate("/login");
    }
  };

  const [department, setDepartment] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [category, setCategory] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [visible, setVisible] = useState(0);

  useEffect(() => {
    axios
      .get(`${BaseUrl}${DEPARTMENT_DETAILS}`)
      .then((response) => {
        setDepartment(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(`${BaseUrl}${CATEGORY_DETAILS}`)
      .then((response) => {
        setCategory(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  console.log("subcategories: ", category[0]?.sub_categories);

  useEffect(() => {
    axios
      .get(`${BaseUrl}${INDUSTRY_DETAILS}`)
      .then((response) => {
        setIndustry(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  // console.log(category, "category from navbar");

  const handlePopupClose = () => {
    setShowPopup(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  // checking path name and validating login and signup urls
  useEffect(() => {
    const currentURL = window.location.href;
    // console.log(currentURL);
    if (currentURL.includes("https://www.conqt.com/")) {
      setLoginLink("https://login.conqt.com/login");
      setSignUpLink("https://login.conqt.com/register");
    } else {
      setLoginLink("https://www.dev.conqt.com/");
      setSignUpLink("https://www.dev.conqt.com/register");
    }
  }, []);
  return (
    <>
      {/* campaign popup */}
      {/* {showPopup && <CampaignPopup onClose={handlePopupClose} />} */}
      <section
        className="d-none d-md-block"
        style={{ position: "sticky", top: "0", zIndex: 5 }}
      >
        <div className="main-header-bg py-2">
          <div className="container">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="congt-logo-beta" onClick={onClickLogo}>
                  <img
                    src={HeaderLogo}
                    alt=""
                    style={{ width: "140px", cursor: "pointer" }}
                  />
                  <p className="nav-beta">Beta</p>
                </div>
                <div className="d-flex px-5">
                  <div className="px-2">
                    <a
                      className="find-vendor"
                      href="https://login.conqt.com/customer-registration"
                    >
                      <RoundedButton
                        backgroundColor={"white"}
                        color={"black"}
                        buttonName={"Find Vendor"}
                      />
                    </a>
                  </div>

                  <div>
                    <a
                      className="find-vendor"
                      href="https://login.conqt.com/vendor-registration"
                    >
                      <RoundedButton
                        backgroundColor={"white"}
                        color={"black"}
                        buttonName={"Find Customer"}
                      />
                    </a>
                  </div>
                </div>
                {/* if you want find vendor and customer buttons uncomment above div */}
              </div>
              <div>
                <div>
                  {/* <RoundedButton
                                        backgroundColor={"#3EB489"}
                                        color={"white"}
                                        buttonName={"Call Expert"}
                                        image={CallIcon}
                                        onClickFunc={() => setIsOpen(true)}
                                    /> */}

                  <button
                    className="call-expt-btn px-4 py-2"
                    onClick={() => setIsOpen(true)}
                  >
                    <img src={CallIcon} alt="" style={{ width: "15px" }} />
                    <span style={{ marginLeft: "5px" }}>Call Expert</span>
                  </button>

                  <ReactModal
                    isOpen={isOpen}
                    contentLabel="Example Modal"
                    onRequestClose={() => setIsOpen(false)}
                  >
                    <HomePagePopup close={onRequestClose} />
                  </ReactModal>
                  <img
                    onClick={toggleLogout}
                    style={{
                      width: "68px",
                      marginLeft: "15px",
                      cursor: "pointer",
                    }}
                    src={SignIcon}
                    alt=""
                  />
                  {logout && (
                    <div className="header-logout">
                      <p className="sign-card">
                        <a className="sign-card" href={loginLink} alt="">
                          Login
                        </a>
                      </p>
                      <p className="sign-card">
                        <a className="sign-card" href={signUpLink} alt="">
                          Sign Up
                        </a>
                      </p>
                      {/* <p className="sign-card">My Profile</p>
                      <p className="sign-card">My Cart</p>
                      <p className="sign-card">Wishlist</p>
                      <p className="sign-card">Get Customer Pricing</p> */}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-center justify-content-between"
              style={{ paddingTop: "20px" }}
            >
              <div className="d-flex align-items-baseline">
                <div style={{ marginTop: "-15px", marginLeft: "-15px" }}>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-category"
                      className="header-links px-3"
                    >
                      All Categories
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {category.slice(0, 2).map((items, index) => {
                        return (
                          <div className="catmenu">
                            <img src={items.image_url} alt="software" />
                            <div
                              className="catmenufields"
                              onMouseEnter={() => setVisible(items.category_id)}
                            >
                              <div className="">
                                <div className={items.category_id}>
                                  {items.category_name}
                                  {visible == items.category_id && (
                                    <div id="submenuitemsList">
                                      {items.sub_categories.map(
                                        (type, indexs) => {
                                          return (
                                            type.product_count != 0 && (
                                              <Dropdown.Item
                                                eventKey="1"
                                                key={indexs}
                                                className="submenuitem"
                                                onClick={() => {
                                                  // console.log(items.department_name
                                                  // , "items.department_name");
                                                  navigate(
                                                    `/category/${
                                                      type.category_id
                                                    }/${type.category_name
                                                      .split(" ")
                                                      .join("-")}`
                                                  );
                                                }}
                                              >
                                                {type.category_name}
                                              </Dropdown.Item>
                                            )
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                                {/* ))} */}
                              </div>
                              <div className="subheader">
                                <span>Categories</span>
                                <BsArrowBarRight />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div style={{ marginTop: "-15px" }}>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-industry"
                      className="header-links px-3"
                    >
                      By Industry
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropmenuitemsdep">
                      {industry.map((items, index) => {
                        return (
                          items.product_count != 0 && (
                            <Dropdown.Item
                              onClick={() => {
                                // console.log(items.department_name
                                // , "items.department_name");
                                navigate(
                                  `/industry/${items.id}/${items.industry_name
                                    .split(" ")
                                    .join("-")}`
                                );
                              }}
                            >
                              {items.industry_name}
                            </Dropdown.Item>
                          )
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div style={{ marginTop: "-15px" }}>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-department"
                      className="header-links px-3"
                    >
                      By Department
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropmenuitemsdep">
                      {department.map((items, index) => {
                        return (
                          items.product_count != 0 && (
                            <Dropdown.Item
                              onClick={() => {
                                // console.log(items.department_name
                                // , "items.department_name");
                                navigate(
                                  `/department/${
                                    items.id
                                  }/${items.department_name
                                    .split(" ")
                                    .join("-")}`
                                );
                              }}
                            >
                              {" "}
                              {items.department_name}
                            </Dropdown.Item>
                          )
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <p
                  className="header-links px-3"
                  onClick={handleClickCompareProduct}
                >
                  Compare Product
                </p>
                {/* <p className="header-links " onClick={handleClickAboutUs}>
                  About Us
                </p> */}
                <p className="header-links px-3" onClick={handleClickContactUs}>
                  Contact Us
                </p>

                {/* <p className="header-links px-4">Find Solutions</p>
                <p className="header-links">Find Projects</p> */}

                {/* <p className="header-links px-3">Offer</p> */}
              </div>
              <div className="d-flex">
                <p className="header-links px-3" onClick={onClickWriteReview}>
                  Write a review
                </p>
                {/* <a href="#faq" className="need-help">
                  <p className="header-links">Need Help ?</p>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="d-block d-sm-none">
        <div className="main-header-bg py-3">
          <div className="container d-flex align-items-center justify-content-between">
            <div className="congt-logo-beta" onClick={onClickLogo}>
              <img style={{ width: "100px" }} src={HeaderLogo} alt="" />
              <p className="nav-beta">Beta</p>
            </div>
            <div onClick={onClickSignIcon}>
              <img src={SignIcon} alt="" />
            </div>
          </div>
        </div>
      </section>
      {popup && <MobileScreenPopup isShow={setPopup} />}
    </>
  );
};

export default HomePageNavbar;
