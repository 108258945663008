import React from 'react';
import "./HowToSellOnConqt.css";
import { HowToSellOnConqtContent } from '../../../Content/SellYourSolution.content';

const HowToSellOnConqt =()=>{
    return(
        <>
        <div className='container htsoc-container'>
            <div className='htsoc-heading-div text-center'>
                <h2 className='htsoc-heading-text'>{HowToSellOnConqtContent.HTSOCHEADING}</h2>
                <hr className='text-center htsoc-hr-line'></hr>
            </div>
            <div className='row htsocsteps text-center'>
                <div className='col-12 col-lg-3 col-md-6 htsocstep-1'>
                    <div className='text-center image-div-1'>
                    </div>
                    <h3 className='htsocstep-title'>{HowToSellOnConqtContent.HTSOCBECOMEAPARTNERTITLE}</h3>
                    <h5 className='htsocstep-text'>{HowToSellOnConqtContent.HTSOCBECOMEAPARTNERTEXT}</h5>
                </div>

                <div className='col-12 col-lg-3 col-md-6 htsocstep-2'>
                    <div className='text-center image-div-2'>
                    </div>
                    <h3 className='htsocstep-title'>{HowToSellOnConqtContent.HTSOCGETPRJCTTITLE}</h3>
                    <h5 className='htsocstep-text'>{HowToSellOnConqtContent.HTSOCGETPRJCTTEXT}</h5>
                </div>

                <div className='col-12 col-lg-3 col-md-6 htsocstep-3'>
                    <div className='text-center image-div-3'>
                    </div>
                    <h3 className='htsocstep-title'>{HowToSellOnConqtContent.HTSOCSUBMITPROPOSALTITLE}</h3>
                    <h5 className='htsocstep-text'>{HowToSellOnConqtContent.HTSOCSUBMITPROPOSALTEXT}</h5>
                </div>

                <div className='col-12 col-lg-3 col-md-6 htsocstep-4'>
                    <div className='text-center image-div-4'>
                    </div>
                    <h3 className='htsocstep-title'>{HowToSellOnConqtContent.HTSOCSELLONCONQTTITLE}</h3>
                    <h5 className='htsocstep-text'>{HowToSellOnConqtContent.HTSOCSELLONCONQTTEXT}</h5>
                </div>                
            </div>
            
        </div>
        </>
    )
};

export default HowToSellOnConqt;