import React from "react";
import "../HelpFaqs/HelpFaqs.css";
import { FaqContent } from "../../../Content/Faq.content";
import overviewImg from "../../../Assets/Images/Faqs-HelpCenter.png";

const HelpFaqs = () => {
    return (
        <>
            <div className="container">
                <div className="row help-faq-row justify-content-between align-items-center flex-column flex-md-row">
                    <div className="col-12 col-lg-6 order-2 order-md-1 px-md-0">
                        <h4 className="faqheader">{FaqContent.FAQHEADING}</h4>
                        <h2 className="faqheader2">{FaqContent.FAQMAINHEADING}</h2>
                        <p className="faqtagline">{FaqContent.FAQHEROBANNERTAGLINE}</p>

                    </div>
                    <div className="col-12 col-lg-6 order-1 md-order-2 text-center">
                        <img className="faqimg" src={overviewImg} alt="faqimage" />

                    </div>

                </div>
            </div>

        </>
    )
}

export default HelpFaqs;
