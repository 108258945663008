import React from "react";
import "./bottomnav.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useSelector } from "react-redux";

const BottomNav = ({ des, pro ,vid }) => {
  const { SingleProduct } = useSelector((state) => state.singleProductDetails);

  return (
    <>
      <div className="bottom-nav-main-div">
        <Navbar bg="light" variant="light">
          <Container className="bottom-nav-div">
            <Nav className="me-auto">
              <Nav.Link href="#product" onClick={pro}>
                Product
              </Nav.Link>
              <Nav.Link href="#desc" onClick={des}>
                Description
              </Nav.Link>
              <Nav.Link href="#fea">Features</Nav.Link>
              <Nav.Link href="#spec">Specification</Nav.Link>
              {SingleProduct.productDetail.video_link && (
                <Nav.Link href="#vid" onClick={vid}>Video</Nav.Link>
              )}
            </Nav>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default BottomNav;
