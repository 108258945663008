import React, { useState, useEffect } from "react";
import "./sidenavsuboptions.css";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import axios from "axios";
import { CATEGORY_DETAILS } from "../../../ApiEndPoints";
import { useParams, useNavigate } from "react-router-dom";
import Checkbox from "../../../Assets/Svg/Rectangle29.svg";

const SideNavSubCategory = ({ title }) => {
  // const [toggle, setToggle] = useState(false)

  // const hadnelClickToggle = () => {
  //     setToggle(!toggle)
  // }

  const [isChecked, setIsChecked] = useState(false);
  const [isCheckboxChecked, setIsCheckBoxChecked] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { id, name } = useParams();

  useEffect(() => {
    axios
      .get(`${BaseUrl}${CATEGORY_DETAILS}`)
      .then((response) => {
        setData(response.data.data[0].sub_categories);
        //console.log("category", response.data.data[0].sub_categories);
      })
      .catch((error) => console.log(error));
  }, []);

  const handlClick = (index) => {
    // let temp=isCheckboxChecked;
    // temp[index]=!temp[index]
    // console.log(temp, "temp");
    setIsCheckBoxChecked(index);
  };

  // console.log(data, "data of categories");

  return (
    <>
      <section className="container">
        <h4 className="py-2">{title}</h4>
        <div>
          <>
            {data.slice(0, 20).map((items, index) => {
              return (
                items.product_count != 0 && (
                  <button
                    className="side-nav-sub-dept"
                    onClick={() => {
                      handlClick(index);

                      navigate(
                        `/category/${items.category_id}/${items.category_name
                          .split(" ")
                          .join("-")}`
                      );
                    }}
                  >
                    {/* <img src={Checkbox} alt="" /> */}
                    <input
                      type="checkbox"
                      checked={isCheckboxChecked === index}
                    />
                    <span className="px-1">{items.category_name}</span>
                  </button>
                )
              );
            })}
          </>
        </div>
      </section>
    </>
  );
};

export default SideNavSubCategory;
