// import React from 'react'
// import YouTube from 'react-youtube';
// import { useDispatch, useSelector } from "react-redux";
import './youtube.css'

// const Youtube = () => {
//     const { SingleProduct } = useSelector((state) => state.singleProductDetails);
//     // console.log( SingleProduct[0].video_link.split("embed/")[1] ,"video link from store")
//     // const YoutubeId = SingleProduct[0].video_link.split("embed/")[1]

//     const opts = {
//         height: '420',
//         width: '1000',
//         // playerVars: {
//         //     autoplay: 1,
//         // },
//         borderRadius: "10px"
//     };
//     return (
//         <>
//             <div className="container d-none d-md-block mb-5">
//                 <div className='d-flex flex-column align-items-center justify-content-center py-3'>
//                     <h2 style={{ fontWeight: "500" }}>Get {SingleProduct[0].product_name} for Business Demo</h2>
//                     <p style={{ color: "black" }}>We make it happen! Get your hands on the best solution based on your needs.</p>
//                 </div>
//                 <div className="d-flex align-items-center justify-content-center single-pro-yt">
//                     <YouTube
//                         // videoId={YoutubeId}
//                     // opts={opts}
//                     />
//                 </div>
//             </div>
//         </>

//     )
// }

// export default Youtube

import React, {useState, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import YouTube from 'react-youtube';


const breakpointSizes = {
        small: 480,
        medium: 768,
        large: 1024,
      };

const Youtube = ({VideoID,}) => {

        const [breakpoint, setBreakpoint] = useState('');
  const [playerWidth, setPlayerWidth] = useState('');
  const [playerHeight, setPlayerHeight] = useState('');


  const handleWindowSizeChange = () => {
        const { innerWidth } = window;
        let newBreakpoint = '';
    
        if (innerWidth < breakpointSizes.small) {
          newBreakpoint = 'small';
        } else if (innerWidth < breakpointSizes.medium) {
          newBreakpoint = 'medium';
        } else {
          newBreakpoint = 'large';
        }
    
        let newPlayerWidth = '';
        let newPlayerHeight = '';
    
        switch (newBreakpoint) {
          case 'small':
            newPlayerWidth = '100%';
            newPlayerHeight = '200px';
            break;
          case 'medium':
            newPlayerWidth = '100%';
            newPlayerHeight = '300px';
            break;
          case 'large':
            newPlayerWidth = '1000';
            newPlayerHeight = '400px';
            break;
          default:
            newPlayerWidth = '100%';
            newPlayerHeight = '360px';
            break;
        }
    
        setBreakpoint(newBreakpoint);
        setPlayerWidth(newPlayerWidth);
        setPlayerHeight(newPlayerHeight);
      };

      useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        handleWindowSizeChange();
    
        return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
        };
      }, []);
    
        const opts = {
                height:playerHeight,
                width: playerWidth,
                // playerVars: {
                //     autoplay: 1,
                // },
                borderRadius: "10px"
        };
        return (
                <>
                        <Card className='container my-3'>
                                <Card.Header
                                        className='single-desc-main'>
                                        <p className='single-vid-top-header'>Video</p>
                                </Card.Header>
                                <Card.Body className='ut-vid'>
                                        <YouTube
                                                videoId={VideoID}
                                                opts={opts}
                                        />
                                </Card.Body>
                        </Card>
                </>
        )
}

export default Youtube