import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage/HomePage";
import ViewALlProduct from "./Pages/ViewAllProduct";
import SingleProduct from "./Pages/ViewAllProduct/SingleProduct";
import AboutUs from "./Pages/AboutUs";
import ContatctUs from "./Pages/ContactUs";
import SingleCategory from "./Pages/SingleCategory";
import SingleIndustry from "./Pages/SingleIndustry";
import SingleDepartment from "./Pages/SingleDepartment";
import WriteAReview from "./Pages/WriteAReview";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import RefundPolicy from "./Pages/RefundPolicy";
import ShippingPolicy from "./Pages/ShippingPolicy";
import ConqtTermsAndConditions from "./Pages/ShippingPolicy/ConqtOldTermsAndConditions";
import { ToastContainer } from "react-toastify";
import Faqs from "./Pages/FAQs/faqs";
import ConqtUniversity from "./Pages/ConqtUniversity/ConqtUniversity";
import Login from "./Pages/Login/Login";
import SellYourSolution from "./Pages/SellYourSolution/SellYourSolution";
import CompareProducts from "./Pages/CompareProducts/CompareProducts";
import ComparisonBetweenProducts from "./Pages/ComparisonBetweenProducts/ComparisonBetweenProducts";
import LandingPageForAdvt from "./Pages/LandingPageForAdvt/LandingPageForAdvt";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/view-all-product" element={<ViewALlProduct />} />
          <Route path="/:name?" element={<SingleProduct />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContatctUs />} />
          <Route path="/category/:id/:name/" element={<SingleCategory />} />
          <Route path="/industry/:id/:name/" element={<SingleIndustry />} />
          <Route path="/department/:id/:name/" element={<SingleDepartment />} />
          <Route path="/write-a-review" element={<WriteAReview />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/conqt-university" element={<ConqtUniversity />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/conqt-terms-and-conditions" element={<ConqtTermsAndConditions />} />
          <Route path="/sell-your-solution" element={<SellYourSolution />} />
          <Route path="/compare-products" element={<CompareProducts />} />
          <Route path="/compare-between-products" element={<ComparisonBetweenProducts />} />
          <Route path="/connect-with-certified-vendor" element={<LandingPageForAdvt/>} />


      
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
