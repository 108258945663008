import React, { useState, useEffect } from 'react';
import LandingPageForAdvtConqtMap from '../../Components/LandingPageForAdvt/LandingPageForAdvtConqtMap/LandingPageForAdvtConqtMap';
import LandingPageForAdvtHomeBanner from '../../Components/LandingPageForAdvt/LandingPageForAdvtHomeBanner/LandingPageForAdvtHomeBanner';
import LandingPageForAdvtItProjects from '../../Components/LandingPageForAdvt/LandingPageForAdvtItProjects/LandingPageForAdvtItProjects';
import LandingPageForAdvtContact from '../../Components/LandingPageForAdvt/LandingPageForAdvtContact/LandingPageForAdvtContact';
import ConqtCompaignFlowMap from '../../Components/LandingPageForAdvt/ConqtCompaignFlowMap/ConqtCompaignFlowMap';

const LandingPageForAdvt = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <LandingPageForAdvtHomeBanner />
      {isMobile ? <ConqtCompaignFlowMap /> : <LandingPageForAdvtConqtMap />}
      <LandingPageForAdvtItProjects />
      <LandingPageForAdvtContact />
    </div>
  );
};

export default LandingPageForAdvt;
