import React from "react";
import "./Form.css";
import LeftPatch from "../../../Assets/Images/ads-left-patch.png";
import RightPatch from "../../../Assets/Images/ads-right-patch.png";
import { ConqtUniversityContent } from "../../../Content/ConqtUniversity.content";
import { useForm } from "react-hook-form";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {ADD_EXPERT} from "../../../ApiEndPoints/index";

const FormUniversity = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log(data);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      designation: "conqt-university",
      query: data.query,
      phone: data.phone,
      company_email: data.company_email,
      name: data.first_name + data.last_name,
      company_size: "100",
      company: data.company,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${BaseUrl}${ADD_EXPERT}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.status === 200) {
          toast("Thank you for getting in touch!");
          navigate("/");
        } else {
          toast("error");
        }
      })
      .catch((error) => console.log("error", error));
  };


  return (
    <>
      <div className="background-for-form">
        <div className="form-patches">
          <img className="form-left-patch" src={LeftPatch} alt="" />
          <img className="form-right-patch" src={RightPatch} alt="" />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-University">
          <div className="form-header">
            <div className="formheading">
              <h1 className="form-heading">
                {ConqtUniversityContent.FORMHEADING}
              </h1>
            </div>
            <div className="formdescription">
              <p>{ConqtUniversityContent.FORMDESCRIPTION}</p>
            </div>
          </div>
          <div className="full-form-university">
            <div className="firstline-form">
              <div className="fnuniversity">
                <label className="label-university">
                  First Name <span className="error-symbol">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="input-box-university"
                  {...register("first_name", {
                    required: true,
                    minLength: 3,
                    maxLength: 20,
                  })}
                />
                {errors.first_name && (
                  <span className="error-msg">Please enter the first name</span>
                )}
              </div>
              <div className="lnuniversity">
                <label className="label-university">
                  Last Name <span className="error-symbol">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="input-box-university"
                  {...register("last_name", {
                    required: true,
                    minLength: 3,
                    maxLength: 20,
                  })}
                />
                {errors.last_name && (
                  <span className="error-msg">Please enter the last name</span>
                )}
              </div>
            </div>
            <div className="secondline-form">
              <div className="company-university">
                <label className="label-university">
                  Company <span className="error-symbol">*</span>
                </label>
                <br />
                <input
                  type="text"
                  className="input-box-university"
                  {...register("company", {
                    required: true,
                    minLength: 3,
                    maxLength: 40,
                  })}
                />
                {errors.company && (
                  <span className="error-msg">
                    Please enter the company name
                  </span>
                )}
              </div>
              <div className="pnuniversity">
                <label className="label-university">
                  Phone Number <span className="error-symbol">*</span>
                </label>
                <br />
                <input
                  type="number"
                  className="input-box-university"
                  {...register("phone", {
                    required: true,
                    minLength: 10,
                    maxLength: 15,
                  })}
                />
                {errors.phone && (
                  <span className="error-msg">
                    Please enter the phone number
                  </span>
                )}
              </div>
            </div>
            <div className="email-conatiner">
              <div className="email-university">
                <label className="label-university">
                  Email <span className="error-symbol">*</span>
                </label>
                <br />
                <input
                  type="email"
                  className="email-input-box-university"
                  {...register("company_email", {
                    required: true,
                  })}
                />
                {errors.company_email && (
                  <span className="error-msg">Please enter the email</span>
                )}
              </div>
            </div>
            <div className="description-container">
              <div className="description-university">
                <label className="label-university">
                  Description <span className="error-symbol">*</span>
                </label>
                <br />
                <textarea
                  className="description-input-box-university"
                  {...register("query", {
                    required: true,
                  })}
                ></textarea>
                {errors.query && (
                  <span className="error-msg">
                    Please enter the description
                  </span>
                )}
              </div>
            </div>
            <div className="button-in-from">
              <button className="button-university">Send</button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
export default FormUniversity;
