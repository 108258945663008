import React from "react";
import "./LandingPageForAdvtItProjects.css";
import landingman from "../../../Assets/Images/landingman.png";

const LandingPageForAdvtItProjects = () => {
  const handleSignUp =()=>{
    window.location.href = 'https://login.conqt.com/vendor-registration';
  }
  return (
    <div className="lp-itprojects">
      <div className="lp-itprojects-content">
        <h3 className="lp-itprojects-heading">Effortless IT Projects</h3>
        <p className="lp-itprojects-para">
          At ConQT, we handle much of the process, making it hassle-free for
          you. Our platform's advanced features, automated processes, and expert
          guidance ensure that you can sit back and relax while we manage the
          complexities. Focus on your core business while we take care of the IT
          project journey.
        </p>
        <p className="lp-itprojects-para">
          Join ConQT today and experience how effortless it can be to create
          your project, receive vendor quotations, analyze them efficiently, and
          select the ideal IT vendor. Let us simplify your IT project journey,
          so you can save valuable time and resources.
        </p>
        <div className="signup-button-div">
          <button className="signup-button" onClick={handleSignUp}>Sign Up Now</button>
        </div>
      </div>
      <img className="lp-landimg" src={landingman} alt="" />
    </div>
  );
};

export default LandingPageForAdvtItProjects;
