import {createSlice } from "@reduxjs/toolkit";

export const myReducer = createSlice({
  name: "conqt",
  initialState: {
    AllProducts: [],
    currentSelectedProduct1: {},
    currentSelectedProduct2: {},
    currentSelectedProduct3: {},
  },
  reducers: {
    updatedAllProductsData: (state, action) => {
      state.AllProducts = action.payload;
    },

    updatedCurrentSelectedProduct1: (state, action) => {
      state.currentSelectedProduct1 = action.payload;
    },

    updatedCurrentSelectedProduct2: (state, action) => {
      state.currentSelectedProduct2 = action.payload;
    },

    updatedCurrentSelectedProduct3: (state, action) => {
      state.currentSelectedProduct3 = action.payload;
    }

  },
  extraReducers: {},
});
export const { updatedAllProductsData, updatedCurrentSelectedProduct1,
  updatedCurrentSelectedProduct2, updatedCurrentSelectedProduct3,
} = myReducer.actions;

export default myReducer.reducer;
