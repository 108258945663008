import React from "react";
import "./PrivacyPolicy.css";
import HomePageLayout from "../../Layouts/HomePageLayout";
import { PrivacyPolicyContent } from "../../Content/PrivacyPolicyContent";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>{"PRIVACY POLICY - ConQT"}</title>
      </Helmet>
      <HomePageLayout>
        <div className="container privacy-policy-container mt-5 mb-5">
          <h2 className="privacy-policy-heading">
            {PrivacyPolicyContent.PRIVACYPOHEADING1}
          </h2>
          <hr className="privacy-policy-hr-line"></hr>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA1}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA2}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA3}
          </p>
          <h2 className="privacy-policy-heading">
            {" "}
            {PrivacyPolicyContent.PRIVACYPOHEADING2}
          </h2>
          <hr className="privacy-policy-hr-line"></hr>
          <h4 className="privacy-policy-sub-heading">
            {PrivacyPolicyContent.PRIVACYPOSUBHEADING1}
          </h4>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST1}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST2}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST3}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST4}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST5}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST6}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA4}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA5}
          </p>
          <h2 className="privacy-policy-heading">
            {PrivacyPolicyContent.PRIVACYPOHEADING3}
          </h2>
          <hr className="privacy-policy-hr-line"></hr>
          <h4 className="privacy-policy-sub-heading">
            {PrivacyPolicyContent.PRIVACYPOSUBHEADING2}
          </h4>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST8}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST9}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST10}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST11}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST12}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST13}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA6}
          </p>
          <h2 className="privacy-policy-heading">
            {PrivacyPolicyContent.PRIVACYPOHEADING4}
          </h2>
          <h4 className="privacy-policy-sub-heading">
            {PrivacyPolicyContent.PRIVACYPOSUBHEADING3}
          </h4>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST14}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST15}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST16}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST17}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST18}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST19}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST20}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST21}
          </p>
          <p className="privacy-policy-list-item">
            {PrivacyPolicyContent.PRIVACYPOLIST22}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA7}
          </p>
          <h2 className="privacy-policy-heading">
            {PrivacyPolicyContent.PRIVACYPOHEADING5}
          </h2>
          <hr className="privacy-policy-hr-line"></hr>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA8}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA9}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA10}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA11}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA12}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA13}
          </p>

          <h2 className="privacy-policy-heading">
            {PrivacyPolicyContent.PRIVACYPOHEADING6}
          </h2>
          <hr className="privacy-policy-hr-line"></hr>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA14}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA15}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA16}
          </p>

          <h2 className="privacy-policy-heading">
            {PrivacyPolicyContent.PRIVACYPOHEADING7}
          </h2>
          <hr className="privacy-policy-hr-line"></hr>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA22}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA17}
          </p>
          <h2 className="privacy-policy-heading">
            {PrivacyPolicyContent.PRIVACYPOHEADING8}
          </h2>
          <hr className="privacy-policy-hr-line"></hr>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA18}
          </p>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA19}
          </p>
          <h2 className="privacy-policy-heading">
            {PrivacyPolicyContent.PRIVACYPOHEADING9}
          </h2>
          <hr className="privacy-policy-hr-line"></hr>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA20}
          </p>
          <h2 className="privacy-policy-heading">
            {PrivacyPolicyContent.PRIVACYPOHEADING10}
          </h2>
          <hr className="privacy-policy-hr-line"></hr>
          <p className="privacy-policy-para">
            {PrivacyPolicyContent.PRIVACYPOPARA21}
          </p>
        </div>
      </HomePageLayout>
    </>
  );
};

export default PrivacyPolicy;
