import React from 'react';
import './HtsocVideo.css';

const HtsocVideo=()=>{
    return(
        <>
        <div class='container text-center'>
  <div class="video-container">
    <iframe src="https://www.youtube.com/embed/44lU2tArDnE" title="video-container" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  </div>
</div>
        </>
    )
}

export default HtsocVideo;