import React from 'react'
import { Grid } from '@mui/material'
import Footer from '../Components/Footer'
import Header from '../Components/HomePage/Navbar/Navbar'
import SideNavigation from '../Components/SideNavigation';
import './layout.css'
import SingleCategoryRightSideNav from '../Components/SingleCategory/RightSideNav';
import BreadCrumbs from '../Components/BreadCrumbs/BreadCrumbs';
import SingleCategorySwiper from '../Components/SingleIndusty/SingleSwiper';

const HomePageWithSideNav = ({ children }) => {
    return (
        <>
            <Grid>
                <Header />
            </Grid>
            <div className="container">
                {/* <div>
                    <BreadCrumbs
                        title1={"Industries"}
                        title2={"Software for E-Commerce"}
                        title3={""}
                        sep1={">"}
                        sep2={">"}
                        sep3={""}
                        sep4={""}
                    />
                </div> */}
                <Grid container spacing={1} className="">
                    <Grid item xs={12} md={3} lg={2}>
                        <div className='d-none d-lg-block'>
                            <SideNavigation />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={9} lg={10}>
                        <div>
                                <SingleCategorySwiper />
                        </div>
                    <Grid container>
                        <Grid item xs={12} md={8} lg={9}>
                            {children}
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <div className="d-none d-lg-block">
                                <SingleCategoryRightSideNav />
                            </div>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            </div>

            <Grid>
                <Footer />
            </Grid>
        </>
    )
}

export default HomePageWithSideNav