import React from 'react';
import './productVideos.css';
// import Ytube from '../../../CompareProducts/yTube';
import YouTube from 'react-youtube';

const ProductVideosCompare = ({ video, pName }) => {

    const opts = {
        height: "140px",
        width: "230px",
        borderRadius: "10px"
    };
    return (
        <>
            <section className='mbl-view-compare-product-border py-3 my-3' >
                <div className='container'>
                    <div className='price-top-name'>
                        <p>{pName}</p>
                    </div>
                    <div className='d-flex align-items-center justify-content-center py-3'>
                        <YouTube
                            style={{ width: "150px", marginLeft: "-80px" }}
                            videoId={video}
                            opts={opts}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProductVideosCompare