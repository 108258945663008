import React from "react";
import "./ConqtUniversityBanner.css";

const CUBanner = ({ title, spanName }) => {
  return (
    <>
      <section className="CU-banner">
        <div className="d-flex align-items-center justify-content-center">
          <div style={{ marginTop: "150px" }}>
            <h1 className="page-heading">
              {title} <span style={{ color: "#3EB489" }}>{spanName}</span>
            </h1>
          </div>
        </div>
      </section>
    </>
  );
};

export default CUBanner;
