import React, { useState, useEffect, useRef } from "react";
import "./sidenavsuboptions.css";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import axios from "axios";
import { INDUSTRY_DETAILS } from "../../../ApiEndPoints";
import { updatedSingleDepartmentProductsData } from "../../../Store/Reducer/SingleDepartmentReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Checkbox from "../../../Assets/Svg/Rectangle29.svg";

const SideNavSubOptions = ({ title }) => {
  // const [toggle, setToggle] = useState(false)

  // const hadnelClickToggle = () => {
  //     setToggle(!toggle)
  // }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isChecked ,setIsChecked] = useState()
  const [data, setData] = useState([]);
  const { id, name } = useParams();

  useEffect(() => {
    axios
      .get(`${BaseUrl}${INDUSTRY_DETAILS}`)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <section className="container">
        <h4 className="py-2">{title}</h4>
        <div>
          <>
            {data.map((items, index) => {
              return (
                items.product_count != 0 && 
                <button
                  className="side-nav-sub-dept"
                  //   ref={buttonRef}

                  onClick={() => {
                    // buttonRef.current.focus();
                    setIsChecked(index)
                    navigate(
                      `/industry/${items.id}/${items.industry_name
                        .split(" ")
                        .join("-")}`
                    );
                  }}
                >
                  {/* <img src={Checkbox} alt="" /> */}
                  <input type="checkbox" checked={isChecked === index} />
                  <span className="px-1">{items.industry_name}</span>
                </button>
              );
            })}
            {/*<div className="d-flex align-items-center">
                            <input type="checkbox"
                            />
                            <label htmlFor="" className='px-2 py-2'>Dignisim ac</label>
                        </div>
                        <div className="d-flex align-items-center">
                            <input type="checkbox"
                            />
                            <label htmlFor="" className='px-2'>Dignisim ac</label>
                        </div>
                        <div className="d-flex align-items-center">
                            <input type="checkbox"
                            />
                            <label htmlFor="" className='px-2 py-2'>Dignisim ac</label>
                        </div>
                        <div className="d-flex align-items-center">
                            <input type="checkbox"
                            />
                            <label htmlFor="" className='px-2'>Dignisim ac</label>
                        </div>
                         <div className='' style={{ paddingTop: "15px", paddingBottom: "10px" }}>
                            <p onClick={hadnelClickToggle} className="view-cat-side-toggle">View More</p>

                            {toggle && (
                                <>
                                    <div className="d-flex align-items-center">
                                        <input type="checkbox"
                                        />
                                        <label htmlFor="" className='px-2'>Dignisim ac</label>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <input type="checkbox"
                                        />
                                        <label htmlFor="" className='px-2 py-2'>Dignisim ac</label>
                                    </div>
                                </>
                            )}
                        </div> */}
          </>
        </div>
      </section>
    </>
  );
};

export default SideNavSubOptions;
