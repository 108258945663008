import React, { useState, useEffect } from "react";
import "./rightsidenav.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import axios from "axios";
import Typography from "@mui/material/Typography";
import RightSmallCard from "./SmCard";
import SmCard1 from "../../../Assets/Svg/sm-card-1.svg";
import SmCard2 from "../../../Assets/Svg/sm-card-2.svg";
import { useDispatch, useSelector } from "react-redux";
import { updatedAllProductsData } from "../../../Store/Reducer/AllProductsReducer";
import FadeLoader from "react-spinners/FadeLoader";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import parse from "html-react-parser";

const SingleCategoryRightSideNav = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const { AllProducts } = useSelector((state) => state.allProducts);

  // useEffect(() => {
  //     axios
  //         .get(`${BaseUrl}product/Get-All-Products`)
  //         .then((response) => {
  //             setLoader(false);
  //             setData(response.data.data);
  //             console.log("data", response.data.data)
  //             dispatch(updatedAllProductsData(response.data.data));
  //         })
  //         .catch((error) => console.log(error));
  // }, []);
  // console.log(data);

  const NewData = [...AllProducts].sort(() => 0.5 - Math.random());

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 2 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (SyntheticEvent, number) => {
    setValue(number);
  };
  return (
    <>
      <Box sx={{ width: "100%" }} className="box-cat-right mt-3">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="New Arrivals" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          {/* <center>{loader && <FadeLoader color="#36d7b7" />}</center> */}
          {NewData.slice(0, 3).map((e) => {
            console.log(e, "id in all new arrival products page");
            // console.log(e.product_description)

            return (
              <RightSmallCard
                id={e.id}
                image={e.image_url}
                content={parse(
                  e.product_description
                    .substring(0, 80)
                    .replace(/^(.{70}[^\s]*).*/, "$1")
                )}
                header={e.product_name}
                // count={"159"}
              />
            );
          })}
        </TabPanel>
      </Box>
    </>
  );
};

export default SingleCategoryRightSideNav;
