import React, { useState } from "react";
import Rate from "rc-rate";
import "./rating.css";
import poor from "../../../Assets/Pdf/closeMo.gif";
import good from "../../../Assets/Pdf/normal.gif";
import great from "../../../Assets/Pdf/sad.gif";
import excellent from "../../../Assets/Pdf/hero.gif";
import { Range, getTrackBackground } from "react-range";
import Dropdown from "../Dropdown";
import axios from "axios";
import { BaseUrl } from "../../../ApiBaseUrl/Api";
import { ADD_PRODUCT_REVIEW } from "../../../ApiEndPoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ReviewSuccessPopup from "../../../Models/ReviewSuccessPopup/ReviewSuccessPopup";

const Rating = () => {
  // validate the  words
  const MAX_FEEDBACK_WORDS = 80;
  const MAX_TITLE_WORDS = 15;

  const [values, setValues] = useState([5]);
  const [featureValues, setFeatureValues] = useState();
  const [moneyValue, setMoneyValues] = useState();
  const [useValues, setUseValues] = useState();
  const [supportValues, setSupportValues] = useState();
  const [reviewTitle, setReviewTitle] = useState("");
  const [feedback, setFeedback] = useState("");
  const [experience, setExperience] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [ratingErrors, setRatingErrors] = useState(false);
  const [errors, setErrors] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [feedbackWordsCount, setFeedbackWordsCount] = useState(0);
  const [titleWordsCount, setTitleWordsCount] = useState(0);

  const handleFeedbackChange = (e) => {
    const text = e.target.value;
    const words = text.trim().split(/\s+/);
    setFeedbackWordsCount(words.length);
    if (words.length <= MAX_FEEDBACK_WORDS) {
      setFeedback(text);
    }
  };

  const handleTitleChange = (e) => {
    const text = e.target.value;
    const words = text.trim().split(/\s+/);
    setTitleWordsCount(words.length);
    if (words.length <= MAX_TITLE_WORDS) {
      setReviewTitle(text);
    }
  };

  const validateData = () => {
    const errors = {};
    if (!reviewTitle) {
      errors.reviewTitle = "Please enter the review title";
    }

    if (!feedback) {
      errors.feedback = "Please enter the feedback";
    }

    if (!selectedId || selectedId == null) {
      // console.log(selectedId , "selectedId")
      errors.selectedId = "Please select Product Name";
    }

    if (!experience) {
      errors.experience = "Please select your experience";
    }
    return errors;
  };

  const handleIdChange = (id) => {
    setSelectedId(id);
  };

  const handelExperience = (value) => {
    console.log("e: ", value);
    setExperience(value);
  };

  const onChangeFeat = (value) => {
    console.log("feat: ", value);
    setFeatureValues(value);
  };

  const onChangeMoney = (value) => {
    console.log("money: ", value);
    setMoneyValues(value);
  };

  const onChangeUse = (value) => {
    console.log("use: ", value);
    setUseValues(value);
  };
  const onChangeSupport = (value) => {
    console.log("support: ", value);
    setSupportValues(value);
  };

  console.log("values: ", values);

  const submitReview = () => {
    const allRatings =
      featureValues && moneyValue && useValues && supportValues;
    const token = localStorage.getItem("token");
    // console.log("token: ", token);
    const errors = validateData();
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (allRatings) {
        setRatingErrors(false);
        const data = {
          product_id: selectedId,
          features_rate: featureValues,
          money_rate: moneyValue,
          use_rate: useValues,
          support_rate: supportValues,
          experience: experience,
          recommended: values[0],
          review_title: reviewTitle,
          feedback: feedback,
        };
        console.log("data: ", data);
        axios
          .post(`${BaseUrl}${ADD_PRODUCT_REVIEW}`, data, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toast.success("Review Added Successfully");
              window.scrollTo(0, 0);
              setShowPopup(true);
              localStorage.removeItem("productName");
              localStorage.removeItem("productId");
            } else if (res.data.status === 401) {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      } else {
        setRatingErrors(true);
        toast.error("Insufficient Data");
      }
    } else {
      toast.error("Insufficient Data");
    }
  };

  return (
    <>
      {!showPopup && (
        <>
          <div className="wr-top-name">Share Your Experience With Us</div>
          <div>
            <Dropdown onIdChange={handleIdChange} errors={errors.selectedId} />
          </div>
          <div
            style={{ padding: "20px 0px" }}
            className="d-none d-md-block wr-rates-desktop"
          >
            <div className="rat-name rate-us">
              Rate Us{" "}
              {ratingErrors && (
                <p className="error-msg">Please select the rating</p>
              )}
            </div>

            <div
              className="d-flex align-items-center justify-content-between "
              style={{ width: "60%" }}
            >
              <div className="d-flex" style={{ padding: "19px 0px 24px 0px" }}>
                <p className="rat-name">Features</p>
                <span style={{ marginTop: "-30px", marginLeft: "50px" }}>
                  <Rate
                    // defaultValue={4}
                    onChange={onChangeFeat}
                    // value={onHoverChange}
                    style={{ fontSize: 40 }}
                    allowHalf
                    allowClear={true}
                  />
                </span>
              </div>
              <div className="d-flex">
                <p className="rat-name">Value of Money</p>
                <span style={{ marginTop: "-30px", marginLeft: "50px" }}>
                  <Rate
                    // defaultValue={4}
                    onChange={onChangeMoney}
                    style={{ fontSize: 40 }}
                    allowHalf
                    allowClear={true}
                    // disabled={true}
                  />
                </span>
              </div>
            </div>

            <div
              className="d-flex align-items-center justify-content-between"
              style={{ width: "60%", marginTop: "-20px" }}
            >
              <div className="d-flex">
                <p className="rat-name">Ease of Use</p>
                <span style={{ marginTop: "-30px", marginLeft: "30px" }}>
                  <Rate
                    // defaultValue={4}
                    onChange={onChangeUse}
                    style={{ fontSize: 40 }}
                    allowHalf
                    allowClear={true}
                    // disabled={true}
                  />
                </span>
              </div>
              <div className="d-flex">
                <p className="rat-name">Customer Support</p>
                <span style={{ marginTop: "-30px", marginLeft: "50px" }}>
                  <Rate
                    // defaultValue={4}
                    onChange={onChangeSupport}
                    style={{ fontSize: 40 }}
                    allowHalf
                    allowClear={true}
                    // disabled={true}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="d-block d-lg-none">
            <div className="rat-name rate-us" style={{ padding: "20px 0px" }}>
              Rate Us{" "}
              {ratingErrors && (
                <span className="error-msg">Please select the rating</span>
              )}
            </div>
            <div className="d-flex" style={{}}>
              <p className="rat-name">Value for money</p>
              <span style={{ marginTop: "-30px", marginLeft: "50px" }}>
                <Rate
                  // defaultValue={4}
                  onChange={onChangeMoney}
                  style={{ fontSize: 40 }}
                  allowHalf
                  allowClear={true}
                  // disabled={true}
                />
              </span>
            </div>

            <div className="d-flex" style={{}}>
              <p className="rat-name">Features</p>
              <span style={{ marginTop: "-30px", marginLeft: "100px" }}>
                <Rate
                  // defaultValue={4}
                  onChange={onChangeFeat}
                  style={{ fontSize: 40 }}
                  allowHalf
                  allowClear={true}
                  // disabled={true}
                />
              </span>
            </div>

            <div className="d-flex" style={{}}>
              <p className="rat-name">Ease of Use</p>
              <span style={{ marginTop: "-30px", marginLeft: "80px" }}>
                <Rate
                  // defaultValue={4}
                  onChange={onChangeUse}
                  style={{ fontSize: 40 }}
                  allowHalf
                  allowClear={true}
                  // disabled={true}
                />
              </span>
            </div>

            <div className="d-flex" style={{}}>
              <p className="rat-name">Customer Support</p>
              <span style={{ marginTop: "-30px", marginLeft: "35px" }}>
                <Rate
                  // defaultValue={4}
                  onChange={onChangeSupport}
                  style={{ fontSize: 40 }}
                  allowHalf
                  allowClear={true}
                  // disabled={true}
                />
              </span>
            </div>
          </div>

          <div>
            <div className="d-flex">
              <p className="wr-sub-head">
                How was your Experience using our Service{" "}
              </p>
              {errors.experience && (
                <span className="error-msg px-2">{errors.experience}</span>
              )}
            </div>
            <div className="emojis">
              <div onClick={() => handelExperience("Poor")}>
                <button
                  className={
                    experience == "Poor" ? "poor-btn-selected" : "poor-btn"
                  }
                >
                  <img
                    src={poor}
                    alt=""
                    style={{ width: "50px", cursor: "pointer" }}
                  />
                </button>
                <p style={{ marginLeft: "10px" }} className="emoji-name">
                  Poor
                </p>
              </div>
              <div onClick={() => handelExperience("Fair")}>
                <button
                  className={
                    experience == "Fair" ? "fair-btn-selected" : "fair-btn"
                  }
                >
                  <img src={great} alt="" style={{ width: "50px" }} />
                </button>
                <p style={{ marginLeft: "10px" }} className="emoji-name">
                  Fair
                </p>
              </div>
              <div onClick={() => handelExperience("Good")}>
                <button
                  className={
                    experience == "Good" ? "gr-btn-selected" : "gr-btn"
                  }
                >
                  <img src={good} alt="" style={{ width: "50px" }} />
                </button>
                <p style={{ marginLeft: "10px" }} className="emoji-name">
                  Good
                </p>
              </div>
              <div onClick={() => handelExperience("Excellent")}>
                <button
                  className={
                    experience == "Excellent"
                      ? "excellent-btn-selected"
                      : "excellent-btn"
                  }
                >
                  <img src={excellent} alt="" style={{ width: "50px" }} />
                </button>
                <p className="emoji-name">Excellent</p>
              </div>
            </div>
            <div style={{ marginTop: "-50px" }}>
              <hr className="wr-rev-hr" />
            </div>
          </div>

          <div className="py-4">
            <p className="wr-sub-head">
              How likely are you to recommend “ this service” to others ?
            </p>
            <div className="main-track">
              <Range
                values={values}
                step={1}
                min={0}
                max={10}
                onChange={(values) => setValues(values)}
                renderTrack={({ props, children }) => (
                  <div
                    onMouseDown={props.onMouseDown}
                    onTouchStart={props.onTouchStart}
                    className="track"
                  >
                    <div
                      ref={props.ref}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: "4px",
                        background: getTrackBackground({
                          values,
                          colors: ["#3EB489", "#3EB489"],
                          min: 0,
                          max: 10,
                        }),
                        alignSelf: "start",
                      }}
                      className="inner-track"
                    >
                      {children}
                    </div>
                  </div>
                )}
                renderThumb={({ props, isDragged }) => (
                  <div
                    className=""
                    {...props}
                    style={{
                      ...props.style,
                      height: "35px",
                      width: "30px",
                      borderRadius: "50px 50px 100px 100px",
                      backgroundColor: "#3EB489",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 2px 6px #AAA",
                      marginTop: "-22px",
                    }}
                  >
                    <output style={{ color: "#fff" }}>{values[0]}</output>
                  </div>
                )}
              />
            </div>
            <div className="btm-track-names">
              <div>Not Likely</div>
              <div>Neutral</div>
              <div>Highly Recommend</div>
            </div>
          </div>

          <div>
            <p className="wr-sub-review">Review Title</p>
            <div>
              <input
                type="text"
                className="wr-input"
                maxLength=""
                value={reviewTitle}
                onChange={handleTitleChange}
                placeholder="What most important to know? (not more than 15 words)"
              />
              <p className="word-count mt-2" style={{ color: "#707070" }}>
                Words Count: {titleWordsCount}/{MAX_TITLE_WORDS}
              </p>
              {errors.reviewTitle && (
                <span className="error-msg">{errors.reviewTitle}</span>
              )}
            </div>
          </div>

          <div className="py-4">
            <p className="wr-sub-review">Feedback</p>
            <div>
              <textarea
                type="text"
                className="wr-input"
                onChange={handleFeedbackChange}
                value={feedback}
                maxLength=""
                placeholder="What do you like most about this service? (not more than 80 words)"
              ></textarea>
              <p className="word-count mt-2" style={{ color: "#707070" }}>
                Words Count: {feedbackWordsCount}/{MAX_FEEDBACK_WORDS}
              </p>
              {errors.feedback && (
                <span className="error-msg">{errors.feedback}</span>
              )}
            </div>
          </div>

          <div style={{ padding: "30px 0px" }}>
            <div className="wr-btm-container">
              <p className="wr-sub-head">Almost Done</p>
              <button className="wr-cont-btn" onClick={submitReview}>
                Submit
              </button>
            </div>
          </div>
        </>
      )}{" "}
      {showPopup && <ReviewSuccessPopup />}
    </>
  );
};

export default Rating;
